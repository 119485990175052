import Button from "@mui/material/Button";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {registerAction} from "../actions/RegisterAction";
import {updateUserAction} from "../actions/UserActions";
import {setPendingDetails} from "../slices/UserSlice";
import Alert from '@mui/material/Alert';
import {parse} from "query-string";
import {StyledTextField, StyledUserForm} from './styles';

const UserForm = ({type}) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const details = useSelector(state => state.user.pendingDetails);
  const friendToken = parse(location.search).friendToken;

  const handleChange = (e) => {
    let newItem = {...details};
    newItem[e.target.id] = e.target.value;
    dispatch(setPendingDetails(newItem));

    setSuccess("");
    setError("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError("");
    setSuccess("");

    const item = {...details};

    if (details.password !== details.confirmPassword) {
      setError("Passwords do not match");
      return false;
    } else if (details.password === "" || details.confirmPassword === "") {
      delete item.password;
      delete item.confirmPassword;
    }

    if (type === 'register') {
      dispatch(registerAction(details)).then(() => {
        if (friendToken) {
          history.push(`/friends/verify?token=${friendToken}`)
        } else {
          history.push("/login");
        }
      }).catch(error => {
        setError(error);
      });
    } else {
      dispatch(updateUserAction(details)).then(json => {
        setSuccess("Account update successful");
      })
      .catch(error => setError(error));
    }
  };

  return (
      <React.Fragment>
        <StyledUserForm onSubmit={handleSubmit}>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <StyledTextField id="email" label="Email" type="email" fullWidth
                     value={details.email} onChange={handleChange} required
                     variant="standard" />
          <StyledTextField id="firstName" label="First Name" fullWidth
                     value={details.firstName} onChange={handleChange} required
                     variant="standard" />
          <StyledTextField id="lastName" label="Last Name" fullWidth
                     value={details.lastName} onChange={handleChange} required
                     variant="standard" />
          <StyledTextField id="password" label="Password" type="password" fullWidth
                     value={details.password} onChange={handleChange}
                     required={type === "register"}
                     variant="standard" />
          <StyledTextField id="confirmPassword" label="Confirm Password"
                     type="password" fullWidth
                     value={details.confirmNewPassword} onChange={handleChange}
                     required={type === "register"}
                     variant="standard" />
          <Button type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    marginTop: 2,
                    marginLeft: 0}
                  }>
            {type === 'register' ? "Register" : "Update"}
          </Button>
        </StyledUserForm>
      </React.Fragment>
  )
}

export default UserForm;
