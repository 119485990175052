import styled from '@emotion/styled';
import TextField from '@mui/material/TextField/TextField';

export const StyledTextField = styled(TextField)({
  margin: '5px 0 5px 0'
})

export const StyledUserForm = styled('form')({
  margin: '10px 20px',
  textAlign: 'center'
})
