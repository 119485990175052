import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {setTitle} from "../slices/NavigatorSlice";
import {verifyFriendAction} from "../actions/FriendActions";
import {parse} from 'query-string';
import {Box} from '@mui/material';
import {StyledPagePaper} from './styles';


function VerifyFriendPage({ location }) {
  const [ successMessage, setSuccessMessage ] = useState("");
  const dispatch = useDispatch();
  const [ error, setError ] = useState("");
  const params = parse(location.search);
  const userId = useSelector(state => state.user.id);

  React.useEffect(() => {
    if (userId) {
      dispatch(verifyFriendAction(userId, params.token)).then(json => {
        setSuccessMessage(
            "Successfully accepted the friend request. Great job making friends!");
      }).catch(error => {
        setError(error);
      });
    }
  }, [userId]);

  dispatch(setTitle("Verify Friend"));

  return (
      <StyledPagePaper>
        <Box margin={2} textAlign="center">
          { successMessage ? <Typography color="textPrimary" variant="subtitle1" >{successMessage}</Typography> : null }
          { error ? <Typography color="error" variant="subtitle1" sx={{ margin: '5px 0 5px 0' }}>{error}</Typography> : null }
        </Box>
      </StyledPagePaper>
  );
}

export default VerifyFriendPage;
