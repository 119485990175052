import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const PaperStyled = styled(Paper)(({ theme}) => ({
  padding: theme.spacing(2, 4, 3),
  marginBottom: 10,
}));

const FriendFormStyled = styled('form')({
  margin: 'auto'
});

const PriorityHeading = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(1)
}));

export { PaperStyled, FriendFormStyled, PriorityHeading }
