import {createSlice} from "@reduxjs/toolkit";
import {WISHLIST_STATE} from "../constants/StateConstants";

const slice = createSlice({
  name: "Wishlists",
  initialState: {
    wishlists: [],
    selectedWishlist: WISHLIST_STATE.DEFAULT,
    wishlistSearch: "",
    loading: true,
    addItem: {},
  },
  reducers: {
    setAddWishlist: (state, action) => {
      state.addItem = action.payload;
    },
    setWishlists: (state, action) => {
      state.wishlists = action.payload
    },
    setSelectedWishlist: (state, action) => {
      state.selectedWishlist = action.payload
    },
    setWishlistSearch: (state, action) => {
      state.wishlistSearch = action.payload
    },
    setWishlistLoading: (state, action) => {
      state.loading = action.payload;
    },
    reset: state => {
      state.wishlists = [];
      state.selectedWishlist = WISHLIST_STATE.DEFAULT;
      state.wishlistSearch = "";
    }
  }
});

export const {
  setAddWishlist,
  setWishlists,
  setSelectedWishlist,
  setWishlistSearch,
  setWishlistLoading,
  reset
} = slice.actions;

export default slice.reducer