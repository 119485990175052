import React from "react";
import Hidden from "@mui/material/Hidden";
import Navigator from "./Navigator";
import {useDispatch, useSelector} from "react-redux";
import {toggleOpen} from "../../slices/NavigatorSlice";
import {StyledNav} from './styles';

const drawerWidth = 256;

function MaterialNavigator() {
  const { open } = useSelector(state => state.navigator);
  const dispatch = useDispatch();

  return (
    <StyledNav>
      <Hidden mdUp implementation="js">
        <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={open}
            onClose={() => dispatch(toggleOpen())}
        />
      </Hidden>
      <Hidden mdDown implementation="js">
        <Navigator PaperProps={{ style: { width: drawerWidth } }} />
      </Hidden>
    </StyledNav>
  );
}

export default MaterialNavigator;

export { drawerWidth };
