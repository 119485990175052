import {
  addLinkedWishlist,
  deleteLinkOnWishlist, deleteWishlistLink, getLinkByWishlistId,
  getLinkedWishlistsByType, updateLinkPermission,
} from "../api/LinkedWishlistsApi";
import {setLinkedWishlists} from "../slices/LinkedWishlistSlice";
import {setWishlistLinkUser} from "../slices/WishlistItemsSlice";

const getLinkedWishlistsByTypeAction = (type) => async dispatch => {
  return await getLinkedWishlistsByType(type).then(json => {
    dispatch(setLinkedWishlists(json));
  });
};

const getLinkByWishlistIdAction = (wishlistId) => async dispatch => {
  return await getLinkByWishlistId(wishlistId).then(json =>
      dispatch(setWishlistLinkUser(json))
  );
}

const addLinkedWishlistAction = (payload) => async dispatch => {
  return await addLinkedWishlist(payload);
}

const updateLinkPermissionAction = (id, payload) => async dispatch => {
  return await updateLinkPermission(id, payload);
}

const deleteWishlistLinkAction = (id) => async dispatch => {
  return await deleteWishlistLink(id);
}

const deleteLinkOnWishlistAction = (id) => async dispatch => {
  return await deleteLinkOnWishlist(id);
};

export { getLinkedWishlistsByTypeAction, getLinkByWishlistIdAction, addLinkedWishlistAction, updateLinkPermissionAction, deleteWishlistLinkAction, deleteLinkOnWishlistAction }