import React from "react";
import PaperListPage from "../../components/items/PaperList/PaperListPage";
import {setPublicListSearch} from "../../slices/BrowseSlice";
import {Typography} from '@mui/material';

function BrowseFriendListsPage() {

  return (
      <PaperListPage placeholder="Search Public Wishlists"
                     onSearchChange={setPublicListSearch}>
        {/*<BrowsePublicListsPage/>*/}
          <Typography align="center" variant="h3">
              Coming Soon
          </Typography>
      </PaperListPage>
  );
}

export default BrowseFriendListsPage;