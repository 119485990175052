import React, {useState} from "react";
import {Typography} from "@mui/material";
import SharedWithAutocomplete from "./SharedWithAutocomplete";
import SharedWithList from "./SharedWithList";
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {addLinkedWishlistAction} from "../../actions/LinkedWishlistActions";
import {getFriendsNotAlreadySharedAction} from "../../actions/FriendActions";
import LoadingComponent from "../utils/LoadingComponent";


const ShareWithFriendsTab = ({wishlistId, linkUser}) => {
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.id);
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [authorized, setAuthorized] = useState(true);

  React.useEffect(() => {
    setLoading(true);

    if (userId) {
      dispatch(getFriendsNotAlreadySharedAction(userId, wishlistId)).then(
          json => {
            setItems(json.links);
            setSelectedItems(filterLinkedItems(json.links));
          })
      .catch((e) => {
        setAuthorized(false);
      })
      .finally(() => {
        setLoading(false);
      });
    }
  }, [userId, wishlistId]);

  const onHandleChange = (e, value) => {
    dispatch(addLinkedWishlistAction({wishlistId, link: value})).then(
        (json) => {
          setSelectedItems([...selectedItems, json]);
          enqueueSnackbar("Successfully shared wishlist", {variant: 'success'});
        }).catch(error => {
      enqueueSnackbar("Failed to share wishlist", {variant: 'error'});
    });
  }

  const filterLinkedItems = (items) => {
    return items.filter(item => item.linked);
  }

  return (
      <LoadingComponent loading={loading}>
        {authorized ? (
            <React.Fragment>
              <SharedWithAutocomplete
                  {...{
                    items,
                    selectedItems,
                    setSelectedItems,
                    onHandleChange
                  }}
              />
              <SharedWithList {...{
                wishlistId,
                linkUser,
                selectedItems,
                setSelectedItems
              }} />
            </React.Fragment>
        ) : (
            <Typography align="center">
              Not Authorized
            </Typography>
        )}
      </LoadingComponent>
  )
}

export default ShareWithFriendsTab;
