import React from "react";
import {ClickAwayListener, Popper} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {MoreVert} from "@mui/icons-material";

const ButtonPopper = ({children, padding, popper, setPopper, ...other}) => {
  const popperOpen = Boolean(popper);

  const togglePopper = (event) => {
    event.stopPropagation();

    setPopper(popper ? null : event.currentTarget);
  };

  const style = Object.assign({ marginRight: "-7px" }, other['style'])

  return (
    <div {...{style, ...other}} >
      <IconButton color="inherit" onClick={togglePopper} size="small" sx={padding && {padding: padding + "!important"}}>
        <MoreVert/>
      </IconButton>
      <Popper
          open={popperOpen}
          onClose={() => setPopper(null)}
          anchorEl={popper}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
        <div>
          <ClickAwayListener onClickAway={togglePopper}>
            {children}
          </ClickAwayListener>
        </div>
      </Popper>
    </div>
  );
}

export default ButtonPopper;
