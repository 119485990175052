import {styled} from '@mui/material/styles';
import SpeedDial from '@mui/material/SpeedDial';

const styles = ({ theme }) => ({
  position: 'fixed',
  width: 56,
  bottom: theme.spacing(4),
  right: theme.spacing(4),
})

const SpeedDialContainer = styled('div')(styles);

const SpeedDialStyled = styled(SpeedDial)(styles);

export { SpeedDialContainer, SpeedDialStyled }