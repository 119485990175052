import React from "react";
import FriendForm from "./FriendForm";
import {useDispatch, useSelector} from "react-redux";
import {addFriendAction} from "../../../actions/FriendActions";
import {useSnackbar} from "notistack";
import {setAddFriend} from "../../../slices/FriendsSlice";
import {PaperStyled} from './Styles';


const AddFriend = ({item}) => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.id);
  const {enqueueSnackbar} = useSnackbar();

  const handleSubmit = (e, newItem) => {
    e.preventDefault();

    dispatch(addFriendAction(userId, newItem)).then(() => {
      enqueueSnackbar("Friend request sent", {variant: 'success'})
      dispatch(setAddFriend({}));
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    })
  }

  const handleClose = () => {
    dispatch(setAddFriend({}));
  }

  return (
      <PaperStyled>
        <FriendForm type={'ADD'} {...{item, handleSubmit, handleClose}} />
      </PaperStyled>
  );
}

export default AddFriend;
