import React from 'react';
import Grid from '@mui/material/Grid';
import {Box, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import styled from "@emotion/styled";

const BoxStyled = styled(Box)({
    padding: '10px 15px',
    '&:not(:last-of-type)': {
        borderBottom: '1px solid gray',
    },
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    }
})

const NotificationItem = ({ notification, onClickItem }) => {
    const formatDate = val => {
        const date = new Date(Date.parse(val));

        if (date) {
            return moment(Date.parse(val)).format('MMMM Do YYYY');
        }
    }

    return (
        <BoxStyled onClick={() => onClickItem(notification.link)}>
            <Grid container item justifyContent='space-between' alignItems='center' mb="2px">
                <Grid item>
                    <Typography variant='subtitle1'>
                        <b>
                            {notification.title}
                        </b>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant='subtitle2' color={'textSecondary'}>
                        { formatDate(notification.created) }
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs>
                    <Typography variant='subtitle2' color={'textSecondary'}>
                        {notification.message}
                    </Typography>
                </Grid>
                {!notification.read && (
                    <Grid item>
                        <Paper elevation={0} sx={{
                            color: 'white',
                            backgroundColor: 'red',
                            padding: '0 5px'
                        }}>
                            New
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </BoxStyled>
    );
}

export default NotificationItem;