import React from "react";
import UserForm from "../forms/UserForm";
import Typography from "@mui/material/Typography";
import {StyledPagePaper} from './styles';


function RegisterPage() {

  return (
      <StyledPagePaper>
        <Typography variant="h5" align="center">
          Register
        </Typography>
        <UserForm type={"register"}/>
      </StyledPagePaper>
  );
}

export default RegisterPage;
