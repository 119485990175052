import {createSlice} from "@reduxjs/toolkit";
import {COMMENT_STATE} from "../constants/StateConstants";

const slice = createSlice({
  name: "Comment",
  initialState: {
    selectedComment: COMMENT_STATE.DEFAULT,
  },
  reducers: {
    setSelectedComment: (state, action) => { state.selectedComment = action.payload },
  }
});

export const { setSelectedComment } = slice.actions;

export default slice.reducer