import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";

const ListIconDense = ({children}) => {

  return (
      <ListItemIcon>
        {children}
      </ListItemIcon>
  )
}

export default ListIconDense;