import React from 'react';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Stack, Typography} from '@mui/material';
import {useHistory, useLocation} from 'react-router-dom';
import {
  deleteAllNotificationsAction,
  getAllNotificationsAction,
  markAllAsReadAction
} from '../../actions/NotificationActions';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import NotificationItem from './NotificationItem';
import styled from "@emotion/styled";

const BoxStyled = styled(Box)({
  borderBottom: '1px solid gray',
  display: "flex",
  justifyContent: "flex-end",
})

const Notifications = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.id);
  const notifications = useSelector(state => state.notification.data);
  const unread = notifications.filter(it => !it.read);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    if (userId) {
      dispatch(getAllNotificationsAction());
    }
  }, [location.pathname, userId])

  const handleClick = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  }

  const handleClose = () => {
    if (unread.length > 0) {
      dispatch(markAllAsReadAction())
    }
    setAnchorEl(null);
  }

  const clickItem = (link) => {
    handleClose();
    if (link) {
      history.push(link);
    }
  }

  const clearAll = () => {
    dispatch(deleteAllNotificationsAction()).catch(error => {

    });
  }

  return (
    <React.Fragment>
      <IconButton color="inherit" onClick={handleClick} size="large">
        <Badge badgeContent={unread.length} color="secondary">
          <NotificationsIcon/>
        </Badge>
      </IconButton>
      <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={handleClose}
          PaperProps={{
            sx: notifications.length > 0
                ? { maxWidth: '350px', maxHeight: '400px'}
                : { padding: '10px 15px', overflow: 'hidden !important'}
          }}
      >
        <Stack>
          {notifications.length > 0 && (
              <BoxStyled>
                <Button color='primary' sx={{ marginRight: "10px", marginTop: "5px"}} onClick={clearAll}>
                  Clear All
                </Button>
              </BoxStyled>
          )}
          <Stack>
            {notifications.length > 0 ? (
                notifications.map(note => <NotificationItem key={note.id} notification={note} onClickItem={clickItem}/>)
            ) : (
                <Typography>
                  No notifications
                </Typography>
            )}
          </Stack>
        </Stack>
      </Popover>
    </React.Fragment>
  );
}

export default Notifications;
