import React from "react";
import Ribbon from "../../ribbons/Ribbon";
import {useSelector} from "react-redux";
import {getFullName} from "../../../util/NameUtil";
import currency from "currency.js";
import {getUserClaim} from '../../../util/ClaimUtils';

const WishlistItemRibbon = ({item, claims, contributions}) => {
  const userId = useSelector(state => state.user.id);
  const showStatus = useSelector(state => state.wishlistItems.showStatus);
  const contAmount = contributions.map(cont => cont.amount).reduce(
      (a, b) => a + b, 0);
  const userClaim = getUserClaim(claims, userId);
  const claimNames = claims.map(claim => getFullName(claim.user).trim()).join(', ');

  if (contributions.length) {
    const contrOpacity = 0.6;
    const contrColor = "#4f00a6";
    const myContrColor = "#005180";
    if (!claims || contAmount < item.price) {
      const myContribution = contributions.filter(it => it.user.id === userId);
      if (myContribution.length > 0) {
        return <Ribbon
            color={myContrColor}
            text={`You contributed ${currency(
                myContribution[0].amount).format()} (total: ${currency(
                contAmount).format()})`}/>
      } else {
        return contAmount < item.price ? (
            <Ribbon
                color={contrColor}
                opacity={contrOpacity}
                text={`Others contributed ${currency(
                    contAmount).format()} so far`}/>
        ) : (
            <Ribbon
                color={contrColor}
                opacity={contrOpacity}
                text="Fully funded"/>
        );
      }
    } else if (claims.length > 0) {
      return userClaim ? (
          <Ribbon
              color={myContrColor}
              text={`Funded (claimed by You)`}/>
      ) : (
          <Ribbon
              color={contrColor}
              opacity={contrOpacity}
              text={ showStatus ? `Funded (claims by ${claimNames})` : "Funded and claimed (name hidden)"}/>
      )
    }
  } else if (claims.length > 0) {
    return userClaim ? (
        <Ribbon color="Green" text="Claimed by You"/>
    ) : (
        <Ribbon color="#0088bf"
                text={showStatus ? `Claims by ${claimNames}` : "Claimed (name hidden)"}
                opacity={0.75}/>
    )
  }

  return null;
}

export default WishlistItemRibbon;