import WishlistItemRibbon from "./WishlistItemRibbon";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import currency from "currency.js";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Collapse from "@mui/material/Collapse";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {setWishlistItemDetailItems} from "../../../slices/WishlistItemDetailSlice";
import {isAssociateLink} from '../../../util/AffiliateUtils';
import PaperListItem from "../PaperList/PaperListItem";
import WishlistItemButtons from "./WishlistItemButtons";
import LinesEllipsis from 'react-lines-ellipsis';
import {Box, Stack, useMediaQuery, useTheme} from '@mui/material';
import {getClaimedQuantity} from '../../../util/ClaimUtils';
import {ItemImage} from './styles';

const WishlistItemView = ({
  item,
  claims,
  contributions,
  ownerId,
  toggleEdit
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(state => state.user.id);
  const linkUser = useSelector(state => state.wishlistItems.linkUser);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [expanded, setExpanded] = useState(false);
  const owner = ownerId === userId;
  const edit = linkUser.permission === 'ADMIN' || linkUser.permission
      === 'EDIT';
  const contAmount = contributions.map(cont => cont.amount).reduce(
      (a, b) => a + b, 0);
  const claimedQuantity = getClaimedQuantity(claims);

  const gridDetailsStyle = ((claims && claims.length) || (contributions && contributions.length))
      ? {paddingTop: 15} : {};


  const handleClick = () => {
    if (!owner) {
      dispatch(setWishlistItemDetailItems({}));
      history.push(`/lists/${item.wishlistId}/items/${item.id}`)
    }
  };

  const handleExpand = e => {
    e.stopPropagation();

    setExpanded(!expanded);
  };

  return (
    <PaperListItem
        hover
        onClick={handleClick}
        clickable={!owner}>
      {(claims || contributions) && (
          <WishlistItemRibbon {...{item, claims, contributions}} />
      )}
      <Stack direction="row" spacing={smUp ? 2 : 1} style={gridDetailsStyle}>
        { item.imageUrl && (
            <Box>
              <ItemImage src={item.imageUrl} alt="Image of item"/>
            </Box>
        )}
        <Stack spacing={1} width="100%">
          <Stack>
            {item.link ?
                <Link
                  href={item.link}
                  target="_blank"
                  variant="h6"
                  color="#00b6ff"
                  onClick={e => e.stopPropagation()}
                  underline="hover">
                  <LinesEllipsis text={item.title} maxLine={2} basedOn='letters' />
                </Link>
                :
                <Typography variant="h6">
                  <LinesEllipsis text={item.title} maxLine={2} basedOn='letters' />
                </Typography>
            }
            {isAssociateLink(item.link) && (
                <Typography variant={'caption'} mt="-5px">
                  Amazon affiliate link
                </Typography>
            )}
          </Stack>
          {item.quantity > 0 && (
              <Typography
                  color={item.imageUrl ? 'inherit' : 'textSecondary'}>
                Quantity: {item.quantity}
                &nbsp;{claimedQuantity > 0 && (`(${claimedQuantity === item.quantity ? 'fully' : claimedQuantity} claimed)`)}
              </Typography>
          )}
          {item.price > 0 && (
              <Typography
                  color={item.imageUrl ? 'inherit' : 'textSecondary'}>
                Price: {currency(item.price).format()} &nbsp;
                {contAmount > 0 &&
                <span>({currency(
                    item.price - contAmount).format()} remaining)</span>}
              </Typography>
          )}
        </Stack>
        <Stack
              spacing={1}
              alignContent="center"
              marginRight="-5px !important"
              justifyContent={edit ? 'space-between' : 'flex-end'}
              width="38px">
          {edit && (
              <WishlistItemButtons item={item} toggleEdit={toggleEdit}/>
          )}
          {item.description && (
              <IconButton color="inherit" onClick={handleExpand} size="large">
                {!expanded ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
              </IconButton>
          )}
        </Stack>
      </Stack>
      {item.description && (
          <Collapse in={expanded}>
            <Typography mt={theme.spacing(3)}>
              {item.description}
            </Typography>
          </Collapse>
      )}
    </PaperListItem>
  );
}

export default WishlistItemView;
