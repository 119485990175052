import React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import {Link, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {setOpen, setSelected, setTitle} from "../../slices/NavigatorSlice";
import {logoutAction} from "../../actions/LogoutAction";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Collapse, ListItemIcon} from '@mui/material';
import LinkItem from './LinkItem';
import useCategories from './useCategories';
import {showSignedIn} from '../../util/UserUtils';
import {
  StyledDrawer,
  StyledListItem,
  StyledListItemText,
  StyledNavListItem,
  StyledNavTitleListItem
} from "./styles";

function Navigator(props) {
  const isLoggedIn = useSelector(state => state.user.loggedIn);
  const [toggles, setToggles] = React.useState([{
    id: 'Browse',
    open: false,
  }]);
  const dispatch = useDispatch();
  const location = useLocation();
  const categories = useCategories();

  const selectOption = (child) => {
    if (child.to === location.pathname) {
      dispatch(setTitle(child.title ? child.title : child.id));
      dispatch(setSelected(child.id));

      if (child.action) {
        child.action();
      }
    } else if (child.children) {
      child.children.forEach(subChild => {
        selectOption(subChild);
      })
    }
  }

  React.useEffect(() => {
    if (location.pathname === "/") {
      dispatch(setTitle("Home"));
      dispatch(setSelected("Home"));
    } else {
      categories.forEach(category => {
        selectOption(category)
      })
    }
  }, [location, dispatch, selectOption, categories]);

  const handleClick = (id) => {
    if (id === 'Logout') {
      dispatch(logoutAction());
    } else {
      dispatch(setSelected(id));
    }
    dispatch(setOpen(false));
  };

  const getOpen = (id) => {
    const open = toggles.filter(toggle => toggle.id === id)
        .map(toggle => toggle.open);
    return open.length > 0 ? open[0] : false;
  }

  const handleToggle = (childId) => {
    setToggles(toggles.map(
        toggle => toggle.id === childId
            ? { ...toggle, open: !toggle.open }
            : toggle ));
  }

  return (
      <StyledDrawer variant="permanent" {...props}>
        <List disablePadding>
          <StyledNavTitleListItem>
            Simple Wishlist
          </StyledNavTitleListItem>
          <StyledNavListItem component={Link} to="/" onClick={() => handleClick("Home")}>
            <ListItemIcon>
              <HomeIcon/>
            </ListItemIcon>
            <ListItemText>
              Home
            </ListItemText>
          </StyledNavListItem>
          {categories.map(({id, loggedIn, children}) => (showSignedIn(isLoggedIn, loggedIn)) && (
              <React.Fragment key={id}>
                <ListItem sx={{ paddingTop: 2, paddingBottom: 2}}>
                  <StyledListItemText>{id}</StyledListItemText>
                </ListItem>
                {children.map(({id: childId, icon, loggedIn, to, children}) => (showSignedIn(isLoggedIn, loggedIn)) && (
                    children ? (
                        <div key={childId}>
                          <StyledListItem
                              key={childId}
                              button
                              onClick={() => handleToggle(childId)}>
                            <ListItemIcon>
                              { getOpen(childId) ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                            </ListItemIcon>
                            <ListItemText>
                              {childId}
                            </ListItemText>
                          </StyledListItem>
                          <Collapse in={getOpen(childId)}>
                            {children.map(({id: subId, icon, to}) => (
                                <LinkItem key={subId} id={subId} {...{icon, to, handleClick}} nested={true} />
                            ))}
                          </Collapse>
                        </div>
                    ) : (
                        <LinkItem key={childId} id={childId} {...{icon, to, handleClick}} />
                    )
                ))}

                <Divider sx={{ marginTop: 2 }}/>
              </React.Fragment>
          ))}
        </List>
      </StyledDrawer>
  );
}


export default Navigator;
