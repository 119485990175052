import React from 'react';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material';

const Footer = () => {
    const theme = useTheme();

    return (
        <footer style={{
            padding: theme.spacing(2),
            background: '#eaeff1',
        }}>
            <Typography variant="body2" color="textSecondary" align="center">
                As an Amazon Associate I earn from qualifying purchases
            </Typography>
        </footer>
    );
}

export default Footer;
