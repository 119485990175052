import React, {useState} from "react";
import ButtonPopper from "../../utils/ButtonPopper";
import {List} from "@mui/material";
import Paper from "@mui/material/Paper";
import ListIconDense from "../../utils/ListIconDense";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import DeleteAction from "../../buttons/DeleteAction";
import DeleteIcon from '@mui/icons-material/Delete';
import PaletteIcon from '@mui/icons-material/Palette';

const FriendButton = ({item, onEdit, onDelete}) => {
  const [popper, setPopper] = useState(null);

  const handleDelete = (e) => {
    e.stopPropagation();

    setPopper(null);
    onDelete();
  };

  const changeColor = (e) => {
    setPopper(null);
    onEdit()
  }

  return (
      <ButtonPopper padding="4px" {...{popper, setPopper}}>
        <Paper>
          <List dense>
            <ListItem button onClick={changeColor}>
              <ListIconDense>
                <PaletteIcon />
              </ListIconDense>
              <ListItemText primary="Change Color"/>
            </ListItem>
            <DeleteAction
                handleDelete={handleDelete}
                item={item}
                name={`${item.friend.firstName} ${item.friend.lastName}`}>
              <ListItem button>
                <ListIconDense>
                  <DeleteIcon />
                </ListIconDense>
                <ListItemText primary="Delete"/>
              </ListItem>
            </DeleteAction>
          </List>
        </Paper>
      </ButtonPopper>
  )
}

export default FriendButton;