import {createSlice} from "@reduxjs/toolkit";
import {USER_ITEM_STATE} from "../constants/StateConstants";

const slice = createSlice({
  name: "UserItems",
  initialState: {
    userItems: [],
    selectedUserItem: USER_ITEM_STATE.DEFAULT,
    userItemSearch: "",
    addItem: {},
    loading: false,
  },
  reducers: {
    setAddUserItem: (state, action) => {
      state.addItem = action.payload;
    },
    setUserItems: (state, action) => {
      state.userItems = action.payload
    },
    setSelectedUserItem: (state, action) => {
      state.selectedUserItem = action.payload
    },
    setUserItemSearch: (state, action) => {
      state.userItemSearch = action.payload
    },
    setUserItemLoading: (state, action) => {
      state.loading = action.payload;
    },
    reset: state => {
      state.userItems = [];
      state.selectedUserItem = USER_ITEM_STATE.DEFAULT;
      state.userItemSearch = "";
    },
  }
});

export const {
  setAddUserItem,
  setUserItems,
  setSelectedUserItem,
  setUserItemSearch,
    setUserItemLoading,
  reset
} = slice.actions;

export default slice.reducer;