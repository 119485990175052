import Button from "@mui/material/Button";
import React from "react";
import {useDispatch} from "react-redux";
import { setSelectedComment} from "../../slices/CommentSlice";
import {MODAL_TYPE} from "../../constants/ModalConstants";
import CommentModal from "../modals/CommentModal";
import {COMMENT_STATE} from "../../constants/StateConstants";
import {setCommentModal} from "../../slices/ModalSlice";

function CommentButton() {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    dispatch(setSelectedComment(COMMENT_STATE.DEFAULT));
    dispatch(setCommentModal({ open: true, type: MODAL_TYPE.ADD }));
  };

  return (
      <React.Fragment>
        <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            fullWidth>
          Comment
        </Button>
        <CommentModal />
      </React.Fragment>
  );
}

export default CommentButton;