import React from "react";
import {
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import _ from 'underscore';
import {useDispatch} from "react-redux";
import {searchAmazonAction, searchAmazonProductAction} from "../../../actions/AmzonSearchAction";
import SearchResult from "./SearchResult";
import {useSnackbar} from "notistack";
import {ContainerStyled} from './styles';


const AmazonImport = ({onBack, onSelect}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [value, setValue] = React.useState('');
  const [results, setResults] = React.useState({ searchResults: [] });
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isAmazonUrl(value)) {
      handleProductSubmit(value);
    } else {
      setLoading(true);
      dispatch(searchAmazonAction(value)).then(json => {
        setResults(json);
      }).catch(error => {
        enqueueSnackbar(error, {variant: 'error'})
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  const handlePaste = (e) => {
    const url = e.clipboardData.getData('Text').split('?')[0];

    if (url.includes('http') && url.includes('amazon.')) {
      handleProductSubmit(url);
    }
  }

  const handleProductSubmit = (url) => {
    setLoading(true);
    dispatch(searchAmazonProductAction(url)).then(json => {
      onSelect(null, json.product);
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'});
    }).finally(() => {
      setLoading(false);
    })
  }

  const isAmazonUrl = (url) => {
    return url.includes('http') && url.includes('amazon.');
  }

  return (
    <ContainerStyled>
      <Grid container spacing={1} alignItems="center" justifyContent="space-between" width="100%">
        <Grid item>
          <Typography variant="h6">
            Amazon Search
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={onBack} size="large">
            <CloseIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <form onSubmit={handleSubmit} >
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs>
                <TextField name="terms" fullWidth={true} value={value}
                           label="Search Term or Paste URL"
                           variant="standard"
                           autoFocus={true}
                           onPaste={handlePaste}
                           onChange={(e) => setValue(e.target.value)}/>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  {loading ? <CircularProgress color="inherit"/> : 'Search'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item sx={{
          maxHeight: 500,
          overflowY: 'auto',
          overflowX: 'hidden',
        }}>
          <Collapse in={!_.isEmpty(results.searchResults)} mountOnEnter
                    unmountOnExit>
            <Grid container spacing={2} direction="column">
              {results.searchResults.map(result => (
                  <SearchResult result={result} onSelect={onSelect}/>
              ))}
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </ContainerStyled>
  );
}

export default AmazonImport;
