import {getSubscriptions, subscribe, unsubscribeAll} from "../api/EmailApi";

const unsubscribeAllAction = (userId) => async dispatch => {
  return unsubscribeAll(userId).then((json) => {
    dispatch(getSubscriptionsAction(userId));
  });
};

const subscribeAction = (userId, payload) => async dispatch => {
  return subscribe(userId, payload).then((json) => {
    dispatch(getSubscriptionsAction(userId));
  });
}

const getSubscriptionsAction  = (userId) => async dispatch => {
  return getSubscriptions(userId);
}

export { unsubscribeAllAction, subscribeAction, getSubscriptionsAction }