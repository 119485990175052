const WISHLIST_STATE = {
  DEFAULT: {
    name: ''
  }
};

const USER_ITEM_STATE = {
  DEFAULT: {
    price: 0.00,
    quantity: 1,
  }
};

const SHARED_ITEM_STATE = {
  DEFAULT: {
    price: 0.00,
    quantity: 1,
    priority: 'Normal',
    addToItems: false,
  }
};

const COMMENT_STATE = {
  DEFAULT: {
    content: '',
  }
};

const FRIEND_STATE = {
  DEFAULT: {
    email: '',
    color: '',
  }
}

export {
  WISHLIST_STATE,
  USER_ITEM_STATE,
  COMMENT_STATE,
  SHARED_ITEM_STATE,
  FRIEND_STATE
};