import React from 'react';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/Add';
import {useDispatch, useSelector} from "react-redux";
import LinkUserItemModal from "../modals/LinkUserItemModal";
import ShareIcon from '@mui/icons-material/Share';
import {useHistory} from 'react-router-dom';
import {setWishlistItemsModal} from "../../slices/ModalSlice";
import {MODAL_TYPE} from "../../constants/ModalConstants";
import {setAddWishlistItem} from "../../slices/WishlistItemsSlice";
import {SHARED_ITEM_STATE} from "../../constants/StateConstants";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Fab from "@mui/material/Fab";
import {Grow, useTheme} from "@mui/material";
import {SpeedDialContainer, SpeedDialStyled} from './styles';

const WishlistItemSpeedDial = ({wishlistId}) => {
  const theme = useTheme();
  const wishlist = useSelector(state => state.wishlistItems.wishlist);
  const linkUser = useSelector(state => state.wishlistItems.linkUser);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const owner = wishlist.user && wishlist.user.id === user.id;
  const editable = owner || (linkUser.permission === 'EDIT'
      || linkUser.permission === 'ADMIN');
  const sharable = owner || (linkUser.permission === 'SHARE'
      || linkUser.permission === 'ADMIN');
  const [open, setOpen] = React.useState(false);

  const onAddWishlistItem = () => {
    handleClose();
    dispatch(setAddWishlistItem({add: true, ...SHARED_ITEM_STATE.DEFAULT}));
    //dispatch(setSharedItemModal({ open: true, type: MODAL_TYPE.ADD, item: SHARED_ITEM_STATE.DEFAULT }));
  }

  const onImport = () => {
    dispatch(setAddWishlistItem(
        {add: true, amazon: true, ...SHARED_ITEM_STATE.DEFAULT}));
  }

  const onLinkUserItem = () => {
    handleClose();
    dispatch(setWishlistItemsModal({open: true, type: MODAL_TYPE.ADD}));
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <LinkUserItemModal/>

      <SpeedDialContainer>
        <Grow in={sharable && !open}>
          <Fab aria-label="Share"
               size="small"
               onClick={() => history.push(`/lists/${wishlist.id}/share`)}
               sx={{
                 backgroundColor: "#00ad00",
                 color: "rgba(0,0,0,0.85)",
                 bottom: "70px",
                 left: "7px",
               }}>
            <ShareIcon/>
          </Fab>
        </Grow>
        {(editable || owner) && (
            <SpeedDialStyled
                onClose={handleClose}
                onOpen={handleOpen}
                icon={<SpeedDialIcon/>}
                open={open}
                ariaLabel="Wishlist Actions">
              {editable && (
                  <SpeedDialAction
                      icon={<AddIcon/>}
                      tooltipOpen
                      open={true}
                      tooltipTitle={"Add Item"}
                      onClick={onAddWishlistItem}
                      sx={{
                        '& .MuiSpeedDialAction-staticTooltipLabel': {
                          width: 101
                        }
                      }}
                  />
              )}
              {owner && (
                  <SpeedDialAction
                      icon={<LinkIcon/>}
                      tooltipTitle={"Library Item"}
                      tooltipOpen
                      onClick={onLinkUserItem}
                      sx={{
                        '& .MuiSpeedDialAction-staticTooltipLabel': {
                          width: 122
                        }
                      }}
                  />
              )}
              {editable && (
                  <SpeedDialAction
                      icon={<ImportExportIcon/>}
                      tooltipTitle={"Import Item"}
                      tooltipOpen
                      onClick={onImport}
                      sx={{
                        '& .MuiSpeedDialAction-staticTooltipLabel': {
                          width: 118
                        }
                      }}
                  />
              )}
            </SpeedDialStyled>
        )}
      </SpeedDialContainer>
    </React.Fragment>
  );
}

export default WishlistItemSpeedDial;