import React from "react";
import {Collapse} from "@mui/material";
import AmazonImport from "../Search/AmazonImport";
import {useDispatch, useSelector} from "react-redux";
import {USER_ITEM_STATE} from "../../../constants/StateConstants";
import AddUserItem from "./AddUserItem";
import {setAddUserItem} from "../../../slices/UserItemsSlice";
import {getFirstWords} from "../../../util/StringUtils";
import {addAssociateId} from '../../../util/AffiliateUtils';
import useShowAddItems from '../../hooks/useShowAddItems';

const UserItemActionSection = () => {
  const dispatch = useDispatch();
  const addItem = useSelector(state => state.userItems.addItem);
  const { showAmazon, showAdd } = useShowAddItems(addItem);

  const onBack = () => {
    dispatch(setAddUserItem({}));
  }

  const onSelect = (e, result) => {
    dispatch(setAddUserItem({
      add: true,
      ...USER_ITEM_STATE.DEFAULT,
      title: getFirstWords(result.title),
      price: result.price ? result.price.value : USER_ITEM_STATE.DEFAULT.price,
      link: addAssociateId(result.link),
      imageUrl: result.image,
    }))
  }

  return (
      <React.Fragment>
        <Collapse in={showAdd} sx={{ width: '100%' }} mountOnEnter unmountOnExit>
          <AddUserItem item={addItem}/>
        </Collapse>
        <Collapse in={showAmazon}
                  sx={{ width: '100%' }}
                  mountOnEnter
                  unmountOnExit>
          <AmazonImport item={addItem} onBack={onBack} onSelect={onSelect}/>
        </Collapse>
      </React.Fragment>
  );
}

export default UserItemActionSection;
