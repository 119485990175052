import React, {useState} from "react";
import {FormControl} from "@mui/material";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {FormTextField, WishlistItemFormStyled} from './styles';

const WishlistItemForm = ({
  item,
  showPriority,
  showAddToItems,
  handleSubmit,
  handleCancel
}) => {
  const [editItem, setEditItem] = useState(item);

  React.useEffect(() => {
    setEditItem(item);
  }, [item])

  const handleChange = (e) => {
    let newItem = {...editItem};

    if (e.target.type === 'checkbox') {
      newItem[e.target.id] = e.target.checked
    } else if (e.target.name) {
      newItem[e.target.name] = e.target.value
    } else {
      newItem[e.target.id] = e.target.value;
    }

    setEditItem(newItem)
  };

  const priceProps = {
    step: 0.01,
    min: 0
  };

  const quantityProps = {
    step: 1,
    min: 0
  };

  return (
    <React.Fragment>
      <Typography variant="h6">
        {editItem.add ? "Add" : "Update"} Item
      </Typography>
      <WishlistItemFormStyled onSubmit={(e) => handleSubmit(e, editItem)}>
        <FormTextField id="title" label="Title" autoComplete="off"
                   fullWidth={true}
                   autoFocus={true}
                   value={editItem.title} onChange={handleChange}
                   required
                   variant="standard" />
        <FormTextField id="description" label="Description" multiline={true}
                   variant="standard"
                   maxRows={4} autoComplete="off" fullWidth={true}
                   onChange={handleChange}
                   value={editItem.description} />
        <FormTextField id="price" label="Price" autoComplete="off" type="number"
                   variant="standard"
                   inputProps={priceProps} fullWidth={true}
                   value={editItem.price} onChange={handleChange} />
        <FormTextField id="quantity" label="Quantity" autoComplete="off"
                   variant="standard"
                   type="number" inputProps={quantityProps} fullWidth={true}
                   value={editItem.quantity} onChange={handleChange} />
        {showPriority && (
            <FormControl fullWidth={true} sx={{ margin: "5px 0 5px 0" }} variant="standard">
              <InputLabel id="priority-label">Priority</InputLabel>
              <Select
                  labelId={'priority-label'}
                  fullWidth={true}
                  variant="standard"
                  value={editItem.priority}
                  onChange={handleChange}
                  inputProps={{
                    name: 'priority',
                    id: 'priority',
                  }}
              >
                <MenuItem value={'High'}>High</MenuItem>
                <MenuItem value={'Normal'}>Normal</MenuItem>
                <MenuItem value={'Low'}>Low</MenuItem>
              </Select>
            </FormControl>
        )}
        <FormTextField id="link" label="Link" autoComplete="off" fullWidth={true}
                   variant="standard"
                   value={editItem.link || ''}
                   onChange={handleChange} />
        <FormTextField id="imageUrl" label="Image URL" autoComplete="off"
                   variant="standard"
                   fullWidth={true} value={editItem.imageUrl || ''}
                   onChange={handleChange} />
        {showAddToItems && (
            <FormControlLabel
                control={
                  <Checkbox
                      checked={editItem.addToItems}
                      onChange={handleChange}
                      color="primary"
                      inputProps={{
                        name: 'addToItems',
                        id: 'addToItems',
                      }}
                  />
                }
                label="Add To My Items"
            />
        )}
        <Grid container spacing={2} justifyContent="flex-end" mt="20px" textAlign="center">
          <Grid item>
            <Button variant={"text"} onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      marginLeft: 0
                    }}>
              {editItem.add ? "Add" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </WishlistItemFormStyled>
    </React.Fragment>
  );
}

export default WishlistItemForm;
