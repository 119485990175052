const colors = [
  'rgb(255, 118, 0)',
  'rgb(255, 200, 47)',
  'rgb(63,134,162)',
  'rgb(19,162,54)',
  'rgb(103,53,184)',
  'rgb(202, 62, 94)',
  'rgb(255, 0, 0)',
  'rgb(52,96,36)',
];

const getLetterColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
}

export default getLetterColor;