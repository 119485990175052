import FetchWrapper from "./FetchWrapper";

const getClaimByWishlistItem = (wishlistId, id) => {
  return FetchWrapper(`/wishlists/${wishlistId}/items/${id}/claims`);
};

const claimItem = (payload) => {
  return FetchWrapper(`/claims`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const updateClaimItem = (id, payload) => {
  return FetchWrapper(`/claims/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const unclaimItem = (id) => {
  return FetchWrapper(`/claims/${id}`, {
    method: 'DELETE'
  });
};

export {getClaimByWishlistItem, claimItem, updateClaimItem, unclaimItem}