import React, {useState} from "react";
import {Grow, Slide, Tab, Tabs, Typography} from "@mui/material";
import ShareWithFriendsTab from "./ShareWithFriendsTab";
import ShareWithLinksTab from "./ShareWithLinksTab";
import {getLinkByWishlistId} from '../../api/LinkedWishlistsApi';
import {StyledTab, StyledTabs} from './styles';


const ShareSwitch = ({wishlistId}) => {
  const [selected, setSelected] = React.useState('friends');
  const [linkUser, setLinkUser] = useState({});

  React.useEffect(() => {
    getLinkByWishlistId(wishlistId).then(json => {
      setLinkUser(json);
    });
  }, [wishlistId])

  const handleChange = (event, newValue) => {
    setSelected(newValue);
  };

  const TabPanel = ({children, value, tabName, ...other}) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== tabName}
            style={{ paddingTop: 10 }}
            {...other}
        >
          {value === tabName && (children)}
        </div>
    )
  }

  return (
      <React.Fragment>
        <StyledTabs value={selected} onChange={handleChange} variant="fullWidth">
          <StyledTab label="Friends" value="friends" />
          <StyledTab label="Links" value="links" />
        </StyledTabs>
        <TabPanel value={selected} tabName="friends">
          <Grow in direction="right">
            <div>
              <ShareWithFriendsTab wishlistId={wishlistId} linkUser={linkUser}/>
            </div>
          </Grow>
        </TabPanel>
        <TabPanel value={selected} tabName="links">
          <Grow in direction="left">
            <div>
              <ShareWithLinksTab wishlistId={wishlistId} linkUser={linkUser}/>
            </div>
          </Grow>
        </TabPanel>
      </React.Fragment>
  )
}

export default ShareSwitch;
