import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "WishlistsItemDetail",
  initialState: {
    item: {},
    contributions: {},
    claims: [],
    comments: [],
    loading: false,
  },
  reducers: {
    setWishlistItemDetailItems: (state, action) => {
      state.item = action.payload
    },
    setContributions: (state, action) => {
      state.contributions = action.payload
    },
    setClaims: (state, action) => {
      state.claims = action.payload
    },
    setComments: (state, action) => {
      state.comments = action.payload
    },
    setWishlistItemDetailLoading: (state, action) => {
      state.loading = action.payload;
    },
    reset: state => {
      state.item = {};
      state.contributions = {};
      state.claims = [];
      state.comments = [];
    }
  }
});

export const {
  setWishlistItemDetailItems,
  setContributions,
  setClaims,
  setComments,
  setWishlistItemDetailLoading,
  reset
} = slice.actions;

export default slice.reducer