import {
  addShareLink,
  addWishlist, deleteShareLink,
  deleteWishlist,
  getShareLinks,
  getWishlistById,
  getWishlists,
  getWishlistsSharedWithMe,
  updateShareLink,
  updateWishlist
} from "../api/WishlistsApi";
import {setWishlists, setWishlistSearch} from "../slices/WishlistsSlice";
import {getLinkedWishlistsByTypeAction} from "./LinkedWishlistActions";
import {setWishlist} from "../slices/WishlistItemsSlice";

const getWishlistsAction = () => async dispatch => {
  return await getWishlists().then(json => {
    if (json) {
      dispatch(setWishlists(json));
      dispatch(setWishlistSearch(""));
    }
  });
};

const getWishlistsSharedWithMeAction = () => async dispatch => {
  return await getWishlistsSharedWithMe().then(json => {
    if (json) {
      dispatch(setWishlists(json));
      dispatch(setWishlistSearch(""));
    }
  });
};

const getWishlistByIdAction = (id) => async dispatch => {
  return await getWishlistById(id).then(json => {
    dispatch(setWishlist(json));

    return json;
  });
};

const addWishlistAction = (payload) => async (dispatch, getState) => {
  return await addWishlist(payload).then(json => {
    dispatch(
        getLinkedWishlistsByTypeAction(getState().wishlists.wishlistUserType));

    return json;
  });
};

const updateWishlistAction = (payload) => async (dispatch, getState) => {
  return await updateWishlist(payload).then(json => {
    dispatch(
        getLinkedWishlistsByTypeAction(getState().wishlists.wishlistUserType));

    return json;
  });
};

const deleteWishlistAction = (id) => async (dispatch, getState) => {
  return await deleteWishlist(id).then(json => {
    dispatch(
        getLinkedWishlistsByTypeAction(getState().wishlists.wishlistUserType));

    return json;
  });
};

const addShareLinkAction = (id, payload) => async (dispatch) => {
  return await addShareLink(id, payload);
}

const updateShareLinkAction = (id, shareId, payload) => async (dispatch) => {
  return await updateShareLink(id, shareId, payload);
}

const deleteShareLinkAction = (id, shareId) => async (dispatch) => {
  return await deleteShareLink(id, shareId);
}

const getShareLinksAction = (id) => async (dispatch) => {
  return await getShareLinks(id);
}

export {
  getWishlistsAction,
  getWishlistsSharedWithMeAction,
  getWishlistByIdAction,
  getShareLinksAction,
  addWishlistAction,
  updateWishlistAction,
  deleteWishlistAction,
  addShareLinkAction,
  updateShareLinkAction,
    deleteShareLinkAction,
};