import FetchWrapper from "./FetchWrapper";
import { SERVER } from '../constants/ServiceConstants'

const getWishlistItemsById = (id) => {
  return FetchWrapper(`/wishlists/${id}/items`);
};

const addWishlistItem = (wishlistId, payload) => {
  return FetchWrapper(`/wishlists/${wishlistId}/items`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const linkUserItems = (wishlistId, payload) => {
  return FetchWrapper(`/wishlists/${wishlistId}/items/link`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const updateWishlistItem = (wishlistId, payload) => {
  return FetchWrapper(`/wishlists/${wishlistId}/items/${payload.id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
}


const deleteWishlistItem = (wishlistId, id) => {
  return FetchWrapper(`/wishlists/${wishlistId}/items/${id}`, {
    method: 'DELETE'
  });
};

export { getWishlistItemsById, addWishlistItem, linkUserItems, updateWishlistItem, deleteWishlistItem }