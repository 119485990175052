import React from "react";
import {Button, Stack} from "@mui/material";
import PaperListItem from "../PaperList/PaperListItem";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import useFilteredResults from "../../hooks/useFilteredResults";
import {
  browseFriendWishlistsAction,
  linkWishlistToSelfAction
} from "../../../actions/BrowseWishlistActions";
import {useLocation} from "react-router-dom";
import LoadingComponent from '../../utils/LoadingComponent';
import {setBrowseLoading} from '../../../slices/BrowseSlice';
import BrowseFriendEmpty from '../../../pages/Empty/BrowseFriendEmpty';
import WishlistContent from '../Wishlist/WishlistContent';
import Grid from '@mui/material/Grid';
import BrowseButtons from './BrowseButtons';

const BrowseFriendWishlistsList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {enqueueSnackbar} = useSnackbar();
  const userId = useSelector(state => state.user.id);
  const {friendWishlists, friendListSearch, loading} = useSelector(
      state => state.browse);
  const items = useFilteredResults(friendWishlists, friendListSearch);

  React.useEffect(() => {
    dispatch(setBrowseLoading(true));
    dispatch(browseFriendWishlistsAction(userId)).catch(error => {
      enqueueSnackbar("Failed to retrieve friends lists", {variant: 'error'});
    }).finally(() => {
      dispatch(setBrowseLoading(false));
    })
  }, [location, userId]);

  const handleFollow = (wishlist) => {
    dispatch(linkWishlistToSelfAction('FRIENDS', {
      wishlistId: wishlist.wishlistId,
      userId: userId,
    })).then(() => {
      dispatch(browseFriendWishlistsAction(userId))
      enqueueSnackbar("Followed wishlist", {variant: 'success'});
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'});
    })
  }

  return (
      <LoadingComponent loading={loading}>
        {friendWishlists.length > 0 ? (
            <Stack spacing={1} marginTop="6px">
              { items.map(item => (
                  <PaperListItem hover>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <WishlistContent wishlist={item} friend={item.friend}
                                         shared={true}/>
                      </Grid>
                      <Grid item>
                        <BrowseButtons item={item} onFollow={handleFollow} />
                      </Grid>
                    </Grid>
                  </PaperListItem>
              ))}
            </Stack>
        ) : <BrowseFriendEmpty/>}
      </LoadingComponent>
  );
}

export default BrowseFriendWishlistsList;
