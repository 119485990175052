import {styled} from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';

const ContainerStyled = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2, 2.5, 3),
  margin: '0 2px 10px',
  ['& form']: {
    paddingTop: 10,
    width: '100%'
  }
}));

const ResultImage = styled('img')({
  maxHeight: "77px",
  width: "65px",
  objectFit: "contain"
});

const ResultGrid = styled(Grid)({
  padding: '5px 10px',
  color: 'inherit',

  '&:hover': {
    textDecoration: 'none'
  }
});

const CheckIconStyled = styled(CheckIcon)({
  color: 'orange !important'
})

const GridItemRating = styled(Grid)({
  minHeight: "37px"
})

export { ContainerStyled, ResultImage, ResultGrid, CheckIconStyled, GridItemRating }
