import {login, refreshToken} from "../api/LoginApi";
import {loginUser} from "../slices/UserSlice";
import {getUserDetailsAction} from "./UserActions";
import Cookies from 'js-cookie';
import store from '../store/Store';
import {expireSession} from './LogoutAction';
import {CookieConstants} from "../constants/CookieConstants";

let refreshing = false;

const loginAction = (payload) => async (dispatch, getState) => {
  return await login(payload).then(response => {
    if (!response.ok && response.status === 401) {
      return Promise.reject("Invalid username or password")
    } else if (!response.ok) {
      return Promise.reject("Failed to login")
    }

    if (Cookies.get(CookieConstants.shareId)) {
      Cookies.remove(CookieConstants.shareId);
    }

    return response.json();
  }).then(json => {
    handleLogin(dispatch, getState, json);

    return json
  });
};

const refreshTokenAction = (payload) => (dispatch, getState) => {
  if (!refreshing) {
    refreshing = true;
    return refreshToken({refreshToken: payload}).then(response => {
      if (response.status === 401) {
        store.dispatch(expireSession());
        throw new Error('Credentials expired');
      } else if (!response.ok) {
        throw new Error("An error occurred refreshing token")
      }

      return response.json();
    }).then(json => {
      handleLogin(dispatch, getState, json);

      return json
    }).catch(error => {
      store.dispatch(expireSession());
    }).finally(() => {
      refreshing = false;
    });
  } else {
    return new Promise((resolve, reject) => {
      (function check() {
        if (refreshing) {
          setTimeout(check, 100)
        } else {
          resolve();
        }
      })();
    });
  }
};

const handleLogin = (dispatch, getState, json) => {
  Cookies.set(CookieConstants.token, json.accessToken);
  Cookies.set(CookieConstants.refreshToken, json.refreshToken, {expires: 7});

  dispatch(loginUser(json.accessToken));
  if (!getState().user.details.id) {
    dispatch(getUserDetailsAction());
  }
}

export {loginAction, refreshTokenAction};
