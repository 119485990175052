import {getBaseUrl} from "../util/FetchUtil";

const register = (payload) => {
  return fetch(`${getBaseUrl()}/users/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};


export {
  register
}