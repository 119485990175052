import {setComments} from "../slices/WishlistItemDetailSlice";
import {
  addComment, deleteComment,
  updateComment,
  getCommentsByWishlistItem
} from "../api/CommentApi";

const getCommentsByWishlistItemAction = (wishlistId, id) => async dispatch => {
  return getCommentsByWishlistItem(wishlistId, id).then(json => {
    dispatch(setComments(json));
  })
};

const addCommentAction = (wishlistId, payload) => async dispatch => {
  return await addComment(payload).then(json => {
    dispatch(getCommentsByWishlistItemAction(wishlistId, payload.wishlistItemId));

    return json;
  });
};

const updateCommentAction = (wishlistId, payload) => async dispatch => {
  return await updateComment(payload).then(json => {
    dispatch(getCommentsByWishlistItemAction(wishlistId, payload.wishlistItemId));

    return json;
  });
};

const deleteCommentAction = (wishlistId, wishlistItemid, id) => async dispatch => {
  return await deleteComment(id).then(json => {
    dispatch(getCommentsByWishlistItemAction(wishlistId, wishlistItemid));

    return json;
  });
};

export { getCommentsByWishlistItemAction, addCommentAction, updateCommentAction, deleteCommentAction }