import React from "react";
import Wishlist from "./Wishlist";
import {Collapse} from "@mui/material";
import EditWishlist from "./EditWishlist";

const WishlistSwitch = ({item, shared}) => {
  const [edit, setEdit] = React.useState(false);

  const onEdit = () => {
    setEdit(!edit);
  }

  return (
      <div style={{ margin: '8px auto' }}>
        <Collapse in={edit} mountOnEnter unmountOnExit>
          <EditWishlist {...{item, onEdit}} />
        </Collapse>
        <Collapse in={!edit} mountOnEnter unmountOnExit>
          <Wishlist {...{item, shared, onEdit}} />
        </Collapse>
      </div>
  );
}

export default WishlistSwitch;