const cards = [
  {
    title: 'Wishlists',
    signedIn: true,
    body: "Create Wishlists to add user items to and share with others",
    button: "Learn More",
    onClick: (history) => {
      history.push("/lists")
    }
  },
  {
    title: 'Shared With Me',
    body: "View wishlists that have been shared with you",
    button: "Learn More",
    onClick: (history) => {
      history.push("/shared")
    }
  },
  {
    title: 'Items',
    signedIn: true,
    body: "Create user items to be linked to wishlists",
    button: "Learn More",
    onClick: (history) => {
      history.push("/items")
    }
  },
]

export {
  cards
}