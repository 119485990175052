import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "Notification",
  initialState: {
    data: [],
  },
  reducers: {
    setNotifications: (state, action) => { state.data = action.payload },
  }
});

export const { setNotifications } = slice.actions;

export default slice.reducer;