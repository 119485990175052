import React from "react";
import useFilteredResults from "../../hooks/useFilteredResults";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {
  getFriendRequestsAction,
  getFriendsAction,
  getPendingRequestsAction
} from "../../../actions/FriendActions";
import {reset, setFriendLoading} from "../../../slices/FriendsSlice";
import FriendRequest from "./FriendRequest";
import {Collapse, Stack} from "@mui/material";
import LoadingComponent from '../../utils/LoadingComponent';
import FriendsEmpty from '../../../pages/Empty/FriendsEmpty';
import AddFriend from "./AddFriend";
import FriendSwitch from "./FriendSwitch";
import {PriorityHeading} from './Styles';


function FriendList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    friends,
    friendRequests,
    pendingRequests,
    friendsSearch,
    loading,
    addFriend
  } = useSelector(
      state => state.friends);
  const userId = useSelector(state => state.user.id);
  const items = useFilteredResults(friends, friendsSearch);

  React.useEffect(() => {
    dispatch(reset())
    if (userId) {
      dispatch(setFriendLoading(true));
      const friends = dispatch(getFriendsAction(userId));
      const requests = dispatch(getFriendRequestsAction(userId));
      const pending = dispatch(getPendingRequestsAction(userId));

      Promise.all([friends, requests, pending]).finally(() => {
        dispatch(setFriendLoading(false));
      })
    }
  }, [location, dispatch, userId]);

  return (
      <LoadingComponent loading={loading}>
        <Collapse in={addFriend.add} mountOnEnter unmountOnExit>
          <AddFriend item={addFriend}/>
        </Collapse>
        {(friends.length > 0 || friendRequests.length > 0
            || pendingRequests.length > 0) ? (
            <Stack>
              {pendingRequests.length > 0 && (
                  <Stack spacing={1}>
                      <PriorityHeading variant="h6">
                        Invites Received
                      </PriorityHeading>
                    <Stack spacing={1}>
                      {pendingRequests.map(item => (
                          <FriendRequest key={item.id} item={item}/>
                      ))}
                    </Stack>
                  </Stack>
              )}
              {friendRequests.length > 0 && (
                  <Stack spacing={1}>
                    <PriorityHeading variant="h6">
                      Invites Sent
                    </PriorityHeading>
                    <Stack spacing={1}>
                      {friendRequests.map(item => (
                          <FriendRequest key={item.id} item={item}/>
                      ))}
                    </Stack>
                  </Stack>
              )}
              <Stack spacing={1}>
                {(friends.length > 0 && (friendRequests.length > 0
                    || pendingRequests.length > 0)) && (
                    <PriorityHeading variant="h6">
                      Friends
                    </PriorityHeading>
                )}
                <Stack spacing={1}>
                  {items.map(item => (
                      <FriendSwitch key={item.id} item={item}/>
                  ))}
                </Stack>
              </Stack>
            </Stack>
        ) : <FriendsEmpty/>}
      </LoadingComponent>
  )
}

export default FriendList;
