import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteFriendRequestAction,
  getFriendsAction,
  getPendingRequestsAction,
  verifyFriendAction
} from "../../../actions/FriendActions";
import {useSnackbar} from "notistack";
import FriendRequestButton from "./FriendRequestButton";
import PaperListItem from "../PaperList/PaperListItem";

function FriendRequest({item}) {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const userId = useSelector(state => state.user.id);

  const handleDelete = (e) => {
    dispatch(deleteFriendRequestAction(userId, item.id))
    .then(() => {
      enqueueSnackbar("Rescinded friend request", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  const handleVerify = (e) => {

    dispatch(verifyFriendAction(userId, item.verifyToken)).then(() => {
      dispatch(getPendingRequestsAction(userId))
      dispatch(getFriendsAction(userId))
      enqueueSnackbar("Accepted friend request", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  return (
      <PaperListItem hover>
        <Grid container alignItems="center" justifyContent="space-between"
              spacing={2}>
          <Grid item>
            <Avatar variant="rounded">
              {(item.lastName ? item.lastName.charAt(0) : item.email.charAt(
                  0)).toUpperCase()}
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography color="textSecondary">
              {item.lastName ? `${item.firstName} ${item.lastName}`
                  : item.email}
            </Typography>
          </Grid>
          <Grid item>
            <FriendRequestButton item={item} onDelete={handleDelete} onVerify={handleVerify} />
          </Grid>
        </Grid>
      </PaperListItem>
  );
}

export default FriendRequest;