import React from "react";
import {Collapse} from "@mui/material";
import Friend from "./Friend";
import EditFriend from "./EditFriend";

const FriendSwitch = ({item}) => {
  const [edit, setEdit] = React.useState(false);

  const onEdit = () => {
    setEdit(!edit);
  }

  return (
      <div>
        <Collapse in={edit} mountOnEnter unmountOnExit>
          <EditFriend {...{item, onEdit}} />
        </Collapse>
        <Collapse in={!edit} mountOnEnter unmountOnExit>
          <Friend {...{item, onEdit}} />
        </Collapse>
      </div>
  );
}

export default FriendSwitch;