import React from "react";
import {useDispatch} from "react-redux";
import {setAddWishlist, setWishlistSearch,} from "../slices/WishlistsSlice";
import AddIcon from '@mui/icons-material/Add';
import WishlistList from "../components/items/Wishlist/WishlistList";
import {WISHLIST_STATE} from "../constants/StateConstants";
import PaperListPage from "../components/items/PaperList/PaperListPage";
import {StyledFab} from './styles';

function WishlistsPage(props) {
  const dispatch = useDispatch();

  const handleAddButton = (e) => {
    dispatch(setAddWishlist({add: true, ...WISHLIST_STATE.DEFAULT}));
  };

  return (
      <PaperListPage placeholder="Search by Wishlist Name"
                     onSearchChange={setWishlistSearch}>
        <WishlistList shared={props.sharedPage}/>
        {!props.sharedPage && (
            <StyledFab color="primary" aria-label="add" onClick={handleAddButton}>
              <AddIcon/>
            </StyledFab>
        )}
      </PaperListPage>
  );
}

export default WishlistsPage;
