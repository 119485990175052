import FetchWrapper from "./FetchWrapper";

const getContributionsByWishlistItemId = (wishlistId, id) => {
  return FetchWrapper(`/wishlists/${wishlistId}/items/${id}/contributions`);
};

const contribute = (payload) => {
  return FetchWrapper(`/contributions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const updateContribution = (id, payload) => {
  return FetchWrapper(`/contributions/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const deleteContribution = (id) => {
  return FetchWrapper(`/contributions/${id}`, {
    method: 'DELETE'
  });
};

export { contribute, updateContribution, deleteContribution, getContributionsByWishlistItemId }