import {ListItem, ListItemText} from "@mui/material";
import styled from "@emotion/styled";
import {drawerWidth} from './MaterialNavigator';
import Drawer from '@mui/material/Drawer';

export const StyledDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    backgroundColor: '#18202c'
  }
})

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: 1,
  paddingBottom: 1,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover,&:focus': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  }
}));

export const StyledNavTitleListItem = styled(ListItem)(({ theme }) => ({
  fontSize: 24,
  color: theme.palette.common.white,
  backgroundColor: '#232f3e',
  boxShadow: '0 -1px 0 #404854 inset',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const StyledNavListItem = styled(StyledListItem)(({ theme }) => ({
  backgroundColor: '#232f3e',
  boxShadow: '0 -1px 0 #404854 inset',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '& .MuiListItemText-primary': {
    fontSize: 'inherit',
  }
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    color: theme.palette.common.white,
    fontSize: 16
  }
}));

export const StyledNav = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    flexShrink: 0,
  }
}));
