import { createSlice } from "@reduxjs/toolkit";

const slice =  createSlice({
  name: "home",
  initialState: { status: "" },
  reducers: {
    doSomething: (state, action) => { state.status = action.payload },
    doSomethingElse: (state) => { state.status = "did something else" }
  }
});

export const { doSomething, doSomethingElse } = slice.actions;

export default slice.reducer;