import ListItemText from "@mui/material/ListItemText/ListItemText";
import ListItemSecondaryAction
  from "@mui/material/ListItemSecondaryAction/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItem from "@mui/material/ListItem";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteContributionAction,
  getContributionsByWishlistItemIdAction
} from "../actions/ContributionActions";
import DeleteAction from "./buttons/DeleteAction";
import currency from 'currency.js';
import {useSnackbar} from "notistack";

function Contribution({contribution}) {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const userId = useSelector(state => state.user.id);
  const item = useSelector(state => state.wishlistItemDetail.item);

  const deleteContribution = () => {
    dispatch(deleteContributionAction(contribution.id)).then(json => {
      dispatch(
          getContributionsByWishlistItemIdAction(item.wishlistId, item.id));
      enqueueSnackbar("Removed contribution", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  return (
      <ListItem sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        borderRadius: '10px',
        marginBottom: '5px',
      }}>
        <ListItemText sx={{ width: 'auto' }}>
          {contribution.user.firstName + " " + contribution.user.lastName}
        </ListItemText>
        {contribution.user.id === userId && (
            <ListItemSecondaryAction>
              <DeleteAction
                  handleDelete={deleteContribution}
                  item={contribution}
                  name={currency(contribution.amount).format()}>
                <IconButton edge="end" aria-label="delete" size="small">
                  <DeleteIcon/>
                </IconButton>
              </DeleteAction>
            </ListItemSecondaryAction>
        )}
      </ListItem>
  )
}

export default Contribution;
