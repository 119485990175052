import React, {useState} from "react";
import {Button, FormLabel} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import EmailGroup from "../components/items/EmailGroup";
import {useDispatch, useSelector} from "react-redux";
import {getSubscriptionsAction, subscribeAction} from "../actions/EmailActions";


const EmailNotificationForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState({})
  const userId = useSelector(state => state.user.id);

  React.useEffect(() => {
    if (userId) {
      dispatch(getSubscriptionsAction(userId)).then(json => {
        setLoading(false);
        delete json.userId;
        setNotifications(json);
      })
    }
  }, [userId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(subscribeAction(userId, notifications)).then(json => {

    });
  }

  return (
      <div style={{ margin: '10px 20px' }}>
        {!loading &&
            <form onSubmit={handleSubmit}>
              <FormLabel component={'h3'}>Email Notifications</FormLabel>
              {Object.keys(notifications).map(key =>
                  <FormGroup>
                    <EmailGroup
                        key={key}
                        name={key}
                        notifications={notifications}
                        setNotifications={setNotifications}
                        item={notifications[key]} />
                  </FormGroup>)}
              <Button type="submit" variant="contained" color="primary">
                submit
              </Button>
            </form>
        }
      </div>
  );
}

export default EmailNotificationForm;
