import React from "react";
import {updateWishlistAction} from "../../../actions/WishlistActions";
import WishlistForm from "./WishlistForm";
import {useDispatch} from "react-redux";
import {Paper, useTheme} from "@mui/material";
import {useSnackbar} from "notistack";

const EditWishlist = ({item, onEdit}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    onEdit();
  };

  const handleSubmit = (event, newItem) => {
    event.preventDefault();

    dispatch(updateWishlistAction(newItem)).then(json => {
      handleClose();
      enqueueSnackbar("Updated wishlist", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  return (
      <Paper sx={{ padding: theme.spacing(2, 4, 3) }}>
        <WishlistForm item={item.wishlist} {...{handleSubmit, handleClose}} />
      </Paper>
  );
}

export default EditWishlist;