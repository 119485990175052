import React from 'react';
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import ContributeModal from "../modals/ContributeModal";
import {setContributeModal} from "../../slices/ModalSlice";
import {getContributionsByWishlistItemIdAction} from "../../actions/ContributionActions";
import Modal from '@mui/material/Modal';

function ContributeButton({wishlistId, itemId}) {
  const dispatch = useDispatch();
  const claims = useSelector(state => state.wishlistItemDetail.claims);
  const contributeModal = useSelector(state => state.modals.contributeModal);
  const item = useSelector(state => state.wishlistItemDetail.item);
  const contributions = useSelector(state => state.wishlistItemDetail.contributions);

  React.useEffect(() => {
    dispatch(getContributionsByWishlistItemIdAction(wishlistId, itemId));
  }, [wishlistId, itemId]);

  const clickHandler = e => {
    dispatch(setContributeModal({...contributeModal, open: true}))
  };

  const handleClose = () => {
    dispatch(setContributeModal({...contributeModal, open: false,}));
  };

  return (
      <React.Fragment>
        <Button disabled={claims.length > 0} variant="contained"
                color="primary"
                onClick={clickHandler} fullWidth>Contribute</Button>
        <Modal
            open={contributeModal.open}
            onClose={handleClose}
            closeAfterTransition
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <ContributeModal {...{wishlistId, itemId, contributeModal, item, contributions}} />
        </Modal>
      </React.Fragment>

  );
}

export default ContributeButton