import React from "react";
import {Collapse} from "@mui/material";
import WishlistItemAdd from "./WishlistItemAdd";
import AmazonImport from "../Search/AmazonImport";
import {useDispatch, useSelector} from "react-redux";
import {setAddWishlistItem} from "../../../slices/WishlistItemsSlice";
import {SHARED_ITEM_STATE} from "../../../constants/StateConstants";
import {getFirstWords} from "../../../util/StringUtils";
import {addAssociateId} from '../../../util/AffiliateUtils';
import useShowAddItems from '../../hooks/useShowAddItems';

const WishlistItemActionSection = () => {
  const dispatch = useDispatch();
  const addItem = useSelector(state => state.wishlistItems.addItem);
  const { showAmazon, showAdd } = useShowAddItems(addItem);

  const onBack = () => {
    dispatch(setAddWishlistItem({}));
  }

  const onSelect = (e, result) => {
    dispatch(setAddWishlistItem({
      add: true,
      ...SHARED_ITEM_STATE.DEFAULT,
      title: getFirstWords(result.title),
      price: result.price ? result.price.value
          : SHARED_ITEM_STATE.DEFAULT.price,
      link: addAssociateId(result.link),
      imageUrl: result.image,
    }))
  }

  return (
      <React.Fragment>
        <Collapse in={showAmazon} mountOnEnter
                  unmountOnExit
                  sx={{ marginTop: "10px" }}>
          <AmazonImport item={addItem} onBack={onBack} onSelect={onSelect}/>
        </Collapse>
        <Collapse in={showAdd} mountOnEnter
                  unmountOnExit>
          <WishlistItemAdd item={addItem}/>
        </Collapse>
      </React.Fragment>
  );
}

export default WishlistItemActionSection;