import React from 'react';
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmDeleteDialog = ({open, setOpen, onConfirm, name}) => {
  const handleClose = (e) => {
    e.stopPropagation();

    setOpen(false);
  }

  return (
      <Dialog
          open={open}
          onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove "{name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={(e) => onConfirm(e)} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default ConfirmDeleteDialog;