import styled from "@emotion/styled";
import {Avatar, CardMedia, Collapse} from "@mui/material";
import Grid from '@mui/material/Grid';

const CardImageStyled = styled(CardMedia)({
  height: '100%',
  minHeight: 150,
  position: 'relative',
  borderTop: '1px solid #bdbdbd',
  borderBottom: '1px solid #bdbdbd',
  backgroundSize: 'contain'
});

const BigAvatarStyled = styled(Avatar)({
  margin: '0',
  width: '130px',
  height: '130px',
  top: '50%',
  left: '50%',
  marginRight: '-50%',
  fontSize: '50px',
  transform: 'translate(-50%, -50%)',
})

const UserItemCardContainer = styled('div')({
  height: '100%',
})

const UserItemCardStyled = styled(Grid)(({ theme }) => ({
  width: 301,
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  }
}));

const UserItemCollapse = styled(Collapse)(({
  width: '100%'
}))

const StyledUserItemListGridItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    width: '50%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
}));

export { CardImageStyled, BigAvatarStyled, UserItemCardStyled, UserItemCollapse, StyledUserItemListGridItem, UserItemCardContainer }
