import React from "react";
import {Typography} from "@mui/material";
import ShareSwitch from "../components/share/ShareSwitch";
import {setTitle} from '../slices/NavigatorSlice';
import {getWishlistByIdAction} from '../actions/WishlistActions';
import {useDispatch} from 'react-redux';
import PaperListPage from '../components/items/PaperList/PaperListPage';

const SharedWithPage = ({match}) => {
  const dispatch = useDispatch();
  const wishlistId = match.params.wishlistId;

  React.useEffect(() => {
    dispatch(setTitle('Share'));
    dispatch(getWishlistByIdAction(wishlistId)).then(json => {
      dispatch(setTitle(`Share (${json.name})`));
    });
  }, [])

  return (
      <PaperListPage maxWidth="450px">
        <Typography variant="h5" align="center">
          Share Wishlist
        </Typography>
        <ShareSwitch wishlistId={wishlistId}/>
      </PaperListPage>
  )
}

export default SharedWithPage;
