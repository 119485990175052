import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {loginAction} from "../actions/LoginActions";
import {Link, useHistory} from "react-router-dom";
import {setTitle} from "../slices/NavigatorSlice";
import {StyledPageButtonGroup, StyledPagePaper, StyledSubmitButton, StyledTextField} from './styles';
import {Stack} from '@mui/material';
import {useSnackbar} from 'notistack';

function LoginPage(props) {
  const {enqueueSnackbar} = useSnackbar();
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const { from } = props.location.state || { from: { pathname: '/' } };
  const [ error, setError ] = useState("");

  dispatch(setTitle("Login"));

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(loginAction({email, password})).then(json => {
      history.push(from);
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'});
    });
  };

  const handleEmailChange = (e) => {
    if (error) {
      setError("");
    }
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    if (error) {
      setError("");
    }
    setPassword(e.target.value);
  };

  return (
      <StyledPagePaper>
        <form onSubmit={handleSubmit}>
          <Typography variant="h5">
            Login
          </Typography>
          <StyledTextField id="standard-basic" label="Email" type="email" fullWidth value={email} onChange={handleEmailChange} required variant="standard" />
          <StyledTextField id="standard-basic" label="Password" type="password" fullWidth value={password} onChange={handlePasswordChange} required variant="standard" />
          <StyledPageButtonGroup>
            <Stack>
              <div>
                <StyledSubmitButton type="submit" variant="contained" color="primary">
                  Login
                </StyledSubmitButton>
                <span style={{ margin: "auto 5px auto 5px" }}>Or</span>
                <Typography component={Link} to="/register">
                  Register
                </Typography>
              </div>
              <Typography marginTop={2} component={Link} to="/forgot-password">
                Forgot password?
              </Typography>
            </Stack>
          </StyledPageButtonGroup>
        </form>
      </StyledPagePaper>
  );
}

export default LoginPage;
