import React from 'react';
import {Chip, Grid, Link, Typography} from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis';
import { Rating } from '@mui/material';
import currency from 'currency.js';
import {CheckIconStyled, GridItemRating, ResultImage} from './styles';


const MobileResult = ({ result, onClickTitle }) => {

  return (
      <Grid container direction="column" spacing={1} padding="10px 10px" color="inherit">
        <Grid item container spacing={1} sx={{
          '&:hover': {
            textDecoration: 'none'
          }
        }}>
          <Grid item paddingLeft="0 !important">
            <ResultImage src={result.image} alt="Amazon product image"/>
          </Grid>
          <Grid container item direction="column" xs>
            <Grid item component={Link} href={result.link} target="_blank" onClick={onClickTitle}>
              <Typography variant="h6">
                <LinesEllipsis text={result.title} maxLine={2}/>
              </Typography>
            </Grid>
            {result.price && (
                <Grid item>
                  <Typography>
                    {currency(result.price.value).format()}
                  </Typography>
                </Grid>
            )}
          </Grid>
        </Grid>
        {(result.prime || result.rating > 0) && (
            <Grid item container spacing={1}>
              {result.prime && (
                  <GridItemRating item >
                    <Chip label={
                      <Typography>
                        Prime
                      </Typography>}
                          size="small"
                          color="primary"
                          variant="outlined"
                          icon={<CheckIconStyled />} />
                  </GridItemRating>
              )}
              {result.rating > 0 && (
                  <React.Fragment>
                    <Grid item>
                      <Rating value={result.rating} readOnly/>
                    </Grid>
                    <Grid item>
                      <Typography>
                        ({result.numRatings})
                      </Typography>
                    </Grid>
                  </React.Fragment>
              )}
            </Grid>
        )}
      </Grid>
  );
}

export default MobileResult;
