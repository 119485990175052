import React from 'react';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDial from '@mui/material/SpeedDial';
import AddIcon from '@mui/icons-material/Add';
import {useDispatch} from "react-redux";
import {SHARED_ITEM_STATE} from "../../constants/StateConstants";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {setAddUserItem} from "../../slices/UserItemsSlice";
import {useTheme} from '@mui/material';


const UserItemSpeedDial = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const onAddItem = () => {
    handleClose();
    dispatch(setAddUserItem({add: true, ...SHARED_ITEM_STATE.DEFAULT}));
  }

  const onImportItem = () => {
    handleClose();
    dispatch(setAddUserItem(
        {add: true, amazon: true, ...SHARED_ITEM_STATE.DEFAULT}));
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
      <React.Fragment>
        <SpeedDial
            sx={{
              position: 'fixed',
              bottom: theme.spacing(4),
              right: theme.spacing(4),
            }}
            onClose={handleClose}
            onOpen={handleOpen}
            icon={<SpeedDialIcon/>}
            open={open}
            ariaLabel="Wishlist Actions">
          <SpeedDialAction
              icon={<AddIcon/>}
              tooltipTitle="Add Item"
              tooltipOpen
              onClick={onAddItem}
              sx={{
                '& .MuiSpeedDialAction-staticTooltipLabel': {
                  width: 101
                }
              }}
          />
          <SpeedDialAction
              icon={<ImportExportIcon/>}
              tooltipTitle="Import Item"
              tooltipOpen
              onClick={onImportItem}
              sx={{
                '& .MuiSpeedDialAction-staticTooltipLabel': {
                  width: 118
                }
              }}
          />
        </SpeedDial>
      </React.Fragment>
  );
}

export default UserItemSpeedDial;