import React from 'react';
import {Grid, Typography} from '@mui/material';
import EmptySection from './EmptySection';

const MyLibraryEmpty = () => {

  return (
      <EmptySection>
        <Typography color="textSecondary">
          A library item is an item you plan to add to a wishlist in the future.
          When adding an item to your wishlist you may choose an from your library.
        </Typography>
        <br />

        <Typography component="div" color="textSecondary">
          What are you waiting for? Add an some items to your library:
          <ul>
            <li>Add: Add an item using a form</li>
            <li>Import: Search Amazon for an item</li>
          </ul>
        </Typography>
        <br/>
      </EmptySection>
  )
}

export default MyLibraryEmpty;