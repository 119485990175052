import React from 'react';
import {ClickAwayListener, Grid, IconButton, Tooltip} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const InputHelpTooltip = ({children, Node, align}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose =() => {
    setOpen(false);
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
      <Grid container alignItems={align ? align : 'center'}>
        <Grid item>
          {children}
        </Grid>
        <Grid>
          <ClickAwayListener onClickAway={handleClose}>
            <Tooltip title={Node}
                     open={open}
                     disableFocusListener
                     disableHoverListener
                     disableTouchListener
                     arrow={true}
                     onClose={handleClose}>
              <IconButton size="small" onMouseEnter={handleOpen} onMouseLeave={handleClose} onClick={() => setOpen(!open)}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </ClickAwayListener>
        </Grid>
      </Grid>
  )
}

export default InputHelpTooltip;