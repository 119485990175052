import React from "react";
import { Route, Redirect } from "react-router-dom";
import {useSelector} from "react-redux";
import {parse} from 'query-string';
import {useCookies} from 'react-cookie';
import {CookieConstants} from '../constants/CookieConstants';

export default function PrivateRoute({ component: Component, refreshing, allowShared, location, ...rest }) {
  const loggedIn = useSelector(state => state.user.loggedIn);
  const [cookies] = useCookies([CookieConstants.shareId]);
  const params = parse(location.search);
  let allow = false;

  if (allowShared && (params.shareId || cookies.shareId)) {
    allow = true
  }

  return (
      <Route
          {...rest}
          render={props => {
            return loggedIn || refreshing || allow ? (
                <Component {...props} {...rest} />
            ) : (
                <Redirect
                    to={{pathname: "/login", state: {from: props.location}}}
                />
            )}
          }
      />
  );
};