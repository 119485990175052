import React from 'react';
import {Grid, Typography} from '@mui/material';
import EmptySection from './EmptySection';

const WishlistItemsEmpty = () => {

  return (
      <EmptySection>
        <Typography color="textSecondary">
          You have not added any items to your wishlist.
        </Typography>
        <br/>
        <Typography component="div" color="textSecondary">
          To add items use the blue button on the bottom right of your screen:
          <ul>
            <li>
              Add: Add items via a form
            </li>
            <li>
              Library: Add items from your library
            </li>
            <li>
              Import: Search and add an item from Amazon
            </li>
            <li>
              Share: Share your wishlist with friends
            </li>
          </ul>
        </Typography>
      </EmptySection>
  )
}

export default WishlistItemsEmpty;