import Cookies from 'js-cookie';
import {CookieConstants} from "../constants/CookieConstants";

const removeCookies = () => {
  if (Cookies.get(CookieConstants.token)) {
    Cookies.remove(CookieConstants.token);
  }
  if (Cookies.get(CookieConstants.refreshToken)) {
    Cookies.remove(CookieConstants.refreshToken);
  }
  if (Cookies.get(CookieConstants.shareId)) {
    Cookies.remove(CookieConstants.shareId);
  }
}

const logoutAction = () => () => {
  removeCookies();

  window.location.href = '/';
};

const expireSession = () => () => {
  removeCookies();

  window.location.href = '/login';
};

export {logoutAction, expireSession};