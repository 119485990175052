import React from "react";
import {useDispatch} from "react-redux";
import {addUserItemAction} from "../../../actions/UserItemsActions";
import WishlistItemForm from "../WishlistItem/WishlistItemForm";
import {setAddUserItem} from "../../../slices/UserItemsSlice";
import {Paper, useTheme} from "@mui/material";
import {useSnackbar} from "notistack";

function AddUserItem({item}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();

  const resetAdd = () => {
    dispatch(setAddUserItem({}));
  }

  const handleSubmit = (event, newItem) => {
    event.preventDefault();
    dispatch(addUserItemAction(newItem)).then(() => {
      resetAdd();
      enqueueSnackbar("Added user item", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
      //setError(error);
    });
  };

  const handleCancel = () => {
    resetAdd();
  }

  return (
      <Paper sx={{
        padding: theme.spacing(2, 4, 3),
        margin: '0 2px 10px 2px'
      }}>
        <WishlistItemForm showPriority={false} showAddToItems={false} {...{
          item,
          handleSubmit,
          handleCancel
        }} />
      </Paper>
  )
}

export default AddUserItem;