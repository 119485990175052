import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {setTitle} from "../slices/NavigatorSlice";
import {forgotPasswordAction} from "../actions/UserActions";
import {StyledPageButtonGroup, StyledPagePaper, StyledSubmitButton} from './styles';

function ForgotPage() {
  const [ successMessage, setSuccessMessage ] = useState("");
  const [ email, setEmail ] = useState("");
  const dispatch = useDispatch();
  const [ error, setError ] = useState("");

  dispatch(setTitle("Forgot Password"));

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(forgotPasswordAction({email})).then(json => {
      setSuccessMessage("If there is an account you will receive an email with steps to reset your password.")
    }).catch(error => {
      setError(error);
    });
  };

  const handleEmailChange = (e) => {
    if (error) {
      setError("");
    }
    setEmail(e.target.value);
  };

  return (
      <StyledPagePaper>
        <form onSubmit={handleSubmit}>
          <Typography variant="h5">
            Forgot Password
          </Typography>
          { successMessage ? <Typography color="textPrimary" variant="subtitle1" >{successMessage}</Typography> : null }
          { error ? <Typography color="error" variant="subtitle1" sx={{ margin: '5px 0 5px 0' }}>{error}</Typography> : null }
          <TextField id="standard-basic" label="Email" type="email" fullWidth value={email} onChange={handleEmailChange} required sx={{ margin: '5px 0 5px 0' }} variant="standard" />
          <StyledPageButtonGroup>
            <StyledSubmitButton type="submit" sx={{ marginLeft: 0 }} variant="contained" color="primary">
              Submit
            </StyledSubmitButton>
          </StyledPageButtonGroup>
        </form>
      </StyledPagePaper>
  );
}

export default ForgotPage;
