import {SERVER} from "../constants/ServiceConstants";

const getBaseUrl = () => {
  if (process.env.NODE_ENV !== 'production') {
    return `//${SERVER.host}:8080/api`
  }

  return `/api`
}

export {
  getBaseUrl
}