import Typography from "@mui/material/Typography";
import React from "react";
import styled from '@emotion/styled';

const StyledTypography = styled(Typography)({
  color: 'white',
  position: 'absolute',
  width: '100%',
  padding: 2,
  top: 0,
  left: 0,
});

const Ribbon = ({ color, text, opacity }) => {

  return (
      <StyledTypography align="center"
                  variant='caption'
                  sx={{
                    backgroundColor: color,
                    opacity: opacity ? opacity : 1
                  }}>
        {text}
      </StyledTypography>
  )
}

export default Ribbon;
