import FetchWrapper from "./FetchWrapper";
import {SERVER} from "../constants/ServiceConstants";


const getAllNotifications = () => {
  return FetchWrapper(`/notifications`);
};

const markAllAsRead = () => {
  return FetchWrapper(`/notifications/mark-as-read`, {
    method: 'POST'
  });
};

const deleteAllNotifications = () => {
  return FetchWrapper(`/notifications/delete`, {
    method: 'DELETE',
  });
};

const deleteNotification = (id) => {
  return FetchWrapper(`/notifications/${id}`, {
    method: 'DELETE',
  });
};

export { getAllNotifications, markAllAsRead, deleteAllNotifications, deleteNotification }