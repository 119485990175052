import React from 'react';
import Hidden from '@mui/material/Hidden';
import HeaderAppBarMobile from "./HeaderAppBarMobile";
import HeaderAppBar from "./HeaderAppBar";

function Header() {
  return (
    <React.Fragment>
      <Hidden mdUp>
        <HeaderAppBarMobile/>
      </Hidden>
      <Hidden mdDown>
        <HeaderAppBar />
      </Hidden>
      {/*<HeaderAppBarTabs />*/}
    </React.Fragment>
  );
}

export default Header;