import React from 'react';
import _ from 'underscore';

const useShowAddItems = (item) => {
    const [showAmazon, setShowAmazon] = React.useState(false);
    const [showAdd, setShowAdd] = React.useState(false);

    React.useEffect(() => {
        setShowAdd(!_.isEmpty(item) && !item.amazon);
        setShowAmazon(!_.isEmpty(item) && item.amazon);
    }, item.amazon)

    return {
        showAdd,
        showAmazon
    }
}

export default useShowAddItems;