import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "Wishlists",
  initialState: {
    friendWishlists: [],
    publicWishlists: [],
    friendListSearch: "",
    publicListSearch: "",
    loading: false,
  },
  reducers: {
    setBrowseFriendWishlists: (state, action) => {
      state.friendWishlists = action.payload;
    },
    setFriendListSearch: (state, action) => {
      state.friendListSearch = action.payload;
    },
    setBrowsePublicWishlists: (state, action) => {
      state.friendWishlists = action.payload;
    },
    setPublicListSearch: (state, action) => {
      state.friendListSearch = action.payload;
    },
    setBrowseLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export const {
  setBrowseFriendWishlists,
  setFriendListSearch,
  setBrowsePublicWishlists,
  setPublicListSearch,
  setBrowseLoading,
} = slice.actions;

export default slice.reducer