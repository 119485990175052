import React from 'react';
import './App.css';
import Header from "./components/header/Header";
import AppRoutes from "./router/AppRoutes";
import MaterialNavigator from "./components/navigator/MaterialNavigator";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import {useCookies} from "react-cookie";
import {loginUser} from "./slices/UserSlice";
import {useDispatch} from "react-redux";
import {getUserDetailsAction} from "./actions/UserActions";
import {SnackbarProvider} from 'notistack';
import Footer from './components/Footer';
import {refreshTokenAction} from './actions/LoginActions';
import {parse} from "query-string";
import {useLocation} from "react-router-dom";
import {CookieConstants} from "./constants/CookieConstants";
import DefaultErrorBoundary from "./components/error/DefaultErrorBoundary";
import {Box, Stack, useTheme} from '@mui/material';

const App = () => {
  const [cookies, setCookie] = useCookies(
      [CookieConstants.token, CookieConstants.shareId]);
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const [refreshing, setRefreshing] = React.useState(false);
  const params = parse(location.search);

  if (cookies.shareId || params.shareId) {
    if (!cookies.shareId || params.shareId) {
      setCookie(CookieConstants.shareId, params.shareId, {path: '/'});
    }
    dispatch(getUserDetailsAction());
  } else if (!refreshing) {
    if (cookies.token) {
      dispatch(loginUser(cookies.token));
      dispatch(getUserDetailsAction());
      setRefreshing(true);
    } else if (cookies.refreshToken) {
      setRefreshing(true);
      dispatch(refreshTokenAction(cookies.refreshToken));
    }
  }

  return (
      <Box display="flex" minHeight="100vh">
        <DefaultErrorBoundary>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline/>
            <MaterialNavigator />
            <Stack flex="1">
              <Header/>
              <main style={{
                flex: 1,
                padding: theme.spacing(2, 1),
                background: '#eaeff1',  }}>
                <Box margin="auto" maxWidth="936px">
                  <AppRoutes refreshing={refreshing}/>
                </Box>
              </main>
              <Footer/>
            </Stack>
          </SnackbarProvider>
        </DefaultErrorBoundary>
      </Box>
  );
}
export default App;
