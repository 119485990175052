import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {updateWishlistItemAction} from "../../../actions/WishlistItemActions";
import {Paper, useTheme} from "@mui/material";
import WishlistItemForm from "./WishlistItemForm";
import {useSnackbar} from "notistack";

const WishlistItemEdit = ({item, toggleEdit}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const linkUser = useSelector(state => state.wishlistItems.linkUser);

  const handleSubmit = (event, newItem) => {
    event.preventDefault();

    dispatch(updateWishlistItemAction(linkUser.wishlist.id, newItem)).then(
        () => {
          toggleEdit();
          enqueueSnackbar("Updated wishlist item", {variant: 'success'})
        }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
      //setError(error);
    });
  };

  const handleCancel = () => {
    toggleEdit();
  }

  return (
      <Paper sx={{
        padding: theme.spacing(2, 4, 3),
        marginTop: 1,
      }}>
        <WishlistItemForm showPriority={true} showAddToItems={false} {...{
          item,
          handleSubmit,
          handleCancel
        }} />
      </Paper>
  )
}

export default WishlistItemEdit;