import styled from '@emotion/styled';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField/TextField';

const ItemImage = styled('img')(({theme}) => ({
  objectFit: 'cover',
  [theme.breakpoints.up('sm')]: {
    width: 75,
  },
  [theme.breakpoints.down('sm')]: {
    width: 60,
  },
  borderRadius: 5,
  marginTop: 6,
}));

const CollapseStyled = styled(Collapse)({
  width: '100%'
});

const FormTextField = styled(TextField)({
  margin: '5px 0 5px 0',
})

const WishlistItemFormStyled = styled('form')({
  margin: 'auto',
  marginTop: 10
});

const Priority = styled('div')({
  padding: '0 2px'
});

export { ItemImage, CollapseStyled, FormTextField, WishlistItemFormStyled, Priority  }
