import {Link} from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import {useSelector} from 'react-redux';
import {StyledListItem} from "./styles";

const LinkItem = ({id, to, icon, nested, handleClick}) => {
    const {selected} = useSelector(state => state.navigator);

    return (
        <StyledListItem
            sx={{
                ...(id === selected ? {'color': '#4fc3f7 !important'} : {}),
                ...(nested ? {'paddingLeft': "40px"}  : {}),
            }}
            key={id}
            button
            component={Link}
            to={to}
            onClick={() => handleClick(id)}
        >
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText>{id}</ListItemText>
        </StyledListItem>
    );
}

export default LinkItem;