import FetchWrapper from "./FetchWrapper";

const getFriends = (userId) => {
  return FetchWrapper(`/users/${userId}/friends`);
};

const getFriendRequests = (userId) => {
  return FetchWrapper(`/users/${userId}/friends/requests`);
};

const getPendingRequests = (userId) => {
  return FetchWrapper(`/users/${userId}/friends/pending`);
};

const getFriendsNotAlreadyShared = (userId, wishlistId) => {
  return FetchWrapper(`/users/${userId}/friends/wishlists/${wishlistId}`);
};

const verifyFriend = (userId, token) => {
  return FetchWrapper(`/users/${userId}/friends/verify?token=${token}`)
}

const addFriend = (userId, payload) => {
  return FetchWrapper(`/users/${userId}/friends`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const updateFriend = (userId, friendId, payload) => {
  return FetchWrapper(`/users/${userId}/friends/${friendId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload),
  })
}

const deleteFriend = (userId, id) => {
  return FetchWrapper(`/users/${userId}/friends/${id}`, {
    method: 'DELETE'
  });
};

const deleteFriendRequest = (userId, id) => {
  return FetchWrapper(`/users/${userId}/friends/${id}/request`, {
    method: 'DELETE'
  });
};

export {
  getFriends,
  getFriendRequests,
  getPendingRequests,
  getFriendsNotAlreadyShared,
  verifyFriend,
  addFriend,
  updateFriend,
  deleteFriend,
  deleteFriendRequest
}