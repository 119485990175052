import TextField from "@mui/material/TextField/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import React from "react";


const SharedWithAutocomplete = ({ items, selectedItems, onHandleChange }) => {

    const filterOptions = (opts, state) => {
        return opts.filter(it => (
            selectedItems.filter(i => i.friend.id === it.friend.id).length === 0)
        ).filter(it => (
            state.inputValue === '' ||
            it.friend.firstName.includes(state.inputValue) ||
            it.friend.lastName.includes(state.inputValue) ||
            it.friend.email.includes(state.inputValue)
        ));
    };

    return (
        <Autocomplete
            id="checkboxes-tags-demo"
            onChange={onHandleChange}
            sx={{
                margin: '15px 0 5px 0'
            }}
            options={items}
            fullWidth={true}
            autoHighlight
            autoComplete={true}
            getOptionLabel={(option) => option.friend.firstName}
            filterOptions={filterOptions}
            renderOption={(props, option, { selected }) => (
                    <div {...props}>
                        {option.friend.firstName} {option.friend.lastName}
                    </div>
            )}
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Friends" placeholder="Name" />
            )}
        />
    )
}

export default SharedWithAutocomplete;
