import React from "react";
import {useDispatch} from "react-redux";
import {updateUserItemAction} from "../../../actions/UserItemsActions";
import WishlistItemForm from "../WishlistItem/WishlistItemForm";
import {useSnackbar} from "notistack";
import {useTheme} from "@mui/material";

function EditUserItem({item, setEdit}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const handleSubmit = (event, newItem) => {
    event.preventDefault();
    dispatch(updateUserItemAction(newItem)).then(() => {
      setEdit(false);
      enqueueSnackbar("Updated user item", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  const handleCancel = () => {
    setEdit(false);
  }

  return (
      <div style={{
        margin: theme.spacing(2, 4, 3)
      }}>
        <WishlistItemForm showPriority={false} showAddToItems={false} {...{
          item,
          handleSubmit,
          handleCancel
        }} />
      </div>
  )
}

export default EditUserItem;
