export const getUserClaim = (claims, userId) => {
  return claims.reduce(((previousValue, currentValue) =>
      (currentValue.user && currentValue.user.id === userId) ? currentValue
          : previousValue), null);
}

export const getClaimedQuantity = (claims) => {
  return claims
      .map(claim => claim.quantity)
      .reduce((p, c) => c + p, 0);
}

export const getClaimedQuantityNotByUser = (claims, userId) => {
  return claims
      .filter(claim => claim.user.id !== userId)
      .map(claim => claim.quantity)
      .reduce((p, c) => c + p, 0);
}