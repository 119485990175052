import React from 'react';
import {Paper} from '@mui/material';


const EmptySection = ({children}) => {

  return (
      <Paper sx={{ padding: '20px' }}>
        {children}
      </Paper>
  )
}

export default EmptySection;
