import React from "react";
import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button";
import {FriendFormStyled} from './Styles';
import {ColorPicker, createColor} from "mui-color";

const FriendForm = ({item, handleSubmit, handleClose, type}) => {
  const [newItem, setNewItem] = React.useState(item);
  const [color, setColor] = React.useState(
      createColor(item.color ? `#${item.color}` : "#bdbdbd", 1));

  const handleChange = (e) => {
    let copy = {...newItem};
    copy[e.target.id] = e.target.value;
    setNewItem(copy)
  };

  const handleColor = (color) => {
    setColor(color)
    if (color.hex !== 'bdbdbd') {
      setNewItem({...newItem, color: color.hex});
    }
  }

  return (
    <FriendFormStyled onSubmit={(e) => handleSubmit(e, newItem)}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h6">
            {type === 'ADD' ? "Add Friend" : "Change Color"}
          </Typography>
        </Grid>
        {type === 'ADD' && (
            <Grid item>
              <TextField id="email"
                         label="Email"
                         variant="standard"
                         required
                         autoComplete="off"
                         fullWidth
                         autoFocus
                         value={newItem.email}
                         onChange={handleChange}
                         sx={{
                           margin: '10px 0 0 0'
                         }} />
            </Grid>
        )}
        <Grid item container spacing={1} alignItems="center" mt="5px">
          <Grid item>
            <Typography align="center">
              Color:
            </Typography>
          </Grid>
          <Grid item>
            <ColorPicker onChange={handleColor}
                         hideTextfield
                         disableAlpha
                         value={color}/>
          </Grid>
        </Grid>
        <Grid container item spacing={1} justifyContent="flex-end" mt="10px">
          <Grid item>
            <Button variant={"text"} onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained"
                    color="primary">
              {newItem.add ? "Add" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </FriendFormStyled>
  );
}

export default FriendForm;
