import React from "react";
import FriendForm from "./FriendForm";
import {useDispatch, useSelector} from "react-redux";
import {updateFriendAction} from "../../../actions/FriendActions";
import {useSnackbar} from "notistack";
import {PaperStyled} from './Styles';

const EditFriend = ({item, onEdit}) => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.id);
  const {enqueueSnackbar} = useSnackbar();

  const handleSubmit = (e, newItem) => {
    e.preventDefault();

    dispatch(updateFriendAction(userId, item.id, {...newItem})).then(() => {
      enqueueSnackbar("Updated color", {variant: 'success'})
      onEdit();
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    })
  }

  const handleClose = (e) => {
    onEdit();
  }

  return (
      <PaperStyled>
        <FriendForm {...{item, handleSubmit, handleClose}} />
      </PaperStyled>
  );
}

export default EditFriend;
