import React, {useEffect} from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {useDispatch, useSelector} from "react-redux";
import {getWishlistItemDetailsByIdAction} from "../actions/WishlistItemDetailActions";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grow from "@mui/material/Grow/Grow";
import {setTitle} from "../slices/NavigatorSlice";
import ClaimedButton from "../components/buttons/ClaimedButton";
import ContributeButton from "../components/buttons/ContributeButton";
import CommentButton from "../components/buttons/CommentButton";
import CommentSection from "../components/comment/CommentSection";
import {
  reset,
  setWishlistItemDetailLoading
} from "../slices/WishlistItemDetailSlice";
import currency from 'currency.js';
import {isAssociateLink} from '../util/AffiliateUtils';
import LoadingComponent from '../components/utils/LoadingComponent';
import {useSnackbar} from 'notistack';
import {useMediaQuery, useTheme} from '@mui/material';
import {StyledDetailImage} from "./styles";

function WishlistItemDetailPage(props) {
  const wishlistId = props.match.params.wishlistId;
  const itemId = props.match.params.itemId;
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const {enqueueSnackbar} = useSnackbar();
  const {item, contributions, claims, loading} = useSelector(
      state => state.wishlistItemDetail);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const contAmount = contributions && contributions.contributions ?
      contributions.contributions.map(c => c.amount).reduce((a, c) => a + c, 0) : 0;
  const claimedQuantity = claims.map(claim => claim.quantity).reduce(
      (a, c) => a + c, 0)

  useEffect(() => {
    dispatch(setWishlistItemDetailLoading(true));
    dispatch(reset());
    dispatch(getWishlistItemDetailsByIdAction(wishlistId, itemId)).then(
        json => {
          dispatch(setTitle(json.title));
        }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'});
    }).finally(() => {
      dispatch(setWishlistItemDetailLoading(false));
    });
  }, [wishlistId, itemId]);

  const sx = {
    padding: 3
  }
  if (item.imageUrl) {
    Object.assign(sx, {
      backgroundImage: `linear-gradient(
              rgba(0, 0, 0, 0.75),
              rgba(0, 0, 0, 0.75)
            ), url(${item.imageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: 'white'
    });
  }

  return (
    <LoadingComponent loading={loading}>
      <Grow in={true}>
        <Paper sx={sx}>
          <Grid container spacing={1} direction="column">
            <Grid container item xs spacing={2}>
              {item.imageUrl && matches && (
                  <Grid item>
                    <StyledDetailImage src={item.imageUrl} alt="Image of item"/>
                  </Grid>
              )}
              <Grid item container xs direction="column">
                <Grid item container direction="column" marginBottom="5px">
                  <Grid item>
                    {item.link ? (
                        <Link
                          href={item.link}
                          target="_blank"
                          variant="h6"
                          underline="hover">
                          {item.title}
                        </Link>
                        ) : (
                        <Typography variant="h6">
                          {item.title}
                        </Typography>
                    )}
                  </Grid>
                  {isAssociateLink(item.link) && (
                      <Grid item marginTop="-5px">
                        <Typography variant="caption">
                          Amazon affiliate link
                        </Typography>
                      </Grid>
                  )}
                </Grid>
                <Grid item>
                  <Typography>
                    Quantity: {item.quantity}
                    &nbsp;{claimedQuantity > 0 && (`(${claimedQuantity === item.quantity ? 'fully' : claimedQuantity} claimed)`)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    Price: {currency(item.price).format()}&nbsp;
                    {item.ownerId !== user.id && contAmount > 0 &&
                    <span>({currency(
                        item.price - contAmount).format()} remaining)</span>}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {item.ownerId !== user.id && (
                <Grid item container spacing={1} marginTop="10px" padding="0 !important">
                  <Grid item xs>
                    <ClaimedButton wishlistId={wishlistId} itemId={itemId}
                                   quantity={item.quantity}/>
                  </Grid>
                  <Grid item xs>
                    <ContributeButton wishlistId={wishlistId} itemId={itemId}/>
                  </Grid>
                  <Grid item xs>
                    <CommentButton/>
                  </Grid>
                </Grid>)}
            {item.description && (
                <Grid item>
                  <Typography marginTop="20px">
                    {item.description}
                  </Typography>
                </Grid>
            )}
          </Grid>
        </Paper>
      </Grow>
      {item.ownerId !== user.id &&
      <CommentSection wishlistId={wishlistId} itemId={itemId}/>}
    </LoadingComponent>
  );
}

export default WishlistItemDetailPage;
