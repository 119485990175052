import FetchWrapper from "./FetchWrapper";

const getLinkedWishlistsByType = (type) => {
  return FetchWrapper(`/wishlists/links/type/${type}`);
};

const addLinkedWishlist = (payload) => {
  return FetchWrapper(`/wishlists/links`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const getLinkByWishlistId = (wishlistId) => {
  return FetchWrapper(`/wishlists/${wishlistId}/link`);
}

const updateLinkPermission = (id, payload) => {
  return FetchWrapper(`/wishlists/links/${id}/permission`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const deleteWishlistLink = (id) => {
  return FetchWrapper(`/wishlists/links/${id}`, {
    method: 'DELETE',
  });
};

const deleteLinkOnWishlist = (id) => {
  return FetchWrapper(`/wishlists/${id}/link`, {
    method: 'DELETE',
  });
};

export { getLinkedWishlistsByType, getLinkByWishlistId, addLinkedWishlist, updateLinkPermission, deleteWishlistLink, deleteLinkOnWishlist }