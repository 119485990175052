import {CardContent, useTheme} from "@mui/material";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import getLetterColor from "../../../constants/LetterColors";
import Grid from "@mui/material/Grid";
import currency from "currency.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Collapse from "@mui/material/Collapse";
import ConfirmDeleteDialog from "../../dialog/ConfirmDeleteDialog";
import {deleteUserItemAction} from "../../../actions/UserItemsActions";
import {useDispatch} from "react-redux";
import UserItemHeader from "./UserItemHeader";
import {useSnackbar} from "notistack";
import {BigAvatarStyled, CardImageStyled} from "./styles";

const UserItem = ({item, setEdit}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false)

  const handleDelete = (e, item) => {
    dispatch(deleteUserItemAction(item.id))
    .then(() => {
      enqueueSnackbar("Deleted item", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  return (
    <React.Fragment>
      <Grid container direction="column" height="100%">
        <Grid item>
          <UserItemHeader {...{item, setEdit, setOpen}} />
        </Grid>
        <Grid item xs>
          {item.imageUrl
              ? <CardImageStyled image={item.imageUrl}/>
              : (
                <CardImageStyled>
                  <BigAvatarStyled style={{
                    backgroundColor: getLetterColor(item.title.charAt(0)),
                  }}>
                    {item.title.charAt(0).toUpperCase()}
                  </BigAvatarStyled>
                </CardImageStyled>
              )}
        </Grid>
        <Grid item>
          <CardContent sx={{ paddingBottom: "10px !important" }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography color="inherit">
                  Quantity: {item.quantity}
                </Typography>

                <Typography color="inherit">
                  Price: {item.price > 0 ? currency(item.price).format()
                    : "Not specified"}
                </Typography>
              </Grid>
              {item.description &&
              <Grid item>
                <IconButton onClick={() => setExpanded(!expanded)} color="inherit" size="large">
                  {!expanded ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
                </IconButton>
              </Grid>}
            </Grid>
            {item.description &&
            <Collapse in={expanded}>
              <Typography marginTop={ theme.spacing(3) }>
                {item.description}
              </Typography>
            </Collapse>}
          </CardContent>
        </Grid>

      </Grid>
      <ConfirmDeleteDialog
          open={open}
          setOpen={setOpen}
          name={item.title}
          onConfirm={(e) => handleDelete(e, item)}/>
    </React.Fragment>
  );
}

export default UserItem;
