import {
  claimItem,
  getClaimByWishlistItem,
  unclaimItem,
  updateClaimItem
} from "../api/ClaimApi";

const getClaimByWishlistItemAction = (wishlistId, id) => async dispatch => {
  return getClaimByWishlistItem(wishlistId, id);
};

const claimItemAction = (payload) => async dispatch => {
  return await claimItem(payload, 'POST');
};

const updateClaimAction = (id, payload) => async dispatch => {
  return await updateClaimItem(id, payload);
};

const unclaimItemAction = (itemId) => async dispatch => {
  return await unclaimItem(itemId);
};

export {
  getClaimByWishlistItemAction,
  claimItemAction,
  updateClaimAction,
  unclaimItemAction
}