import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {MODAL_TYPE} from "../../constants/ModalConstants";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {getUserItemsAction} from "../../actions/UserItemsActions";
import Checkbox from "@mui/material/Checkbox";
import {linkUserItemsAction} from "../../actions/WishlistItemActions";
import {Link} from "react-router-dom";
import Grow from "@mui/material/Grow";
import {setWishlistItemsModal} from "../../slices/ModalSlice";
import Select from "@mui/material/Select";
import Collapse from "@mui/material/Collapse";
import {FormControl, Grid} from "@mui/material";
import {useSnackbar} from "notistack";
import MenuItem from '@mui/material/MenuItem';
import {
  StyledButtonGroup,
  StyledForm,
  StyledLinkItemButton,
  StyledList,
  StyledModal,
  StyledPaper,
  StyledPriorityGrid
} from "./styles";

function LinkUserItemModal() {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {wishlistItemsModal} = useSelector(state => state.modals);
  const {userItems} = useSelector(state => state.userItems);
  const {wishlistItems, wishlist} = useSelector(state => state.wishlistItems);
  const [availableUserItems, setAvailableUserItems] = useState([]);

  React.useEffect(() => {
    dispatch(getUserItemsAction());
  }, [dispatch]);

  React.useEffect(() => {
    setAvailableUserItems(userItems.filter(item => {
      let matched = false;
      wishlistItems.forEach(wItem => {
        if (wItem.userItemId === item.id) {
          matched = true;
        }
      });
      return !matched;
    })
    .map(item => ({...item, checked: false, priority: 'Normal'})));
  }, [wishlistItems, userItems]);

  const handleClose = () => {
    dispatch(setWishlistItemsModal({...wishlistItemsModal, open: false}));
  };

  const toggleUserItem = (item) => {
    let index = availableUserItems.map(it => it.id).indexOf(item.id);
    availableUserItems[index] = {...item, checked: !item.checked};
    setAvailableUserItems([...availableUserItems]);
  };

  const changePriority = (e, item) => {
    const index = availableUserItems.map(it => it.id).indexOf(item.id);
    availableUserItems[index] = {...item, priority: e.target.value};
    setAvailableUserItems([...availableUserItems]);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(linkUserItemsAction(wishlist.id,
        availableUserItems.filter(it => it.checked))).then(json => {
      handleClose();
      enqueueSnackbar("Linked items", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  return (
      <StyledModal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={wishlistItemsModal.open}
          onClose={handleClose}
          closeAfterTransition>
        <Grow in={wishlistItemsModal.open}>
          <StyledPaper>
            <StyledForm onSubmit={handleSubmit}>
              <Typography variant="h5">
                {MODAL_TYPE.ADD} Items
              </Typography>
              {availableUserItems.length > 0 ?
                  <StyledList aria-label="main mailbox folders" dense>
                    {availableUserItems.map(item =>
                        <React.Fragment key={item.id}>
                          <ListItem button key={item.id} onClick={() => {
                            toggleUserItem(item)
                          }}>
                            <ListItemIcon>
                              <Checkbox name="items" checked={item.checked}
                                        disableRipple/>
                            </ListItemIcon>
                            <ListItemText primary={item.title}/>
                          </ListItem>
                          <Collapse in={item.checked}>
                            <StyledPriorityGrid container alignItems="center" spacing={1}>
                              <Grid item>
                                <Typography
                                    variant='body2'>Priority:</Typography>
                              </Grid>
                              <Grid item>
                                <FormControl fullWidth={true} variant="standard">
                                  <Select
                                      value={item.priority}
                                      onChange={(e) => changePriority(e, item)}>
                                    <MenuItem value={'High'}>High</MenuItem>
                                    <MenuItem value={'Normal'}>Normal</MenuItem>
                                    <MenuItem value={'Low'}>Low</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </StyledPriorityGrid>
                          </Collapse>
                        </React.Fragment>
                    )}
                  </StyledList>
                  :
                  <Typography marginTop={2}>
                    No more items available. Add more items to your items to see
                    more here.
                  </Typography>}
              <StyledButtonGroup>
                {availableUserItems.length > 0 ?
                    <StyledLinkItemButton type="submit" variant="contained" color="primary">
                      {MODAL_TYPE.ADD}
                    </StyledLinkItemButton>
                    :
                    <StyledLinkItemButton
                        variant="contained"
                        color="primary"
                        component={Link} to="/items"
                        onClick={handleClose}>
                      Go to my items
                    </StyledLinkItemButton>
                }
              </StyledButtonGroup>
            </StyledForm>
          </StyledPaper>
        </Grow>
      </StyledModal>
  )
}

export default LinkUserItemModal;