import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "User",
  initialState: {
    id: undefined,
    type: "",
    details: {},
    emailPrefs: {},
    pendingEmailPrefs: {},
    pendingDetails: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: ""
    },
    loggedIn: false,
    fetchingDetails: false,
    token: undefined
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.id = action.payload.id
      state.type = action.payload.type
      state.details = {...state.details, ...action.payload};
      state.pendingDetails = {...state.pendingDetails, ...action.payload};
    },
    setPendingDetails: (state, action) => {
      state.pendingDetails = {...state.pendingDetails, ...action.payload};
    },
    setFetchingUserDetails: (state, action) => {
      state.fetchingDetails = action.payload;
    },
    loginUser: (state, action) => {
      state.loggedIn = true;
      state.token = action.payload;
    }
  }
});

export const {
  setUserDetails,
  setPendingDetails,
  setFetchingUserDetails,
  loginUser
} = slice.actions;

export default slice.reducer;