import {
  setFriendRequests,
  setFriends,
  setPendingRequests
} from "../slices/FriendsSlice";
import {
  addFriend,
  deleteFriend,
  deleteFriendRequest,
  getFriendRequests,
  getFriends,
  getFriendsNotAlreadyShared,
  getPendingRequests,
  updateFriend,
  verifyFriend
} from "../api/FriendApi";

const getFriendsAction = (userId) => async dispatch => {
  return getFriends(userId).then(json => {
    dispatch(setFriends(json));
  })
};

const getFriendRequestsAction = (userId) => async dispatch => {
  return getFriendRequests(userId).then(json => {
    dispatch(setFriendRequests(json));
  })
};

const getPendingRequestsAction = (userId) => async dispatch => {
  return getPendingRequests(userId).then(json => {
    dispatch(setPendingRequests(json));
  })
};

const getFriendsNotAlreadySharedAction = (userId,
    wishlistId) => async dispatch => {
  return getFriendsNotAlreadyShared(userId, wishlistId);
};

const verifyFriendAction = (id, token) => () => {
  return verifyFriend(id, token);
};

const addFriendAction = (userId, payload) => async dispatch => {
  return await addFriend(userId, payload).then(json => {
    dispatch(getFriendsAction(userId));
    dispatch(getFriendRequestsAction(userId))

    return json;
  });
};

const updateFriendAction = (userid, friendId, payload) => (dispatch) => {
  return updateFriend(userid, friendId, payload).then(json => {
    dispatch(getFriendsAction(userid));

    return json;
  })
}

const deleteFriendAction = (userId, id) => async dispatch => {
  return await deleteFriend(userId, id).then(json => {
    dispatch(getFriendsAction(userId));

    return json;
  });
};

const deleteFriendRequestAction = (userId, id) => async dispatch => {
  return await deleteFriendRequest(userId, id).then(json => {
    dispatch(getFriendRequestsAction(userId))

    return json;
  })
}

export {
  getFriendsAction,
  getFriendRequestsAction,
  getPendingRequestsAction,
  getFriendsNotAlreadySharedAction,
  verifyFriendAction,
  addFriendAction,
  updateFriendAction,
  deleteFriendAction,
  deleteFriendRequestAction
}