import styled from "@emotion/styled";
import Modal from "@mui/material/Modal";
import {Button, Grid, List, Paper} from "@mui/material";

export const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3)
}));

export const StyledForm = styled('form')({
  margin: 'auto',
  maxWidth: '300px',
  textAlign: 'center',
})

export const StyledButtonGroup = styled('div')({
  marginTop: '20px',
  verticalAlign: 'middle',
})

export const StyledButton = styled(Button)({
  marginLeft: 0
})

export const StyledContributeWrapperPaper = styled(Paper)(({ theme}) => ({
  maxWidth: 470,
  margin: 'auto',
  padding: theme.spacing(2),
  overflow: 'hidden',
}));

export const StyledList = styled(List)({
  maxHeight: 300,
  overflowY: 'auto'
})

export const StyledPriorityGrid = styled(Grid)({
  paddingLeft: 72,
  minWidth: 225,
  width: '100%',
  marginBottom: 10
})

export const StyledLinkItemButton = styled(Button)({
  marginLeft: 0,
})