import {useDispatch, useSelector} from "react-redux";
import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {MODAL_TYPE} from "../../constants/ModalConstants";
import {setSelectedComment} from "../../slices/CommentSlice";
import {
  deleteCommentAction,
  getCommentsByWishlistItemAction
} from "../../actions/CommentActions";
import {setCommentModal} from "../../slices/ModalSlice";
import moment from "moment";
import {useSnackbar} from "notistack";
import CommentAction from "./CommentAction";
import {Stack} from "@mui/material";
import PaperListPage from "../items/PaperList/PaperListPage";
import PaperListItem from "../items/PaperList/PaperListItem";

function CommentSection({wishlistId, itemId}) {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {email} = useSelector(state => state.user.details);
  const {item} = useSelector(state => state.wishlistItemDetail);
  const {comments} = useSelector(state => state.wishlistItemDetail);

  React.useEffect(() => {
    dispatch(getCommentsByWishlistItemAction(wishlistId, itemId));
  }, [wishlistId, itemId]);

  const handleEdit = (e, comment) => {
    e.stopPropagation();

    dispatch(setSelectedComment(comment));
    dispatch(setCommentModal({open: true, type: MODAL_TYPE.UPDATE}));
  };

  const handleDelete = (e, comment) => {
    e.stopPropagation();

    dispatch(deleteCommentAction(item.wishlistId, item.id, comment.id))
    .then(() => {
      enqueueSnackbar("Deleted comment", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  const formatDate = val => {
    const date = Date.parse(val);

    if (date) {
      return moment(date).fromNow();
    }
  }

  return (
    <PaperListPage>
      {comments.map(comment =>
          <PaperListItem key={comment.id} sx={{ marginTop: "7px" }}>
            <Stack spacing={3}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography color="textSecondary" variant="subtitle2">
                    By {comment.user.firstName + " "
                  + comment.user.lastName}
                  </Typography>
                </Grid>
                <Grid item container sx={{width: "auto"}}>
                  <Grid item>
                    <Typography color="textSecondary" variant="subtitle2" mr="5px">
                      {formatDate(comment.updated)}
                    </Typography>
                  </Grid>
                  {comment.user.email === email && (
                      <Grid item sx={{ marginTop: "-5px" }}>
                        <CommentAction item={comment} onDelete={handleDelete} onEdit={handleEdit} />
                      </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item sx={{ marginTop: "5px!important" }}>
                <Typography>
                  {comment.content}
                </Typography>
              </Grid>
            </Stack>
          </PaperListItem>
      )}
    </PaperListPage>
  );
}

export default CommentSection;