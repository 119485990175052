import React, {useState} from 'react';
import ButtonPopper from '../../utils/ButtonPopper';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListIconDense from '../../utils/ListIconDense';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const BrowseButtons = ({ item, onFollow }) => {
  const [popper, setPopper] = useState(null);

  const handleShare = (e) => {
    e.stopPropagation();
    setPopper(null);

    onFollow(item);
  }

  return (
      <ButtonPopper {...{popper, setPopper}}>
        <Paper>
          <List dense>
            <ListItem button onClick={handleShare}>
              <ListIconDense>
                <BookmarkIcon/>
              </ListIconDense>
              <ListItemText primary="Follow"/>
            </ListItem>
          </List>
        </Paper>
      </ButtonPopper>
  )
}

export default BrowseButtons;
