import React from "react";
import {Button, Grid} from "@mui/material";
import TextField from '@mui/material/TextField/TextField';
import {useDispatch} from 'react-redux';
import {useSnackbar} from 'notistack';
import {
  addShareLinkAction,
  getShareLinksAction
} from '../../actions/WishlistActions';
import ShareWithLink from './ShareWithLink';
import LoadingComponent from "../utils/LoadingComponent";


const ShareWithLinksTab = ({wishlistId, linkUser}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [name, setName] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const manage = linkUser.permission === 'ADMIN' || linkUser.permission === 'SHARE';

  React.useEffect(() => {
    loadLinks();
  }, [wishlistId]);

  const loadLinks = () => {
    setLoading(true);

    dispatch(getShareLinksAction(wishlistId)).then(json => {
      setItems(json);
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'});
    }).finally(() => {
      setLoading(false);
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(addShareLinkAction(wishlistId, {name})).then(json => {
      setItems([...items, json]);
      navigator.clipboard
      .writeText(json.shareUrl)
      .then(() => enqueueSnackbar("Copied share link to clipboard",
              {variant: 'success'}),
          () => enqueueSnackbar("Error copying to clipboard",
              {variant: 'error'}));
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'});
    })
  }

  const handleNameChange = (e) => {
    setName(e.target.value);
  }

  const handleDelete = () => {
    loadLinks();
  }

  return (
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item container alignItems="flex-end" spacing={1}>
            <Grid item xs>
              <TextField id="name"
                         label="Name or Group"
                         variant="standard"
                         required
                         fullWidth
                         value={name}
                         onChange={handleNameChange}/>
            </Grid>
            <Grid item>
              <Button type="submit"
                      variant="contained"
                      color="primary">
                Add
              </Button>
            </Grid>
          </Grid>
          <LoadingComponent loading={loading}>
            <Grid item>
              {items.map(
                  (item, i) => <ShareWithLink key={i} item={item} manage={manage}
                                              onDelete={handleDelete}/>)}
            </Grid>
          </LoadingComponent>
        </Grid>
      </form>
  )
}

export default ShareWithLinksTab