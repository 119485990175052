import styled from "@emotion/styled";
import {Box} from "@mui/material";

export const StyledForm = styled('form')({
  margin: 'auto',
})

export const StyledUL = styled('ul')({
  listStyleType: 'none',
  margin: 5,
  padding: 0,
})

export const StyledHighlight = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: 10
})