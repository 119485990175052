import Avatar from "@mui/material/Avatar";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {MoreVert} from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import {CardHeader} from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import UserItemSubMenu from "./UserItemSubMenu";

const UserItemHeader = ({ item, setEdit, setOpen }) => {
  const [ popper, setPopper ] = useState(null);
  const popperOpen = Boolean(popper);

  const handlePopper = (event) => {
    setPopper(popper ? null : event.currentTarget);
  };

  return (
    <CardHeader
        sx={{
          padding: 2,
          height: '100%',
        }}
        avatar={
          <Avatar>
            {item.title.charAt(0).toUpperCase()}
          </Avatar>
        }
        action={
          <React.Fragment>
            <IconButton onClick={handlePopper} size="large">
              <MoreVert />
            </IconButton>
            <Popover
                open={popperOpen}
                onClose={() => setPopper(null)}
                anchorEl={popper}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}>
              <UserItemSubMenu {...{item, setEdit, setOpen, setPopper}} />
            </Popover>
          </React.Fragment>
        }
        title={item.link ?
            <Link
              href={item.link}
              target="_blank"
              variant="subtitle1"
              underline="hover">
              {item.title}
            </Link>
            :
            <Typography variant="subtitle1">
              {item.title}
            </Typography>
        } />
  );
}

export default UserItemHeader;