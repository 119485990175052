import React from 'react';
import {Grid, Typography} from '@mui/material';
import EmptySection from './EmptySection';

const FriendsEmpty = () => {

  return (
      <EmptySection>
        <Typography color="textSecondary" align="center">
          You have no friends! Forever alone &#128546;
        </Typography>
      </EmptySection>
  )
}

export default FriendsEmpty;