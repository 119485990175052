import React from "react";
import {Stack, Typography} from "@mui/material";
import WishlistItem from "./WishlistItem";

const WishlistItemPrioritySection = ({priority, items}) => {

  return (
      <Stack>
        {priority && (
            <Typography mt={2} ml={1} variant="h6">
              {priority}
            </Typography>
        )}
        {items.map((item, index) =>
            <WishlistItem
                key={index}
                item={item.wishlistItem}
                contributions={item.contributions}
                claims={item.claims} ownerId={item.ownerId}/>
        )}
      </Stack>
  );
}

export default WishlistItemPrioritySection;