import FetchWrapper from "./FetchWrapper";

const getWishlists = () => {
  return FetchWrapper(`/wishlists`);
};

const getWishlistsSharedWithMe = () => {
  return FetchWrapper(`/wishlists/shared`);
};

const getWishlistById = (id) => {
  return FetchWrapper(`/wishlists/${id}`);
};

const addWishlist = (payload) => {
  return FetchWrapper(`/wishlists`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const updateWishlist = (payload) => {
  return FetchWrapper(`/wishlists/${payload.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const deleteWishlist = (id) => {
  return FetchWrapper(`/wishlists/${id}`, {
    method: 'DELETE'
  });
};

const addShareLink = (id, payload) => {
  return FetchWrapper(`/wishlists/${id}/share`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

const updateShareLink = (id, shareId, payload) => {
  return FetchWrapper(`/wishlists/${id}/share/${shareId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

const deleteShareLink = (id, shareId) => {
  return FetchWrapper(`/wishlists/${id}/share/${shareId}`, {
    method: 'DELETE',
  });
}

const getShareLinks = (id) => {
  return FetchWrapper(`/wishlists/${id}/share`);
}

export {
  getWishlists,
  getWishlistsSharedWithMe,
  getWishlistById,
  getShareLinks,
  addWishlist,
  updateWishlist,
  deleteWishlist,
  addShareLink,
  updateShareLink,
    deleteShareLink,
}