import {searchAmazon, searchAmazonProduct} from "../api/SearchAmazonApi";

const searchAmazonAction = (term) => async dispatch => {
  return searchAmazon(term);
};

const searchAmazonProductAction = (term) => async dispatch => {
  return searchAmazonProduct(term);
};

export {searchAmazonAction, searchAmazonProductAction}