import React from 'react';
import {Grid, Typography} from '@mui/material';
import EmptySection from './EmptySection';

const BrowseFriendEmpty = () => {

  return (
      <EmptySection>
        <Typography color="textSecondary">
          Looks like there is nothing to show
        </Typography>
        <br />
        <Typography component="div" color="textSecondary">
          Reasons you don't see anything here:
          <ul>
            <li>You have no friends (that is sad)</li>
            <li>You are already following all your friends wishlists</li>
            <li>Your friends have not set their wishlist to Friend or Public visibility</li>
          </ul>
        </Typography>
      </EmptySection>
  )
}

export default BrowseFriendEmpty;