import React, {useState} from 'react';
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {
  claimItemAction,
  getClaimByWishlistItemAction,
  unclaimItemAction,
  updateClaimAction
} from "../../actions/ClaimActions";
import {useSnackbar} from 'notistack';
import {Grid, IconButton, Paper, useTheme} from "@mui/material";
import {AddCircleOutlined, RemoveCircleOutlined} from "@mui/icons-material";
import {setClaims} from "../../slices/WishlistItemDetailSlice";
import {getClaimedQuantityNotByUser, getUserClaim} from '../../util/ClaimUtils';


// TODO: Need to restrict claiming only what is left. Need to display claimed quantity information
function ClaimedButton({wishlistId, itemId, quantity}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  const userId = useSelector(state => state.user.id);
  const item = useSelector(state => state.wishlistItemDetail.item);
  const claims = useSelector(state => state.wishlistItemDetail.claims);
  const [toggle, setToggle] = useState(false);
  const [loaded, setLoaded] = useState(false);
  let [inputQuantity, setInputQuantity] = useState(0);
  let [instant, setInstant] = useState(false);
  const availableQuantity = quantity - getClaimedQuantityNotByUser(claims, userId);
  const currentUserClaim = getUserClaim(claims, userId);
  const disabled = (currentUserClaim && currentUserClaim.user
      && !currentUserClaim) || (availableQuantity === 0);
  let text = "Claim";

  if (currentUserClaim) {
    text = "Unclaim";
  } else if (claims > 0) {
    text = `Claimed`
  }

  React.useEffect(() => {
    dispatch(getClaimByWishlistItemAction(wishlistId, itemId)).then(json => {
      dispatch(setClaims(json));

      json.forEach(claim => {
        if (claim.user.id === userId) {
          setToggle(true);
          setInputQuantity(claim.quantity);
        }
      });

      setLoaded(true);
    });
  }, [wishlistId, itemId]);

  React.useEffect(() => {

    let delay;
    if (delay) {
      clearTimeout(delay);
      delay = null;
    }

    if (loaded && !instant) {
      delay = setTimeout(sendClaim, 1000);
    } else if (instant) {
      sendClaim();
      setInstant(false)
    }

    return () => clearTimeout(delay);
  }, [inputQuantity]);

  const sendClaim = () => {
    if (currentUserClaim) {
      if (inputQuantity > 0) {
        dispatch(updateClaimAction(
            currentUserClaim.id,
            {
              ...currentUserClaim,
              quantity: inputQuantity
            })).then(json => {
          dispatch(setClaims(
              [...claims].map(
                  claim => claim.user.id === userId ? json : claim)));
          enqueueSnackbar("Claim updated", {variant: 'success'});
        }).catch(error => {
          console.error(error);
          enqueueSnackbar(error, {variant: 'error'});
        });
      } else {
        dispatch(unclaimItemAction(currentUserClaim.id)).then(json => {
          enqueueSnackbar("Item no longer claimed", {variant: 'success'});
          dispatch(setClaims(claims.filter(claim => claim.user.id !== userId)));
          setToggle(false);
        })
        .catch(error => {
          enqueueSnackbar(error, {variant: 'error'});
        });
      }
    } else {
      dispatch(claimItemAction({
        wishlistId: item.wishlistId,
        wishlistItemId: item.id,
        quantity: inputQuantity
      })).then(json => {
        dispatch(setClaims([json, ...claims]));
        enqueueSnackbar("Claimed item", {variant: 'success'});
      }).catch(error => {
        console.error(error);
        enqueueSnackbar(error, {variant: 'error'});
      });
    }
  }

  const handlePlus = () => {
    if (inputQuantity < availableQuantity) {
      setInputQuantity(++inputQuantity);
    }
  }

  const handleMinus = () => {
    if (inputQuantity > 0) {
      inputQuantity--;
      setInputQuantity(inputQuantity);
    }
  }

  const clickHandler = e => {
    if (!currentUserClaim) {
      setToggle(!toggle);

      if (availableQuantity === 1) {
        setInstant(true);
        handlePlus();
      }
    }
  };

  return toggle ? (
      <Paper
          elevation={0}
          sx={{
            border: `2px solid ${theme.palette.primary.main}`,
            margin: 'auto',
            padding: '1px',
          }}>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item>
            <IconButton size="small" sx={{padding: "4px"}} onClick={handleMinus} disabled={inputQuantity === 0}>
              <RemoveCircleOutlined sx={inputQuantity > 0 ? { color: 'red' } : {}} />
            </IconButton>
          </Grid>
          <Grid item>
            {inputQuantity}
          </Grid>
          <Grid item>
            <IconButton size="small" sx={{padding: "4px"}} onClick={handlePlus} disabled={inputQuantity === availableQuantity}>
              <AddCircleOutlined sx={inputQuantity < availableQuantity ? { color: '#008b00'} : {}} />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
  ) : (
      <Button
          disabled={disabled}
          variant="contained"
          color="primary"
          onClick={clickHandler}
          fullWidth>
        {text}
      </Button>
  );
}

export default ClaimedButton