import React from "react";
import Grid from "@mui/material/Grid";
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField/TextField";
import AppBar from "@mui/material/AppBar/AppBar";
import {useDispatch} from "react-redux";
import {useTheme} from '@mui/material';
import TableAppBarActions from "./TableAppBarActions";

function TableAppBar({ placeholder, onSearchChange, menuItems }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  return (
      <AppBar sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} position="static" color="default" elevation={0}>
          <Grid container spacing={2} alignItems="center" padding={1}>
            <Grid item marginLeft="5px">
              <label htmlFor="search">
                <SearchIcon sx={{ display: 'block' }} color="inherit" />
              </label>
            </Grid>
            <Grid item xs>
              <TextField
                  id="search"
                  variant="standard"
                  fullWidth
                  placeholder={placeholder}
                  InputProps={{
                    disableUnderline: true,
                    sx: {
                      fontSize: theme.typography.fontSize,
                    }
                  }}
                  onChange={(e) => dispatch(onSearchChange(e.target.value))}
              />
            </Grid>
            { menuItems && (
                <Grid item marginRight="6px">
                  <TableAppBarActions items={menuItems} />
                </Grid>
            )}
          </Grid>
      </AppBar>
  );
}

export default TableAppBar;
