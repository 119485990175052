import {createSlice} from "@reduxjs/toolkit";
import {MODAL_TYPE} from "../constants/ModalConstants";
import {SHARED_ITEM_STATE, USER_ITEM_STATE} from "../constants/StateConstants";

const slice = createSlice({
  name: "Modals",
  initialState: {
    sharedItemModal: { open: false, type: MODAL_TYPE.ADD, item: SHARED_ITEM_STATE.DEFAULT },
    contributeModal: { open: false, type: MODAL_TYPE.UPDATE },
    commentModal: { open: false, type: MODAL_TYPE.ADD },
    userItemModal: { open: false, type: MODAL_TYPE.ADD, item: USER_ITEM_STATE.DEFAULT },
    wishlistItemsModal: { open: false, type: MODAL_TYPE.UPDATE },
    wishlistModal: { open: false, type: MODAL_TYPE.ADD },
    shareWishlistModal: { open: false, friends: [] },
    friendModal: { open: false },
  },
  reducers: {
    setSharedItemModal: (state, action) => { state.sharedItemModal = { ...state.sharedItemModal, ...action.payload }},
    setSharedItemModalData: (state, action) => { state.sharedItemModal.data = { ...state.sharedItemModal.data, ...action.payload }},
    setCommentModal: (state, action) => { state.commentModal = { ...state.commentModal, ...action.payload }},
    setContributeModal: (state, action) => { state.contributeModal = { ...state.contributeModal, ...action.payload }},
    setShareWishlistModal: (state, action) => { state.shareWishlistModal = { ...state.shareWishlistModal, ...action.payload }},
    setUserItemModal: (state, action) => { state.userItemModal = { ...state.userItemModal, ...action.payload }},
    setWishlistItemsModal: (state, action) => { state.wishlistItemsModal = { ...state.wishlistItemsModal, ...action.payload }},
    setWishlistModal: (state, action) => { state.wishlistModal = { ...state.wishlistModal, ...action.payload }},
    setFriendModal: (state, action) => { state.friendModal = { ...state.friendModal, ...action.payload }},
  }
});

export const {
  setSharedItemModal,
  setCommentModal,
  setContributeModal,
  setShareWishlistModal,
  setUserItemModal,
  setWishlistItemsModal,
  setWishlistModal,
  setFriendModal} = slice.actions;

export default slice.reducer