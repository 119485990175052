import FetchWrapper from "./FetchWrapper";

const getUserDetails = () => {
  return FetchWrapper(`/users`);
};

const updateUser = (payload) => {
  return FetchWrapper(`/users/${payload.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

const forgotPassword = (payload) => {
  return FetchWrapper(`/users/forgot-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

const resetPassword = (payload) => {
  return FetchWrapper(`/users/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
}

export {
  getUserDetails,
  updateUser,
  forgotPassword,
    resetPassword,
}