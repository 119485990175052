import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "SearchBar",
  initialState: {
    searchText: "",
  },
  reducers: {
    setSearchText: (state, action) => { state.searchText = action.payload },
  }
});

export const { setSearchText } = slice.actions;

export default slice.reducer