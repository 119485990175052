import React from "react";
import {addWishlistAction,} from "../../../actions/WishlistActions";
import {setAddWishlist} from "../../../slices/WishlistsSlice";
import WishlistForm from "./WishlistForm";
import {useDispatch} from "react-redux";
import {Paper, useTheme} from "@mui/material";
import {useSnackbar} from "notistack";
import useWishlistUtils from '../../../util/useWishlistUtils';

const AddWishlist = ({item}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const wishlistUtil = useWishlistUtils();
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = () => {
    dispatch(setAddWishlist({}));
  };

  const handleSubmit = (event, item) => {
    event.preventDefault();

    dispatch(addWishlistAction(item)).then(json => {
      dispatch(setAddWishlist({}))
      handleClose();
      enqueueSnackbar("Added wishlist", {variant: 'success'})
      wishlistUtil.getWishlistsByType('OWNER');
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  return (
      <Paper sx={{
        padding: theme.spacing(2, 4, 3),
        marginTop: 1,
      }}>
        <WishlistForm {...{item, handleSubmit, handleClose}} />
      </Paper>
  );
}

export default AddWishlist;