import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "home",
  initialState: {
    friends: [],
    friendRequests: [],
    pendingRequests: [],
    friendsSearch: '',
    addFriend: {},
    loading: false,
  },
  reducers: {
    setFriends: (state, action) => {
      state.friends = action.payload
    },
    setFriendRequests: (state, action) => {
      state.friendRequests = action.payload
    },
    setPendingRequests: (state, action) => {
      state.pendingRequests = action.payload
    },
    setFriendSearch: (state, action) => {
      state.friendsSearch = action.payload
    },
    setFriendLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAddFriend: (state, action) => {
      state.addFriend = action.payload;
    },
    reset: state => {
      state.friends = [];
      state.friendsSearch = "";
    }
  }
});

export const {
  setFriends,
  setFriendRequests,
  setPendingRequests,
  setFriendSearch,
  setFriendLoading,
  setAddFriend,
  reset
} = slice.actions;

export default slice.reducer;