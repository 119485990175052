
const permissions = [
  {
    label: 'Default',
    value: 'DEFAULT',
  },
  {
    label: 'Edit',
    value: 'EDIT',
  },
  {
    label: 'Share',
    value: 'SHARE',
  },
  {
    label: 'Admin',
    value: 'ADMIN',
  }
];

export { permissions };