import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {setAddWishlistItem} from "../../../slices/WishlistItemsSlice";
import {addWishlistItemAction} from "../../../actions/WishlistItemActions";
import {Paper, useTheme} from "@mui/material";
import WishlistItemForm from "./WishlistItemForm";
import {useSnackbar} from "notistack";

const WishlistItemAdd = ({item}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const linkUser = useSelector(state => state.wishlistItems.linkUser);

  const handleSubmit = (event, newItem) => {
    event.preventDefault();
    dispatch(addWishlistItemAction(linkUser.wishlist.id, newItem)).then(() => {
      dispatch(setAddWishlistItem({}))
      enqueueSnackbar("Added wishlist item", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  const handleCancel = () => {
    dispatch(setAddWishlistItem({}));
  }

  return (
      <Paper sx={{
        padding: theme.spacing(2, 4, 3),
        margin: '10px 2px',
      }}>
        <WishlistItemForm showPriority={true}
                          showAddToItems={linkUser.type === 'OWNER'
                          && item.add} {...{
          item,
          handleSubmit,
          handleCancel
        }} />
      </Paper>
  )
}

export default WishlistItemAdd;