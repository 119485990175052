import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import Typography from "@mui/material/Typography";
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {logoutAction} from "../actions/LogoutAction";


function ProfileButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const {details, id} = useSelector(state => state.user);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    handleClose();
  };

  const handleProfile = () => {
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {id ?
          <React.Fragment>
            <IconButton
              color="inherit"
              onClick={handleClick}
              size="large"
              sx={{ padding: '4px' }}>
              <Avatar src="/static/images/avatar/1.jpg"
                      alt={details.lastName}/>
            </IconButton>
            <Menu id="simple-menu"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
            >
              <MenuItem onClick={handleProfile} component={Link}
                        to="/profile">Profile</MenuItem>
              <MenuItem onClick={handleLogout} component={Link}
                        to="/">Logout</MenuItem>
            </Menu>
          </React.Fragment> :
          <Button color="inherit" component={Link} to="/login">
            <Typography>Login</Typography>
          </Button>
      }
    </React.Fragment>
  );
}

export default ProfileButton;
