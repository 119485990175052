import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import {Button} from "@mui/material";
import Card from "@mui/material/Card";
import React, {useState} from "react";
import {useHistory} from 'react-router-dom';

const HomeCard = ({ card }) => {
  const history = useHistory();
  const [elevation, setElevation] = useState(2);

  return (
      <Card sx={{ height: '100%' }}
            onClick={() => card.onClick(history)}
            elevation={elevation}
            onMouseOver={() => setElevation(4)}
            onMouseOut={() => setElevation(2)}>
        <CardContent>
          <Typography variant={"h6"} component="h2" gutterBottom>
            {card.title}
          </Typography>
          <Typography fontSize="14px" color="textSecondary" gutterBottom>
            {card.body}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary">{card.button}</Button>
        </CardActions>
      </Card>
  );
}

export default HomeCard;
