import {List} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import EditIcon from "@mui/icons-material/Edit";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import React from "react";

const UserItemSubMenu = ({ item, setEdit, setPopper, setOpen }) => {

  const handleEdit = (e, item) => {
    e.stopPropagation();
    setPopper(null);
    setEdit(true);
    //dispatch(setUserItemModal({ open: true, type: MODAL_TYPE.UPDATE, item: item }));
  };

  const onDelete = (e) => {
    e.stopPropagation();
    setPopper(null);
    setOpen(true);
  }

  return (
      <React.Fragment>
        <Paper>
          <List>
            <ListItem button onClick={(e) => handleEdit(e, item)}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Edit" />
            </ListItem>
            <ListItem button onClick={onDelete}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </ListItem>
          </List>
        </Paper>
      </React.Fragment>
  );
}

export default UserItemSubMenu;