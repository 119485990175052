import React from 'react';
import {Box} from '@mui/material';
import TableAppBar from '../../appbar/TableAppBar';

const PaperListPage = ({children, onSearchChange, placeholder, menuItems, ...other}) => {
  const more = Object.assign({ margin: 'auto', paddingTop: "4px"}, {...other})

  return (
    <React.Fragment>
      {(onSearchChange || placeholder) && (
          <TableAppBar {...{placeholder, menuItems, onSearchChange}} />
      )}
      <Box {...more}>
        {children}
      </Box>
    </React.Fragment>
  );
}

export default PaperListPage;
