import FetchWrapper from './FetchWrapper';

const browseFriendWishlists = () => {
  return FetchWrapper(`/browse/lists/FRIENDS`)
}

const linkWishlistToSelf = (visibility, payload) => {
  return FetchWrapper(`/browse/lists/${visibility}/link`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const browsePublicWishlists = () => {
  return FetchWrapper(`/browse/lists/PUBLIC`)
}

export {browseFriendWishlists, linkWishlistToSelf, browsePublicWishlists};