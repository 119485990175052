import {
  browseFriendWishlists,
  browsePublicWishlists,
  linkWishlistToSelf
} from '../api/BrowseWishlistApi';
import {setBrowseFriendWishlists, setBrowsePublicWishlists} from "../slices/BrowseSlice";

const browseFriendWishlistsAction = () => async dispatch => {
  return browseFriendWishlists().then(json => {
    dispatch(setBrowseFriendWishlists(json))

    return json;
  });
}

const linkWishlistToSelfAction = (visibility, payload) => async dispatch => {
  return linkWishlistToSelf(visibility, payload).then(json => {
    dispatch(browseFriendWishlistsAction());
  });
}

const browsePublicWishlistsAction = () => async dispatch => {
  return browsePublicWishlists().then(json => {
    dispatch(setBrowsePublicWishlists(json));

    return json;
  });
}

export {
  browseFriendWishlistsAction,
  linkWishlistToSelfAction,
  browsePublicWishlistsAction
};