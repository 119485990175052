import React, {useState} from "react";
import Card from "@mui/material/Card";
import EditUserItem from "./EditUserItem";
import UserItem from "./UserItem";
import {Collapse, Grow, useMediaQuery, useTheme} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  UserItemCardContainer,
  UserItemCardStyled,
  UserItemCollapse
} from './styles';

function UserItemCard({item}) {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('xl'));
  const [edit, setEdit] = useState(false);
  
  return (
      <UserItemCardContainer>
        <UserItemCardStyled component={Card} container>
          <Collapse component={Grid} mountOnEnter unmountOnExit item
                    in={edit}>
            <EditUserItem {...{item, setEdit}} />
          </Collapse>
          <UserItemCollapse component={Grid} variant="card" timeout={smDown ? 150 : 300} item
                    mountOnEnter
                    unmountOnExit
                    in={!edit}>
            <UserItem {...{item, setEdit}} />
          </UserItemCollapse>
        </UserItemCardStyled>
      </UserItemCardContainer>
  );
}

export default UserItemCard;
