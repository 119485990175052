import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "LinkedWishlists",
  initialState: {
    linkedWishlists: [],
  },
  reducers: {
    setLinkedWishlists: (state, action) => { state.linkedWishlists = action.payload },
    reset: state => { state.linkedWishlists = [] },
  }
});

export const { setLinkedWishlists, reset } = slice.actions;

export default slice.reducer