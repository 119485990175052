import {Tab, Tabs} from '@mui/material';
import styled from '@emotion/styled';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  margin: '0 10px',
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
  }
}));

export const StyledTab = styled(Tab)({
  borderBottom: '1px solid gray',
  margin: 0,
  padding: '0 16px'
})

export const StyledTooltipUl = styled('ul')({
  listStyleType: 'none',
  margin: 5,
  padding: 0,
})
