import React from "react";
import {useDispatch} from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import {setAddFriend, setFriendSearch} from "../slices/FriendsSlice";
import FriendList from "../components/items/Friend/FriendList";
import PaperListPage from '../components/items/PaperList/PaperListPage';
import {FRIEND_STATE} from "../constants/StateConstants";
import {StyledFab} from './styles';

function FriendsPage() {
  const dispatch = useDispatch();

  const handleAddButton = (e) => {
    dispatch(setAddFriend({add: true, ...FRIEND_STATE.DEFAULT}))
  };

  return (
      <PaperListPage placeholder={"Search by friend name"}
                     onSearchChange={setFriendSearch}>
        <FriendList/>

        <StyledFab color="primary" aria-label="add"
             onClick={handleAddButton}>
          <AddIcon/>
        </StyledFab>
      </PaperListPage>
  );
}

export default FriendsPage;
