import store from "../store/Store";
import Cookies from 'js-cookie';
import {getBaseUrl} from "../util/FetchUtil";
import {refreshTokenAction} from '../actions/LoginActions';
import {CookieConstants} from "../constants/CookieConstants";

export default function FetchWrapper(url, options) {
  if (Cookies.get(CookieConstants.shareId)) {
    if (url.includes('?')) {
      url += `&shareId=${Cookies.get(CookieConstants.shareId)}`
    } else {
      url += `?shareId=${Cookies.get(CookieConstants.shareId)}`
    }
  }

  return sendRequest(getBaseUrl() + url, options);
}

const sendRequest = (url, options) => {
  let config = {};
  const token = store.getState().user.token;

  const getHeaders = (headers) => {
    const authHeader = `Bearer ${token}`;
    if (headers) {
      return {...headers, 'authorization': authHeader}
    } else {
      return {'authorization': authHeader}
    }
  };

  if (token) {
    if (options) {
      config = {...options, headers: getHeaders(options.headers)}
    } else {
      config = {headers: getHeaders()}
    }
  } else {
    config = options
  }

  return fetch(url, config).then(async response => {
    if (response.status === 401) {
      return store.dispatch(
          refreshTokenAction(Cookies.get(CookieConstants.refreshToken))).then(
          () => {
            return sendRequest(url, options);
          });
    } else if (response.status === 204) {
      return Promise.resolve({});
    }

    return response.json();
  }).then(json => {
    if (!json || (json.status && json.status !== 200)) {
      return Promise.reject(
          json ? json.message : "Error occurred, please try again later");
    }

    return json;
  });
}
