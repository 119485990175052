import React, {useState} from 'react';
import ConfirmDeleteDialog from "../dialog/ConfirmDeleteDialog";

const DeleteAction = ({children, handleDelete, name, item, ...others}) => {
  const [open, setOpen] = useState(false);

  const onDelete = (e) => {
    e.stopPropagation();

    setOpen(true);
  }

  return (
      <React.Fragment>
        <div onClick={onDelete} {...others}>
          {children}
        </div>
        <ConfirmDeleteDialog
            open={open}
            setOpen={setOpen}
            name={name}
            onConfirm={(e) => handleDelete(e, item)}/>
      </React.Fragment>

  )
}

export default DeleteAction;