import React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

class DefaultErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {

  }

  render() {
    return this.state.hasError ? (
          <div style={{ background: '#eaeff1', width: '100%', display: 'flex' }}>
            <Paper style={{ padding: '8px', margin: 'auto', maxWidth: '300px' }}>
              <Grid container>
                <Grid item component={Typography} variant={'h6'}>
                  An error has occurred. Please try to reload the page. If the problem persists, contact:
                </Grid>
                <Grid item component={Typography}>
                  <a href="mailto:customer-support@jtrumpower.com?subject=Error Page">customer-support@jtrumpower.com</a>
                </Grid>
              </Grid>
            </Paper>
          </div>
    ) : this.props.children;
  }
}

export default DefaultErrorBoundary;