import React from 'react';
import {Grid, Typography} from '@mui/material';
import EmptySection from './EmptySection';

const WishlistsEmpty = () => {

  return (
      <EmptySection>
        <Typography color="textSecondary">
          You haven't made any wishlists yet. Better get started or your friends won't
          know what to get you!
        </Typography>
        <br/>
        <Typography component="div" color="textSecondary">
          Visibility:
          <ul>
            <li>
              Private: only visible to you unless you share manually
            </li>
            <li>
              Friends (default): Friends are able to search for and follow your wishlists
            </li>
            <li>
              Public: Your wishlist is visible to anyone who has an account, friend or not.
            </li>
          </ul>
        </Typography>
      </EmptySection>
  )
}

export default WishlistsEmpty;