import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteFriendAction} from "../../../actions/FriendActions";
import {useSnackbar} from "notistack";
import PaperListItem from '../PaperList/PaperListItem';
import FriendButton from "./FriendButton";


function Friend({item, onEdit}) {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const userId = useSelector(state => state.user.id);

  const handleDelete = (e) => {
    dispatch(deleteFriendAction(userId, item.id))
    .then(() => {
      enqueueSnackbar("Deleted Friend", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  return (
    <PaperListItem hover>
      <Grid container alignItems="center" justifyContent="space-between"
            spacing={2}>
        <Grid item>
          <Avatar variant="rounded"
                  style={item.color && {backgroundColor: `#${item.color}`}}>
            {item.friend.firstName.charAt(0).toUpperCase()}
          </Avatar>
        </Grid>
        <Grid item xs>
          <Typography color="textSecondary">
            {item.friend.firstName}&nbsp;{item.friend.lastName}&nbsp;
          </Typography>
        </Grid>
        <Grid item>
          <FriendButton item={item} onDelete={handleDelete} onEdit={onEdit} />
        </Grid>
      </Grid>
    </PaperListItem>
  );
}

export default Friend;
