import {getWishlistItemDetails} from "../api/WishlistItemDetailApi";
import {setWishlistItemDetailItems} from "../slices/WishlistItemDetailSlice";

const getWishlistItemDetailsByIdAction = (wishlistId, itemId) => async dispatch => {
  return await getWishlistItemDetails(wishlistId, itemId).then(json => {
    dispatch(setWishlistItemDetailItems(json));

    return json;
  });
};

export { getWishlistItemDetailsByIdAction };