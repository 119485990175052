import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ProfileButton from "../ProfileButton";
import React from "react";
import {useSelector} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';
import {IconButton} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Notifications from './Notifications';

function HeaderAppBar() {
  const userId = useSelector(state => state.user.id);
  const { title } = useSelector(state => state.navigator);
  const history = useHistory();
  const location = useLocation();

  return (
    <AppBar
        component="div"
        color="primary"
        position="static"
        elevation={0}
        sx={{
          zIndex: 0,
          paddingTop: '10px',
          paddingBottom: '10px'
        }}
    >
      <Toolbar>
        <Grid container alignItems="center" spacing={1}>
          {location.key &&
              <Grid item>
                <IconButton onClick={() => history.goBack()} color="inherit" size="large">
                  <ArrowBackIcon color="inherit"/>
                </IconButton>
              </Grid>}
          <Grid item xs>
            <Typography color="inherit" variant="h5" component="h1" textTransform="capitalize">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              {userId && (
                  <Grid item>
                    <Notifications />
                  </Grid>
              )}
              <Grid item>
                <ProfileButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default HeaderAppBar;