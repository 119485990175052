import React, {useState} from "react";
import Paper from "@mui/material/Paper";
import {List} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListIconDense from "../utils/ListIconDense";
import ListItemText from "@mui/material/ListItemText";
import DeleteAction from "../buttons/DeleteAction";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonPopper from "../utils/ButtonPopper";
import EditIcon from "@mui/icons-material/Edit";

const CommentAction = ({ item, onEdit, onDelete }) => {
  const [popper, setPopper] = useState(null);

  const handleDelete = (e, item) => {
    e.stopPropagation();

    setPopper(null);
    onDelete(e, item);
  };

  const handleEdit = (e) => {
    setPopper(null);
    onEdit(e, item);
  }

  return (
      <ButtonPopper padding="4px" {...{popper, setPopper}}>
        <Paper>
          <List dense>
            <ListItem button onClick={handleEdit}>
              <ListIconDense>
                <EditIcon/>
              </ListIconDense>
              <ListItemText primary="Edit"/>
            </ListItem>
            <DeleteAction
                handleDelete={handleDelete}
                item={item}
                name="Comment">
              <ListItem button>
                <ListIconDense>
                  <DeleteIcon />
                </ListIconDense>
                <ListItemText primary="Delete" />
              </ListItem>
            </DeleteAction>
          </List>
        </Paper>
      </ButtonPopper>
  );
}

export default CommentAction;