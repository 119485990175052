import React from "react";
import moment from "moment";
import {Typography} from "@mui/material";

const WishlistDate = ({date, variant, color}) => {
  const updated = moment(Date.parse(date)).format('MMM Do, YYYY');

  return (
      <Typography variant={variant ? variant : 'body1'}
                  color={color ? color : 'textPrimary'}>
        Last Updated: {updated}
      </Typography>
  );
}

export default WishlistDate;