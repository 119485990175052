import React from 'react';
import SharedWithItem from './SharedWithItem';
import {useDispatch} from 'react-redux';
import {
  deleteWishlistLinkAction, updateLinkPermissionAction
} from '../../actions/LinkedWishlistActions';
import {useSnackbar} from 'notistack';



const SharedWithList = ({wishlistId, selectedItems, setSelectedItems, linkUser}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const manage = linkUser.permission === 'ADMIN' || linkUser.permission === 'SHARE';

  const handleChange = (e, item) => {
    const newItem = {...item, permission: e.target.value}
    const newItems = [...selectedItems];
    for (let i = 0; i < newItems.length; i++) {
      if (newItems[i].friend.id === item.friend.id) {
        newItems[i] = newItem;
        break;
      }
    }

    dispatch(updateLinkPermissionAction(item.linkId, { wishlistId, permission: newItem.permission})).then(() => {
      enqueueSnackbar("Successfully changed permission", {variant: 'success'});
      setSelectedItems(newItems);
    }).catch(error => {
      enqueueSnackbar("Failed to change permission", {variant: 'error'});
    });
  }

  const handleDelete = (item) => {
    dispatch(deleteWishlistLinkAction(item.linkId)).then(json => {
      enqueueSnackbar("Successfully removes share", {variant: 'success'});
      setSelectedItems(selectedItems.filter(it => it.linkId !== item.linkId));
    }).catch(e => {
      enqueueSnackbar("Failed to remove share", {variant: 'error'});
    });
  }

  return (
      <React.Fragment>
        {selectedItems.map((item, sIdx) =>
            <SharedWithItem key={sIdx}
                            item={item}
                            manage={manage}
                            onChange={handleChange}
                            onDelete={handleDelete} />
        )}
      </React.Fragment>
  )
}

export default SharedWithList;