import FetchWrapper from "./FetchWrapper";
import {SERVER} from "../constants/ServiceConstants";


const getCommentsByWishlistItem = (wishlistId, id) => {
  return FetchWrapper(`/wishlists/${wishlistId}/items/${id}/comments`);
};

const addComment = (payload) => {
  return FetchWrapper(`/comments`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const updateComment = (payload) => {
  return FetchWrapper(`/comments/${payload.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const deleteComment = (id) => {
  return FetchWrapper(`/comments/${id}`, {
    method: 'DELETE',
  });
};

export { getCommentsByWishlistItem, addComment, updateComment, deleteComment }