import React from 'react';
import {CircularProgress} from '@mui/material';

const LoadingComponent = ({loading, children}) => {

    return loading ? (
        <div style={{
            margin: '40px auto',
            width: 60}}>
            <CircularProgress />
        </div>
    ) : children;
}

export default LoadingComponent;
