import React from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button";
import {FormControl, Grid, MenuItem} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import InputHelpTooltip from '../../utils/InputHelpTooltip';
import {StyledForm, StyledUL} from "./styles";

const WishlistForm = ({item, handleSubmit, handleClose}) => {
  const [newItem, setNewItem] = React.useState(item);

  if (!newItem.visibility) {
    setNewItem({...newItem, visibility: 'Friends'});
  }

  const handleChange = (e) => {
    let copy = {...newItem};
    if (e.target.name) {
      copy[e.target.name] = e.target.value;
    } else {
      copy[e.target.id] = e.target.value;
    }
    setNewItem(copy)
  };

  return (
    <StyledForm onSubmit={(e) => handleSubmit(e, newItem)}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h6">
            {newItem.add ? "Add" : "Update"} Wishlist
          </Typography>
        </Grid>
        <Grid item container spacing={1} alignItems="flex-end">
          <Grid item xs marginTop={1}>
            <TextField id="name" label="Name" autoComplete="off"
                       variant="standard"
                       autoFocus={true}
                       fullWidth={true}
                       value={newItem.name} onChange={handleChange} required />
          </Grid>
          <Grid item>
            <InputHelpTooltip align="flex-end"
              Node={
                <Typography variant="caption">
                  <StyledUL>
                    <li>Private: Only visible to you unless
                      you share
                    </li>
                    <li>Friends: Friends can browse and
                      follow
                    </li>
                    <li>Public: Anyone can search and follow
                    </li>
                  </StyledUL>
                </Typography>
              }>
              <FormControl fullWidth={true} variant="standard">
                <InputLabel id="visibility-label">Visibility</InputLabel>
                <Select
                    labelId={'visibility-label'}
                    fullWidth={true}
                    value={newItem.visibility}
                    variant="standard"
                    onChange={handleChange}
                    sx={{ width: '80px' }}
                    inputProps={{
                      name: 'visibility',
                      id: 'visibility',
                    }}
                >
                  <MenuItem value={'Friends'}>Friends</MenuItem>
                  <MenuItem value={'Private'}>Private</MenuItem>
                  <MenuItem value={'Public'}>Public</MenuItem>
                </Select>
              </FormControl>
            </InputHelpTooltip>

          </Grid>
        </Grid>
        <Grid container item marginTop={2} spacing={1}
              justifyContent="flex-end">
          <Grid item>
            <Button variant={"text"} onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained"
                    color="primary">
              {newItem.add ? "Add" : "Update"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </StyledForm>
  );
}

export default WishlistForm;