import React, {useState} from "react";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import {setTitle} from "../slices/NavigatorSlice";
import {
  resetPasswordAction
} from "../actions/UserActions";
import {parse} from 'query-string';
import {StyledPageButtonGroup, StyledPagePaper, StyledSubmitButton, StyledTextField} from './styles';
import {useSnackbar} from 'notistack';


function ResetPage({ location }) {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [ form, setForm ] = useState({ password: "", confirmPassword: ""});
  const params = parse(location.search);

  React.useEffect(() => {
    dispatch(setTitle("Reset Password"));
  }, [location]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!params.resetToken) {
      enqueueSnackbar("No reset token present", {variant: 'error'});
      return false;
    } else if (form.password !== form.confirmPassword) {
      enqueueSnackbar("Passwords do not match", {variant: 'error'});
      return false;
    }

    dispatch(resetPasswordAction({ newPassword: form.password, resetToken: params.resetToken })).then(json => {
      enqueueSnackbar("Password reset. Please login again", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'});
    });
  };

  const handleChange = (e) => {
    let newItem = { ...form };
    newItem[e.target.id] = e.target.value;
    setForm(newItem);
  };

  return (
      <StyledPagePaper>
        <form onSubmit={handleSubmit}>
          <Typography variant="h5">
            Reset Password
          </Typography>
          <StyledTextField id="password" label="Password" type="password" fullWidth value={form.password} onChange={handleChange} required variant="standard" />
          <StyledTextField id="confirmPassword" label="Confirm Password" type="password" fullWidth value={form.confirmPassword} onChange={handleChange} required variant="standard" />
          <StyledPageButtonGroup>
            <StyledSubmitButton type="submit" variant="contained" color="primary">
              Reset Password
            </StyledSubmitButton>
          </StyledPageButtonGroup>
        </form>
      </StyledPagePaper>
  );
}

export default ResetPage;
