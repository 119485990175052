import {createSlice} from "@reduxjs/toolkit";
import {COMMENT_STATE} from "../constants/StateConstants";

const slice = createSlice({
  name: "SharedItems",
  initialState: {
    selectedSharedItem: COMMENT_STATE.DEFAULT,
  },
  reducers: {
    setSelectedSharedItem: (state, action) => { state.selectedSharedItem = action.payload },
  }
});

export const { setSelectedSharedItem } = slice.actions;

export default slice.reducer