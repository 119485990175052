import {
  Grid,
  Paper,
  useMediaQuery,
  useTheme
} from "@mui/material";
import React from "react";
import MobileResult from './MobileResult';
import DesktopResult from './DesktopResult';


const SearchResult = ({result, onSelect}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [elevation, setElevation] = React.useState(1);

  const onClickTitle = (e) => {
    e.stopPropagation();
  }

  return (
      <Grid item>
        <Paper
            onClick={(e) => onSelect(e, result)}
            elevation={elevation}
            onMouseOver={() => setElevation(4)}
            onMouseOut={() => setElevation(1)}
            sx={{ cursor: 'pointer' }}>
          {matches ? (
              <DesktopResult result={result} onClickTitle={onClickTitle} />
          ) : (
              <MobileResult result={result} onClickTitle={onClickTitle} />
          )}

        </Paper>
      </Grid>
  )
}

export default SearchResult;
