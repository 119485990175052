import React, {useState} from "react";
import ButtonPopper from "../../utils/ButtonPopper";
import {List} from "@mui/material";
import Paper from "@mui/material/Paper";
import ListIconDense from "../../utils/ListIconDense";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import DeleteAction from "../../buttons/DeleteAction";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from "@mui/icons-material/Check";

const FriendRequestButton = ({item, onVerify, onDelete}) => {
  const [popper, setPopper] = useState(null);

  const handleDelete = (e) => {
    e.stopPropagation();

    setPopper(null);
    onDelete();
  };

  const handleVerify = (e) => {
    setPopper(null);
    onVerify(e);
  }

  return (
      <ButtonPopper padding="4px" {...{popper, setPopper}}>
        <Paper>
          <List dense>
            {item.verifyToken && (
                <ListItem button onClick={handleVerify}>
                  <ListIconDense>
                    <CheckIcon/>
                  </ListIconDense>
                  <ListItemText primary="Accept"/>
                </ListItem>
            )}
            <DeleteAction
                handleDelete={handleDelete}
                item={item}
                name={item.lastName ? `${item.firstName} ${item.lastName}`
                    : item.email} >
              <ListItem button>
                <ListIconDense>
                  <DeleteIcon />
                </ListIconDense>
                <ListItemText primary={ item.verifyToken ? "Ignore" : "Delete" } />
              </ListItem>
            </DeleteAction>
          </List>
        </Paper>
      </ButtonPopper>
  )
}

export default FriendRequestButton;