import {List} from "@mui/material";
import React, {useState} from "react";
import ListItem from "@mui/material/ListItem";
import EditIcon from "@mui/icons-material/Edit";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import DeleteAction from "../../buttons/DeleteAction";
import {deleteWishlistAction} from "../../../actions/WishlistActions";
import ButtonPopper from "../../utils/ButtonPopper";
import ShareIcon from "@mui/icons-material/Share";
import {deleteLinkOnWishlistAction,} from "../../../actions/LinkedWishlistActions";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ListIconDense from "../../utils/ListIconDense";
import {useHistory} from "react-router-dom";
import useWishlistUtils from '../../../util/useWishlistUtils';

const WishlistButtons = ({item, type, permission, onEdit}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const wishlistUtil = useWishlistUtils();
  const [popper, setPopper] = useState(null);
  const user = useSelector(state => state.user);

  const handleShare = (e) => {
    e.stopPropagation();

    history.push(`/lists/${item.id}/share`)
  }

  const handleEdit = e => {
    e.stopPropagation();

    setPopper(null);
    onEdit();
  };

  const handleDelete = (e) => {
    e.stopPropagation();

    if (user.id === item.user.id) {
      dispatch(deleteWishlistAction(item.id))
      .then(() => {
        enqueueSnackbar("Deleted wishlist", {variant: 'success'});
        wishlistUtil.getWishlistsByType('OWNER');
      }).catch(error => {
        enqueueSnackbar(error, {variant: 'error'})
      });
    } else {
      dispatch(deleteLinkOnWishlistAction(item.id)).then(json => {
        enqueueSnackbar("Unsubscribed from wishlist", {variant: 'success'});
        wishlistUtil.getWishlistsByType("CONTRIBUTOR");
      }).catch(error => {
        enqueueSnackbar(error, {variant: 'error'})
      });
    }
  };

  return (
      <ButtonPopper {...{popper, setPopper}} style={{ marginRight: '-10px' }}>
        <Paper>
          <List dense>
            {(permission === 'SHARE' || permission === 'ADMIN') && (
                <ListItem button onClick={handleShare}>
                  <ListIconDense>
                    <ShareIcon/>
                  </ListIconDense>
                  <ListItemText primary="Share"/>
                </ListItem>
            )}
            {(permission === 'EDIT' || permission === 'ADMIN') && (
                <ListItem button onClick={handleEdit}>
                  <ListIconDense>
                    <EditIcon/>
                  </ListIconDense>
                  <ListItemText primary="Edit"/>
                </ListItem>
            )}
            <DeleteAction
                handleDelete={handleDelete}
                name={item.name}
                item={item}>
              <ListItem button>
                <ListIconDense>
                  {type === 'OWNER' ? <DeleteIcon/> : <HighlightOffIcon/>}
                </ListIconDense>
                <ListItemText
                    primary={type === 'OWNER' ? "Delete" : "Unfollow"}/>
              </ListItem>
            </DeleteAction>
          </List>
        </Paper>
      </ButtonPopper>
  )
}

export default WishlistButtons;