import {useDispatch} from 'react-redux';
import {getLinkedWishlistsByTypeAction} from '../actions/LinkedWishlistActions';
import {useSnackbar} from 'notistack';


const useWishlistUtils = () => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const getWishlistsByType = (type) => {
    dispatch(getLinkedWishlistsByTypeAction(type)).catch(error => {
      enqueueSnackbar(error, {variant: 'error'});
    });
  }

  return {
    getWishlistsByType
  }
}

export default useWishlistUtils;