import {getUserItems, addUserItem, updateUserItem, deleteUserItem} from "../api/UserItemApi";
import {setUserItems, setUserItemSearch} from "../slices/UserItemsSlice";

const getUserItemsAction = () => async dispatch => {
  return await getUserItems().then(json => {
    dispatch(setUserItems(json));
    dispatch(setUserItemSearch(""));

    return json;
  }).catch(error => {
    console.log(error);
  });
};

const addUserItemAction = (payload) => async dispatch => {
  return await addUserItem(payload).then(json => {
    dispatch(getUserItemsAction());

    return json;
  });
};

const updateUserItemAction = (payload) => async dispatch => {
  return await updateUserItem(payload).then(json => {
    dispatch(getUserItemsAction());

    return json;
  });
};

const deleteUserItemAction = (id) => async dispatch => {
  return await deleteUserItem(id).then(json => {
    dispatch(getUserItemsAction());

    return json;
  });
};

export { getUserItemsAction, addUserItemAction, updateUserItemAction, deleteUserItemAction };