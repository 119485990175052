import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {unsubscribeAllAction} from "../actions/EmailActions";
import {StyledPagePaper} from './styles';
import {Box} from '@mui/material';


const UnsubscribeAllPage = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user.id);
  const email = useSelector(state => state.user.details.email);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  React.useEffect(() => {
    if (userId) {
      dispatch(unsubscribeAllAction(userId)).then(json => {
        setSuccessMessage(
            `Successfully unsubscribed ${email} from emails`);
      }).catch(error => {
        console.log(error);
        setError(error);
      });
    }
  }, [userId]);

  return (
      <StyledPagePaper>
        <Box margin={2} textAlign="center">
          <Typography color="textPrimary" variant="subtitle1" >Successfully Unsubscribed josiah@jtrumpower.com from emails</Typography>
          { successMessage ? <Typography color="textPrimary" variant="subtitle1" >{successMessage}</Typography> : null }
          { error ? <Typography color="error" variant="subtitle1" sx={{ margin: '5px 0 5px 0' }}>{error}</Typography> : null }
        </Box>
      </StyledPagePaper>
  )
}

export default UnsubscribeAllPage;
