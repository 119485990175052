import React, {useState} from 'react';
import {Grid, List, ListItem, TextField, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Paper from '@mui/material/Paper';
import ShareWithHelp from './ShareWithHelp';
import ListIconDense from '../utils/ListIconDense';
import ListItemText from '@mui/material/ListItemText';
import ButtonPopper from '../utils/ButtonPopper';
import {FileCopy} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import EditIcon from "@mui/icons-material/Edit";
import {useDispatch} from "react-redux";
import {
  deleteShareLinkAction,
  updateShareLinkAction
} from "../../actions/WishlistActions";
import {updateLinkPermissionAction} from '../../actions/LinkedWishlistActions';

const ShareWithLink = ({item, manage, onDelete}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [popper, setPopper] = useState(null);
  const [edit, setEdit] = useState(false);
  const [link, setLink] = useState(item.link);

  const onPermissionChange = (e, pItem) => {
    const newItem = {...pItem, permission: e.target.value}
    dispatch(
        updateLinkPermissionAction(newItem.linkId,
            {
              wishlistId: item.wishlistId,
              permission: newItem.permission
            })).then(() => {
      setLink(newItem);

      enqueueSnackbar("Updated permission", {variant: 'success'});
    }).catch(error => {
      enqueueSnackbar("Error deleting link", {variant: 'error'});
    });
  }

  const onChange = (e) => {
    const newLink = {...link};
    newLink.friend.firstName = e.target.value;

    setLink(newLink);
  }

  const handleChangeName = () => {
    setEdit(true);
    setPopper(null);
  }

  const handleDelete = () => {
    dispatch(deleteShareLinkAction(item.wishlistId, item.shareId)).then(() => {
      enqueueSnackbar("Deleted share link", {variant: 'success'});

      setPopper(null);

      onDelete();
    }).catch(error => {
      enqueueSnackbar("Error deleting link", {variant: 'error'});
    });
  }

  const handleSave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(updateShareLinkAction(item.wishlistId, item.shareId, link)).then(
        () => {
          enqueueSnackbar("Updated name", {variant: 'success'})

          setEdit(false);
        }).catch(error => {
      enqueueSnackbar("Error updating name", {variant: 'error'});
    });
  }

  const handleCopyLink = () => {
    navigator.clipboard
    .writeText(item.shareUrl)
    .then(() => enqueueSnackbar("Copied share link to clipboard",
            {variant: 'success'}),
        () => enqueueSnackbar("Error copying to clipboard",
            {variant: 'error'}));

    setPopper(null);
  }

  return (
      <Paper sx={{
        marginTop: theme.spacing(1),
        padding: theme.spacing(1)
      }}>
        <form onSubmit={handleSave}>
          <Grid container alignItems='center'>
            <Grid item xs paddingLeft="5px" paddingRight="5px">
              {!edit ? (
                  <Typography align='left'>
                    {link.friend.firstName}
                  </Typography>
              ) : (
                  <TextField fullWidth
                             variant="standard"
                             autoFocus
                             value={link.friend.firstName}
                             onChange={onChange}/>
              )}
            </Grid>
            {manage && (
                <React.Fragment>
                  <Grid item>
                    <ShareWithHelp item={link}
                                   onChange={onPermissionChange}/>
                  </Grid>
                  <Grid item xs={1}>
                    <ButtonPopper {...{popper, setPopper}}>
                      <Paper>
                        <List dense>
                          <ListItem button onClick={handleCopyLink}>
                            <ListIconDense>
                              <FileCopy/>
                            </ListIconDense>
                            <ListItemText primary="Copy Link"/>
                          </ListItem>
                          <ListItem button onClick={handleChangeName}>
                            <ListIconDense>
                              <EditIcon/>
                            </ListIconDense>
                            <ListItemText primary={"Change Name"}/>
                          </ListItem>
                          <ListItem button onClick={handleDelete}>
                            <ListIconDense>
                              <CancelRoundedIcon/>
                            </ListIconDense>
                            <ListItemText primary="Delete"/>
                          </ListItem>
                        </List>
                      </Paper>
                    </ButtonPopper>
                  </Grid>
                </React.Fragment>
            )}
          </Grid>
          <button type="submit" hidden={true}/>
        </form>
      </Paper>
  )
}

export default ShareWithLink;
