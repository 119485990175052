import React from "react";
import useFilteredResults from "../../hooks/useFilteredResults";
import {useSelector} from "react-redux";
import WishlistItemPrioritySection from "./WishlistItemPrioritySection";
import WishlistItemActionSection from "./WishlistItemActionSection";
import WishlistItemsEmpty from '../../../pages/Empty/WishlistItemsEmpty';
import {Priority} from './styles';


function WishlistItemList() {
  const wishlistItemsSearch = useSelector(
      state => state.wishlistItems.wishlistItemsSearch);
  const wishlistItems = useSelector(state => state.wishlistItems.wishlistItems);
  const items = useFilteredResults(wishlistItems, wishlistItemsSearch);
  const highPriority = items.filter(
      (item) => item.wishlistItem.priority === 'High');
  const normalPriority = items.filter(
      (item) => item.wishlistItem.priority === 'Normal');
  const lowPriority = items.filter(
      (item) => item.wishlistItem.priority === 'Low');

  return (
      <React.Fragment>
        <WishlistItemActionSection/>
        {wishlistItems.length > 0 ? (
            <React.Fragment>
              {highPriority.length > 0 &&
              <Priority>
                <WishlistItemPrioritySection
                    items={highPriority}
                    priority={normalPriority.length || lowPriority.length
                        ? 'High'
                        : ''}/>
              </Priority>}
              {normalPriority.length > 0 &&
              <Priority>
                <WishlistItemPrioritySection
                    items={normalPriority}
                    priority={highPriority.length || lowPriority.length
                        ? 'Normal'
                        : ''}/>
              </Priority>}
              {lowPriority.length > 0 &&
              <Priority>
                <WishlistItemPrioritySection
                    items={lowPriority}
                    priority={highPriority.length || normalPriority.length
                        ? 'Low'
                        : ''}/>
              </Priority>}
            </React.Fragment>
        ) : <WishlistItemsEmpty/>}
      </React.Fragment>
  )
}

export default WishlistItemList;
