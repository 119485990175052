import React from "react";
import {Route, Switch} from "react-router-dom";
import ProfilePage from "../pages/ProfilePage";
import MyLibraryPage from "../pages/MyLibraryPage";
import WishlistsPage from "../pages/WishlistsPage";
import HomePage from "../pages/HomePage";
import PrivateRoute from "./PrivateRoute";
import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import WishlistItemsPage from "../pages/WishlistItemsPage";
import WishlistItemDetailPage from "../pages/WishlistsItemDetailPage";
import FriendsPage from "../pages/FriendsPage";
import ForgotPage from "../pages/ForgotPage";
import ResetPage from "../pages/ResetPage";
import VerifyFriendPage from "../pages/VerifyFriendPage";
import UnsubscribeAllPage from "../pages/UnsubscribeAllPage";
import SharedWithPage from "../pages/SharedWithPage";
import BrowseFriendListsPage from '../pages/Browse/BrowseFriendListsPage';
import BrowsePublicListsPage from '../pages/Browse/BrowsePublicListsPage';

function AppRoutes({refreshing}) {

  const RefreshableRoute = ({...props}) => <PrivateRoute
      refreshing={refreshing} {...props} />;

  return (
      <Switch>
        <RefreshableRoute path="/profile" exact component={ProfilePage}/>
        <RefreshableRoute path="/items" exact component={MyLibraryPage}/>
        <RefreshableRoute path="/lists" exact component={WishlistsPage}/>
        <RefreshableRoute path="/shared" exact allowShared component={WishlistsPage} sharedPage={true}/>
        <RefreshableRoute path="/lists/:wishlistId" exact allowShared
               component={WishlistItemsPage}/>
        <RefreshableRoute path="/lists/:wishlistId/share" exact allowShared
                          component={SharedWithPage}/>
        <RefreshableRoute path="/lists/:wishlistId/items/:itemId" exact allowShared
               component={WishlistItemDetailPage}/>
        <RefreshableRoute path="/friends" exact component={FriendsPage}/>
        <RefreshableRoute path="/friends/verify" exact
                          component={VerifyFriendPage}/>
        <RefreshableRoute path="/browse/lists/friends" exact
                          component={BrowseFriendListsPage}/>
        <RefreshableRoute path="/browse/lists/public" exact
                          component={BrowsePublicListsPage}/>
        <Route path="/reset-password" exact component={ResetPage}/>
        <Route path="/forgot-password" exact component={ForgotPage}/>
        <Route path="/register" exact component={RegisterPage}/>
        <Route path="/login" exact component={LoginPage}/>
        <Route path="/unsubscribe-all" exact component={UnsubscribeAllPage}/>
        <Route path="/" component={HomePage}/>
      </Switch>
  );
}

export default AppRoutes;
