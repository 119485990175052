import {
  contribute,
  deleteContribution,
  getContributionsByWishlistItemId,
  updateContribution
} from "../api/ContributeApi";
import {setContributions} from "../slices/WishlistItemDetailSlice";

const getContributionsByWishlistItemIdAction = (wishlistId, id) => async dispatch => {
  return getContributionsByWishlistItemId(wishlistId, id).then(json => {
    dispatch(setContributions(json));

    return json;
  })
};

const contributeAction = (payload) => async dispatch => {
  return await contribute(payload).then(json => {

    dispatch(getContributionsByWishlistItemIdAction(payload.wishlistId, payload.wishlistItemId));

    return json;
  });
};

const updateContributeAction = (id, payload) => async dispatch => {
  return await updateContribution(id, payload).then(json => {

    dispatch(getContributionsByWishlistItemIdAction(payload.wishlistId, payload.wishlistItemId));

    return json;
  });
};

const deleteContributionAction = (id) => async dispatch => {
  return await deleteContribution(id)
};

export { contributeAction, updateContributeAction, deleteContributionAction, getContributionsByWishlistItemIdAction }