import React from "react";
import {useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {setWishlistItems} from "../../../slices/WishlistItemsSlice";
import PaperListItem from "../PaperList/PaperListItem";
import WishlistContent from './WishlistContent';
import {Grid, Hidden, useTheme} from "@mui/material";
import WishlistDate from "../../utils/WishlistDate";
import WishlistButtons from "./WishlistButtons";

function Wishlist({item, shared, onEdit}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const wishlist = item.wishlist;

  const handleWishlistClick = () => {
    dispatch(setWishlistItems([]));
    history.push(`/lists/${wishlist.id}`)
  };

  return (
    <PaperListItem hover
                   clickable
                   onClick={handleWishlistClick}>
      <Grid container justifyContent="space-between">
        <Grid item xs>
          <WishlistContent shared={shared}
                           friend={item.friend}
                           wishlist={{
                             wishlistName: wishlist.name,
                             firstName: wishlist.user.firstName,
                             lastName: wishlist.user.lastName,
                             ...wishlist
                           }}/>
        </Grid>
        <Hidden mdDown>
          <Grid item>
            <WishlistDate date={wishlist.updated} variant={"subtitle1"}/>
          </Grid>
        </Hidden>
        <Grid item marginTop="-6px">
          <WishlistButtons item={wishlist}
                           type={item.type}
                           permission={item.permission}
                           onEdit={onEdit}/>
        </Grid>
      </Grid>
    </PaperListItem>
  );
}

export default Wishlist;