import React, {useState} from "react";
import {List} from '@mui/material';
import ButtonPopper from "../utils/ButtonPopper";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListIconDense from "../utils/ListIconDense";
import ListItemText from "@mui/material/ListItemText";

function TableAppBarActions({ items }) {
  const [popper, setPopper] = useState(null);

  const clicked = (e, action) => {
    setPopper(null);

    action(e);
  }

  return (
      <ButtonPopper padding="4px" {...{popper, setPopper}}>
        <Paper>
          <List dense>
            {items.map((item) => (
                <ListItem button onClick={e => clicked(e, item.action)}>
                  <ListIconDense>
                    {item.icon}
                  </ListIconDense>
                  <ListItemText primary={item.text} />
                </ListItem>
            ))}
          </List>
        </Paper>
      </ButtonPopper>
  );
}

export default TableAppBarActions;
