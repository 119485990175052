import React from "react";
import PaperListPage from "../../components/items/PaperList/PaperListPage";
import {setFriendListSearch} from "../../slices/BrowseSlice";
import BrowseFriendWishlistsList
  from "../../components/items/BrowseWishlist/BrowseFriendWishlistsList";

function BrowseFriendListsPage() {

  return (
      <PaperListPage placeholder="Search Friends Wishlists"
                     onSearchChange={setFriendListSearch}>
        <BrowseFriendWishlistsList/>
      </PaperListPage>
  );
}

export default BrowseFriendListsPage;
