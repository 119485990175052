import React from 'react';
import {getUserItemsAction} from "../../../actions/UserItemsActions";
import UserItemCard from "../UserItem/UserItemCard";
import useFilteredResults from "../../hooks/useFilteredResults";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {reset, setUserItemLoading} from "../../../slices/UserItemsSlice";
import {Grid} from "@mui/material";
import UserItemActionSection from "./UserItemActionSection";
import {useSnackbar} from 'notistack';
import LoadingComponent from '../../utils/LoadingComponent';
import MyLibraryEmpty from '../../../pages/Empty/MyLibraryEmpty';
import {StyledUserItemListGridItem} from "./styles";

function UserItemList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {enqueueSnackbar} = useSnackbar();
  const userItems = useSelector(state => state.userItems.userItems);
  const userItemSearch = useSelector(state => state.userItems.userItemSearch);
  const loading = useSelector(state => state.userItems.loading);
  const items = useFilteredResults(userItems, userItemSearch);

  React.useEffect(() => {
    dispatch(setUserItemLoading(true));
    dispatch(reset());
    dispatch(getUserItemsAction()).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    }).finally(() => {
      dispatch(setUserItemLoading(false));
    });
  }, [location, dispatch]);

  return (
      <LoadingComponent loading={loading}>
        <UserItemActionSection/>
        {userItems.length > 0 ? (
            <Grid container spacing={2} alignItems="stretch" marginBottom="5px" marginTop="-10px">
              {items.map(item => {
                return (
                    <StyledUserItemListGridItem item key={item.id} >
                      <UserItemCard item={item}/>
                    </StyledUserItemListGridItem>)
              })}
            </Grid>
        ) : <MyLibraryEmpty/>}
      </LoadingComponent>
  )
}

export default UserItemList;
