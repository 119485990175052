import React from "react";
import UserItemList from "../components/items/UserItem/UserItemList";
import {setUserItemSearch} from "../slices/UserItemsSlice";
import UserItemSpeedDial from "../components/buttons/UserItemSpeedDial";
import PaperListPage from '../components/items/PaperList/PaperListPage';


function MyLibraryPage() {

  return (
      <PaperListPage placeholder="Search by Title, Price or Description"
                     onSearchChange={setUserItemSearch}>
        <UserItemList />
        <UserItemSpeedDial/>
      </PaperListPage>
  );
}

export default MyLibraryPage;