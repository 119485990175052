import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Grow from "@mui/material/Grow";
import {MODAL_TYPE} from "../../constants/ModalConstants";
import {setSelectedComment} from "../../slices/CommentSlice";
import {
  addCommentAction,
  updateCommentAction
} from "../../actions/CommentActions";
import {COMMENT_STATE} from "../../constants/StateConstants";
import {setCommentModal} from "../../slices/ModalSlice";
import {useSnackbar} from "notistack";
import {
  StyledButton,
  StyledButtonGroup,
  StyledForm,
  StyledModal,
  StyledPaper
} from "./styles";

function CommentModal() {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const {item} = useSelector(state => state.wishlistItemDetail);
  const {commentModal} = useSelector(state => state.modals);
  const {selectedComment} = useSelector(state => state.comment);

  const handleClose = () => {
    dispatch(setSelectedComment(COMMENT_STATE.DEFAULT));
    dispatch(setCommentModal({...commentModal, open: false,}));
  };

  const handleChange = (e) => {
    let newItem = {...selectedComment};
    newItem[e.target.id] = e.target.value;
    dispatch(setSelectedComment(newItem));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let response;
    const request = {...selectedComment};
    request.wishlistItemId = item.id;
    if (commentModal.type === MODAL_TYPE.ADD) {
      response = dispatch(addCommentAction(item.wishlistId, request));
    } else {
      response = dispatch(updateCommentAction(item.wishlistId, request));
    }

    response.then(() => {
      handleClose();
      enqueueSnackbar(commentModal.type === MODAL_TYPE.ADD ? "Added comment"
          : "Updated Comment", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  return (
      <StyledModal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={commentModal.open}
          onClose={handleClose}
          closeAfterTransition
      >
        <Grow in={commentModal.open}>
          <StyledPaper>
            <StyledForm onSubmit={handleSubmit}>
              <Typography variant="h5">
                {commentModal.type === MODAL_TYPE.ADD ? "Add"
                    : "Update"} Comment
              </Typography>
              <TextField
                  id="content"
                  variant="standard"
                  label="Comment"
                  required
                  fullWidth
                  multiline
                  autoComplete="off"
                  autoFocus={true}
                  value={selectedComment.content}
                  onChange={handleChange}
                  sx={{
                    margin: '5px 0 5px 0',
                    width: "275px"
                  }}/>

              <StyledButtonGroup>
                <StyledButton type="submit" variant="contained" color="primary">
                  {commentModal.type === MODAL_TYPE.ADD ? "Add" : "Update"} Comment
                </StyledButton>
              </StyledButtonGroup>
            </StyledForm>
          </StyledPaper>
        </Grow>
      </StyledModal>
  )
}

export default CommentModal;