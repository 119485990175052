import React from 'react';
import {Chip, Grid, Link, Typography} from '@mui/material';
import LinesEllipsis from 'react-lines-ellipsis';
import { Rating } from '@mui/material';
import currency from 'currency.js';
import {CheckIconStyled, GridItemRating, ResultGrid, ResultImage} from './styles';


const DesktopResult = ({ result, onClickTitle }) => {

  return (
      <ResultGrid container spacing={1} alignItems="center">
        <Grid item>
          <ResultImage src={result.image} alt="Amazon product image" />
        </Grid>
        <Grid container item direction="column" xs paddingLeft="6px">
          <Grid item component={Link} href={result.link} target="_blank" onClick={onClickTitle}>
            <Typography variant="h6">
              <LinesEllipsis text={result.title} />
            </Typography>
          </Grid>
          {(result.prime || result.rating > 0) && (
              <Grid item container spacing={1}>
                {result.prime && (
                    <GridItemRating item minHeight="37px">
                      <Chip label={
                        <Typography>
                          Prime
                        </Typography>}
                            size="small"
                            color="primary"
                            variant="outlined"
                            icon={<CheckIconStyled />} />
                    </GridItemRating>
                )}
                {result.rating > 0 && (
                    <React.Fragment>
                      <Grid item>
                        <Rating value={result.rating} readOnly/>
                      </Grid>
                      <Grid item>
                        <Typography>
                          ({result.numRatings})
                        </Typography>
                      </Grid>
                    </React.Fragment>
                )}
              </Grid>
          )}
          {result.price && (
              <Grid item>
                <Typography variant="subtitle1">
                  {currency(result.price.value).format()}
                </Typography>
              </Grid>
          )}
        </Grid>
      </ResultGrid>
  );
}

export default DesktopResult;
