import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "Navigator",
  initialState: {
    title: "Wishlists",
    selected: "Home",
    open: false
  },
  reducers: {
    setTitle: (state, action) => { state.title = action.payload },
    setSelected: (state, action) => { state.selected = action.payload },
    setOpen: (state, action) => { state.open = action.payload },
    toggleOpen: (state) => { state.open = !state.open }
  }
});

export const { setTitle, setSelected, setOpen, toggleOpen } = slice.actions;

export default slice.reducer;