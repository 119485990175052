import {setFetchingUserDetails, setUserDetails} from "../slices/UserSlice";
import {
  forgotPassword,
  getUserDetails,
  resetPassword,
  updateUser
} from "../api/UserApi";

const getUserDetailsAction = () => async (dispatch, getState) => {
  if (!getState().user.fetchingDetails) {
    dispatch(setFetchingUserDetails(true))

    return await getUserDetails().then(json => {
      dispatch(setUserDetails(json));
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      dispatch(setFetchingUserDetails(false));
    });
  }
};

const updateUserAction = (payload) => async dispatch => {
  return updateUser(payload).then(json => {
    dispatch(getUserDetailsAction());
  })
}

const forgotPasswordAction = (payload) => async dispatch => {
  return forgotPassword(payload);
}

const resetPasswordAction = (payload) => async dispatch => {
  return resetPassword(payload);
}

export {
  getUserDetailsAction,
  updateUserAction,
  forgotPasswordAction,
  resetPasswordAction,
};