import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import {Grid, IconButton, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Paper from '@mui/material/Paper';
import ShareWithHelp from './ShareWithHelp';


const SharedWithItem = ({ item, manage, onDelete, onChange}) => {
  const theme = useTheme();

  return (
    <Paper sx={{
      marginTop: theme.spacing(1),
      padding: theme.spacing(1)
    }}>
      <FormGroup row>
        <Grid container alignItems='center'>
          <Grid item xs paddingLeft="5px">
            <Typography align='left'>
              {item.friend.firstName} {item.friend.lastName}
            </Typography>
          </Grid>
          {manage && (
              <React.Fragment>
                <Grid item>
                  <ShareWithHelp {...{item, onChange}} />
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => onDelete(item)} size="large">
                    <CancelRoundedIcon/>
                  </IconButton>
                </Grid>
              </React.Fragment>
          )}
        </Grid>
      </FormGroup>
    </Paper>
  );
}

export default SharedWithItem;
