import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  setShowStatus,
  setWishlistItemsLoading,
  setWishlistItemsSearch
} from "../slices/WishlistItemsSlice";
import {getWishlistByIdAction} from "../actions/WishlistActions";
import {setTitle} from "../slices/NavigatorSlice";
import {getWishlistItemsByIdAction} from "../actions/WishlistItemActions";
import {getLinkByWishlistIdAction} from "../actions/LinkedWishlistActions";
import PaperListPage from "../components/items/PaperList/PaperListPage";
import {useSnackbar} from 'notistack';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import LoadingComponent from "../components/utils/LoadingComponent";
import WishlistItemList
  from "../components/items/WishlistItem/WishlistItemList";
import WishlistItemSpeedDial from "../components/buttons/WishlistItemSpeedDial";

function WishlistItemsPage(props) {
  const dispatch = useDispatch();
  const wishlistId = props.match.params.wishlistId;
  const {enqueueSnackbar} = useSnackbar();
  const loading = useSelector(state => state.wishlistItems.loading);
  const linkUser = useSelector(state => state.wishlistItems.linkUser);
  const wishlist = useSelector(state => state.wishlistItems.wishlist);
  const showStatus = useSelector(state => state.wishlistItems.showStatus);
  const user = useSelector(state => state.user);
  const owner = wishlist.user && wishlist.user.id === user.id;

  const pageLoadAction = () => dispatch => {
    dispatch(setWishlistItemsLoading(true));
    dispatch(getWishlistByIdAction(wishlistId)).then(json => {
      if (json) {
        if (owner) {
          dispatch(setTitle(json.name));
        } else {
          dispatch(setTitle(`${json.name} (${json.user.firstName})`))
        }
      }
    });

    dispatch(getLinkByWishlistIdAction(wishlistId)).catch(error => {
      console.log(error);
    });

    dispatch(getWishlistItemsByIdAction(wishlistId)).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    }).finally(() => {
      dispatch(setWishlistItemsLoading(false));
    });
  }

  React.useEffect(() => {
    dispatch(pageLoadAction());
  }, []);

  return (
      <React.Fragment>
        <PaperListPage placeholder="Search by Title, Price or Description"
                       onSearchChange={setWishlistItemsSearch}
                        menuItems={[{
                          text: "Show Claim Names",
                          action: () => dispatch(setShowStatus(!showStatus)),
                          icon: showStatus ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                        }]}>
          <LoadingComponent loading={loading}>
            <WishlistItemList/>
          </LoadingComponent>
        </PaperListPage>

        {linkUser.permission !== 'DEFAULT' && (
            <WishlistItemSpeedDial wishlistId={wishlistId}/>
        )}
      </React.Fragment>
  );
}

export default WishlistItemsPage;