import FetchWrapper from "./FetchWrapper";

const unsubscribeAll = (userId) => {
  return FetchWrapper(`/users/${userId}/preferences/email/unsubscribe-all`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
}

const subscribe = (userId, payload) => {
  return FetchWrapper(`/users/${userId}/preferences/email/subscribe`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
}

const getSubscriptions = (userId) => {
  return FetchWrapper(`/users/${userId}/preferences/email`);
}

export { unsubscribeAll, subscribe, getSubscriptions }