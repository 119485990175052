import React from 'react';
import {connect, useSelector} from "react-redux";
import HomeCard from "../components/HomeCard";
import {cards} from "./config/HomeConfig";
import {Grid} from '@mui/material';
import {showSignedIn} from '../util/UserUtils';
import {HomePageGridStyled} from './styles';

function Home() {
  const isSignedIn = useSelector(state => state.user.loggedIn);

  return (
      <Grid container maxWidth="936px" margin="auto">
        {cards.map((card, index) => (showSignedIn(isSignedIn, card.signedIn)) && (
            <HomePageGridStyled item xs>
              <HomeCard key={index} card={card} />
            </HomePageGridStyled>
        ))}
      </Grid>
  )
}

export default connect()(Home);
