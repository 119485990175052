import React from 'react';
import Typography from '@mui/material/Typography';
import {FormControl} from '@mui/material';
import Select from '@mui/material/Select';
import {permissions} from '../../pages/config/SharedListConfig';
import MenuItem from '@mui/material/MenuItem';
import InputHelpTooltip from '../utils/InputHelpTooltip';
import {StyledTooltipUl} from './styles';

const ShareWithHelp = ({ item, onChange}) => {

  return (
      <InputHelpTooltip Node={
        <Typography variant="caption" component="div">
          <StyledTooltipUl>
            <li>Default: User can collaborate</li>
            <li>Edit: User can edit and remove items</li>
            <li>Share: User can share with others</li>
            <li>Admin: Edit and Share</li>
          </StyledTooltipUl>
        </Typography>}>
        <FormControl variant="standard">
          <Select
              sx={{ width: 80 }}
              id="permission"
              value={item.permission}
              onChange={(e) => onChange(e, item)}
          >
            {permissions.map(permission =>
                <MenuItem key={permission.value}
                          value={permission.value}>{permission.label}</MenuItem>
            )}
          </Select>
        </FormControl>
      </InputHelpTooltip>
  )
}

export default ShareWithHelp;
