import {List} from "@mui/material";
import React, {useState} from "react";
import ListItem from "@mui/material/ListItem";
import EditIcon from "@mui/icons-material/Edit";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import {deleteWishlistItemAction} from "../../../actions/WishlistItemActions";
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import DeleteAction from "../../buttons/DeleteAction";
import ButtonPopper from "../../utils/ButtonPopper";
import ListIconDense from "../../utils/ListIconDense";

const WishlistItemButtons = ({toggleEdit, item}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [popper, setPopper] = useState(null);

  const handleEdit = e => {
    e.stopPropagation();

    setPopper(null);
    toggleEdit();
  };

  const handleDelete = (e) => {
    e.stopPropagation();

    setPopper(null);
    dispatch(deleteWishlistItemAction(item.wishlistId, item.id)).then(() => {
      enqueueSnackbar("Deleted wishlist item", {variant: 'success'})
    }).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    });
  };

  return (
      <ButtonPopper {...{popper, setPopper}}>
        <Paper>
          <List dense>
            <ListItem button onClick={handleEdit}>
              <ListIconDense>
                <EditIcon/>
              </ListIconDense>
              <ListItemText primary="Edit"/>
            </ListItem>
            <DeleteAction
                handleDelete={handleDelete}
                name={item.title}
                item={item}>
              <ListItem button>
                <ListIconDense>
                  <DeleteIcon/>
                </ListIconDense>
                <ListItemText primary="Delete"/>
              </ListItem>
            </DeleteAction>
          </List>
        </Paper>
      </ButtonPopper>
  )
}

export default WishlistItemButtons;