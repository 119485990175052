import {
  linkUserItems,
  deleteWishlistItem,
  getWishlistItemsById, addWishlistItem, updateWishlistItem
} from "../api/WishlistItemsApi";
import {
  setWishlistItems,
  setWishlistItemsSearch
} from "../slices/WishlistItemsSlice";

const getWishlistItemsByIdAction = (id) => async dispatch => {
  return await getWishlistItemsById(id).then(json => {
    dispatch(setWishlistItems(json));
    dispatch(setWishlistItemsSearch(''));

    return json;
  });
};

const addWishlistItemAction = (wishlistId, payload) => async dispatch => {
  return await addWishlistItem(wishlistId, payload).then(json => {
    dispatch(getWishlistItemsByIdAction(wishlistId));
  });
};

const linkUserItemsAction = (wishlistId, payload) => async dispatch => {
  return await linkUserItems(wishlistId, payload).then(json => {
    dispatch(getWishlistItemsByIdAction(wishlistId));

    return json;
  });
};

const updateWishlistItemAction = (wishlistId, payload) => async dispatch => {
  return await updateWishlistItem(wishlistId, payload).then(json => {
    dispatch(getWishlistItemsByIdAction(wishlistId));
  });
};

const deleteWishlistItemAction = (wishlistId, id) => async dispatch => {
  return await deleteWishlistItem(wishlistId, id).then(json => {
    dispatch(getWishlistItemsByIdAction(wishlistId));

    return json;
  });
};

export { getWishlistItemsByIdAction, addWishlistItemAction, linkUserItemsAction, updateWishlistItemAction, deleteWishlistItemAction };