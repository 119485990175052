import React from "react";
import {LinearProgress} from "@mui/material";
import styled from '@emotion/styled';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200
        : 700],
  },
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  }
}));

const ProgressBar = (props) => {

  return (
      <StyledLinearProgress variant="determinate" {...props} />
  )

}

export default ProgressBar;
