import FetchWrapper from "./FetchWrapper";
import {SERVER} from "../constants/ServiceConstants";

const getUserItems = () => {
  return FetchWrapper(`/users/items`);
};

const addUserItem = (payload) => {
  return FetchWrapper(`/users/items`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const updateUserItem = (payload) => {
  return FetchWrapper(`/users/items/${payload.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });
};

const deleteUserItem = (id) => {
  return FetchWrapper(`/users/items/${id}`, {
    method: 'DELETE'
  });
};


export {
  getUserItems, addUserItem, updateUserItem, deleteUserItem
}