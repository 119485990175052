import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Grow from "@mui/material/Grow";
import List from "@mui/material/List";
import Contribution from "../Contribution";
import {
  contributeAction,
  updateContributeAction
} from "../../actions/ContributionActions";
import currency from 'currency.js';
import {useSnackbar} from "notistack";
import Grid from "@mui/material/Grid";
import {Box} from "@mui/material";
import ProgressBar from "../ProgressBar";
import {StyledButton, StyledContributeWrapperPaper} from "./styles";

function ContributeModal({wishlistId, itemId, contributeModal, item, contributions}) {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const userId = useSelector(state => state.user.id);
  const [amount, setAmount] = useState(0);
  const contribList = contributions && contributions.contributions ? contributions.contributions : [];
  const userContrib = contribList.find(contrib => contrib.user.id === userId);
  const contributedAmount = contribList.map(c => c.amount).reduce(
      (accumulator, currentValue) => accumulator + currentValue, 0);
  const remaining = contributedAmount > item.price ? 0 : item.price - contributedAmount;

  React.useEffect(() => {
    if (userContrib) {
      setAmount(userContrib.amount);
    }
  }, [userContrib]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!userContrib) {
      dispatch(contributeAction({
        amount: amount,
        wishlistItemId: itemId,
        wishlistId: wishlistId
      }))
      .then(json => {
        enqueueSnackbar("Contributed to item", {variant: 'success'})
      }).catch(error => {
        enqueueSnackbar(error, {variant: 'error'})
      });
    } else {
      dispatch(updateContributeAction(userContrib.id, {
        ...userContrib,
        amount: amount,
        wishlistId: wishlistId
      }))
      .then(json => {
        enqueueSnackbar("Contribution updated", {variant: 'success'})
      }).catch(error => {
        enqueueSnackbar(error, {variant: 'error'})
      });
    }
  };

  const priceProps = {
    step: 0.50,
    min: 0
  };

  return (
      <Grow in={contributeModal.open}>
        <form onSubmit={handleSubmit}>
          <StyledContributeWrapperPaper>
            <Typography variant="h5" align="center">
              Contribute
            </Typography>
            <Grid container direction="column" spacing={2} paddingTop={1}>
              {contribList && (
                  <React.Fragment>
                    <Grid item>
                      <Typography marginBottom={1}>
                        Contributors:
                      </Typography>
                      <List dense disablePadding>
                        {contribList.map(contribution =>
                            <Contribution key={contribution.id}
                                          contribution={contribution}/>
                        )}
                      </List>
                    </Grid>
                    <Grid item>
                      <Box display="flex" justifyContent="space-between">
                        <Box paddingBottom={1}>
                          <Typography variant="caption">
                            contributed
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption">
                            goal
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Box>
                          {currency(contributedAmount).format()}
                        </Box>
                        <Box width="100%" mx={1}>
                          <ProgressBar value={contributedAmount >= item.price ? 100 : Math.round((contributedAmount/item.price) * 100) } />
                        </Box>
                        <Box>
                          {currency(item.price).format()}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography>
                        Remaining: {currency(remaining).format()}
                      </Typography>
                    </Grid>
                  </React.Fragment>
              )}
              <Grid item>
                <Box display="flex" alignItems="flex-end">
                  <Box width="100%" marginRight={2}>
                    <TextField
                        id="amount"
                        label="Contribution"
                        variant="filled"
                        autoComplete="off"
                        fullWidth
                        type="number"
                        inputProps={priceProps}
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        sx={{ margin: '5px 0 5px 0' }}/>
                  </Box>
                  <Box>
                    <StyledButton type="submit" variant="contained" color="primary">
                      {userContrib ? 'Update' : 'Contribute' }
                    </StyledButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </StyledContributeWrapperPaper>
        </form>
      </Grow>
  )
}

export default ContributeModal;