import React from "react";
import useFilteredResults from "../../hooks/useFilteredResults";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {getLinkedWishlistsByTypeAction} from "../../../actions/LinkedWishlistActions";
import {reset} from "../../../slices/LinkedWishlistSlice";
import WishlistSwitch from "./WishlistSwitch";
import AddWishlist from "./AddWishlist";
import {Collapse} from "@mui/material";
import {setWishlistLoading} from '../../../slices/WishlistsSlice';
import {useSnackbar} from 'notistack';
import LoadingComponent from '../../utils/LoadingComponent';
import WishlistsEmpty from '../../../pages/Empty/WishlistsEmpty';
import SharedWishlistsEmpty from '../../../pages/Empty/SharedWishlistsEmpty';

function WishlistList({shared}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const {linkedWishlists} = useSelector(state => state.linkedWishlists);
  const wishlistSearch = useSelector(state => state.wishlists.wishlistSearch);
  const addItem = useSelector(state => state.wishlists.addItem);
  const loading = useSelector(state => state.wishlists.loading);
  const {enqueueSnackbar} = useSnackbar();
  const items = useFilteredResults(linkedWishlists, wishlistSearch);
  const wishlistUserType = shared ? 'CONTRIBUTOR' : 'OWNER';

  React.useEffect(() => {
    dispatch(setWishlistLoading(true));
    dispatch(reset())
    dispatch(getLinkedWishlistsByTypeAction(wishlistUserType)).catch(error => {
      enqueueSnackbar(error, {variant: 'error'})
    }).finally(() => {
      dispatch(setWishlistLoading(false));
    })
  }, [location, dispatch, shared]);

  const getEmpty = () => {
    if (!addItem.add) {
      return shared ? <WishlistsEmpty/> : <SharedWishlistsEmpty/>
    }
  }

  return (
      <LoadingComponent loading={loading}>
        <Collapse in={addItem.add} mountOnEnter unmountOnExit
                  sx={addItem.add && { marginBottom: 1 }}>
          <AddWishlist item={addItem}/>
        </Collapse>
        {linkedWishlists.length > 0 ? (
            items.map(item => {
              return <WishlistSwitch key={item.id} item={item} shared={shared}/>
            })
        ) : getEmpty() }
      </LoadingComponent>
  )
}

export default WishlistList;