import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import {Grid, TextField} from '@mui/material';
import Button from '@mui/material/Button';

export const StyledPagePaper = styled(Paper)({
  maxWidth: 310,
  margin: "8px auto",
  padding: '15px 0 15px 0',
  overflow: 'hidden',
  '& > form':{
    margin: '25px',
    textAlign: 'center'
  }
})

export const StyledTextField = styled(TextField)({
  margin: '5px 0 5px 0'
})

export const StyledPageButtonGroup = styled('div')({
  marginTop: '20px',
  verticalAlign: 'middle'
})

export const StyledSubmitButton = styled(Button)({
  marginLeft: 0
})

export const StyledDetailImage = styled('img')({
  maxWidth: 150,
  width: '100%',
  height: 'auto',
  borderRadius: 5,
})

export const StyledFab = styled(Fab)({
  position: 'fixed',
  bottom: '30px',
  right: '30px'
})

export const HomePageGridStyled = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    minWidth: 295,
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: 295,
  },
  margin: "10px 10px",
  cursor: "pointer"
}));
