import React from 'react';
import {Hidden, Stack, Typography} from '@mui/material';
import moment from 'moment';
import {StyledHighlight} from "./styles";

const WishlistContent = ({wishlist, shared, friend}) => {
  const updated = moment(Date.parse(wishlist.updated)).format('MMM Do, YYYY');

  const renderLastUpdated = (variant, color) => (
      <Typography variant={variant ? variant : 'body1'}
                  color={color ? color : 'textPrimary'}>
        Last Updated: {updated}
      </Typography>
  );

  const hasColor = () => {
    return friend && friend.color;
  }

  return (
      <React.Fragment>
        {hasColor() && (
            <StyledHighlight sx={{ backgroundColor: `#${friend.color}` }} />
        )}
        <Stack style={hasColor() && {paddingLeft: 10}}>
          <Typography variant="h6">
            {wishlist.wishlistName}
          </Typography>
          {shared && (
              <Typography color="textSecondary">
                Owner: {wishlist.firstName} {wishlist.lastName}
              </Typography>
          )}
          <Typography color="textSecondary">
            Visibility: {wishlist.visibility}
          </Typography>
          <Hidden mdUp>
            {renderLastUpdated('body1', 'textSecondary')}
          </Hidden>
        </Stack>
      </React.Fragment>

  )
}

export default WishlistContent;