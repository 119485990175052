import React from 'react';
import UserForm from "../forms/UserForm";
import EmailNotificationForm from "../forms/EmailNotificationsForm";
import {StyledPagePaper} from './styles';

function ProfilePage() {

  return (
      <StyledPagePaper>
        <UserForm type="profile" />
        <EmailNotificationForm />
      </StyledPagePaper>
  );
}

export default ProfilePage;
