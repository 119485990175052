import {setNotifications} from '../slices/NotificationSlice';
import {deleteAllNotifications, getAllNotifications, markAllAsRead} from '../api/NotificationApi';

const getAllNotificationsAction = () => async dispatch => {
  return getAllNotifications().then(json => {
    dispatch(setNotifications(json));
  })
};

const markAllAsReadAction = () => async dispatch => {
  return markAllAsRead().then(json => {
    dispatch(setNotifications(json));
  });
};

const deleteAllNotificationsAction = () => async dispatch => {
  return deleteAllNotifications().then(() => {
    dispatch(setNotifications([]));
  });
};

export { getAllNotificationsAction, markAllAsReadAction, deleteAllNotificationsAction }