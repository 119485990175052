import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from "@mui/material/FormGroup";
import {FormControl} from "@mui/material";

const EmailGroup = ({item, name, notifications, setNotifications}) => {
  const toggle = (subItem) => {
    subItem.checked = !subItem.checked;
    synchronizeAll(item);
    setNotifications({...notifications});
  }

  const synchronizeAll = (item) => {
    let checked = true;
    if (item.create && !item.create.checked) checked = false;
    if (item.update && !item.update.checked) checked = false;
    if (item.delete && !item.delete.checked) checked = false;
    item.all = checked;
  }

  const toggleAll = () => {
    item.all = !item.all;
    if (item.create) item.create.checked = item.all;
    if (item.update) item.update.checked = item.all;
    if (item.delete) item.delete.checked = item.all;

    setNotifications({...notifications})
  }

  const splitCamelCaseToString = (s) => {
    return s.split(/(?=[A-Z])/).map(function(p) {
      return p.charAt(0).toUpperCase() + p.slice(1);
    }).join(' ');
  }

  return (
      <div style={{ marginTop: '15px' }}>
        <FormControl>
          <FormControlLabel label={splitCamelCaseToString(name)} control={<Checkbox checked={item.all} onChange={toggleAll} color='primary' />} />
          <FormGroup sx={{ marginLeft: 3 }}>
            {item.create &&
                <FormControl>
                  <FormControlLabel label='Create' control={<Checkbox checked={item.create.checked} onChange={() => toggle(item.create)} color='primary' />} />
                </FormControl>}
            {item.update &&
                <FormControl>
                  <FormControlLabel label='Update' control={<Checkbox checked={item.update.checked} onChange={() => toggle(item.update)} color='primary' />} />
                </FormControl>}
            {item.delete &&
                <FormControl>
                  <FormControlLabel label='Delete' control={<Checkbox checked={item.delete.checked} onChange={() => toggle(item.delete)} color='primary' />} />
                </FormControl>}
          </FormGroup>
        </FormControl>
      </div>
  );
}

export default EmailGroup;