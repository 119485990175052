import FetchWrapper from "./FetchWrapper";

const searchAmazon = (term) => {
  return FetchWrapper(`/search?term=${term}`);
};

const searchAmazonProduct = (url) => {
  return FetchWrapper(`/product?url=${url}`);
};

export {searchAmazon, searchAmazonProduct}