import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
  name: "WishlistItems",
  initialState: {
    wishlist: {},
    wishlistItems: [],
    linkUser: {},
    wishlistItemsSearch: '',
    loading: false,
    addItem: {},
    showStatus: false
  },
  reducers: {
    setAddWishlistItem: (state, action) => {
      state.addItem = action.payload;
    },
    setWishlist: (state, action) => {
      state.wishlist = action.payload;
    },
    setWishlistLinkUser: (state, action) => {
      state.linkUser = action.payload;
    },
    setWishlistItems: (state, action) => {
      state.wishlistItems = action.payload;
    },
    setWishlistItemsSearch: (state, action) => {
      state.wishlistItemsSearch = action.payload;
    },
    setWishlistItemsLoading: (state, action) => {
      state.loading = action.payload;
    },
    setShowStatus: (state, action) => {
      state.showStatus = action.payload;
    }
  }
});

export const {
  setAddWishlistItem,
  setWishlist,
  setWishlistLinkUser,
  setWishlistItems,
  setWishlistItemsSearch,
    setWishlistItemsLoading,
    setShowStatus
} = slice.actions;

export default slice.reducer