import React, {useState} from 'react';
import {Grow, Paper} from '@mui/material';


const PaperListItem = ({
  children,
  menuOptions,
  style,
  hover,
  clickable,
  onClick,
    ...other
}) => {
  const [elevation, setElevation] = useState(2);
  const [grow, setGrow] = React.useState(false);

  React.useEffect(() => {
    setGrow(true);
  }, [])

  const handleMouseOver = (e) => {
    if (hover) {
      setElevation(3);
    }
  }

  const handleMouseOut = (e) => {
    if (hover) {
      setElevation(2);
    }
  }

  const sx = Object.assign({
    position: 'relative',
    overflow: 'hidden',
    padding: 2,
    ...(onClick && clickable && {
      cursor: 'pointer'
    })
  }, other['sx']);

  if (other['sx']) {
    delete other['sx'];
  }

  return (
    <Paper
        {...other}
        elevation={elevation}
        style={style}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={onClick}
        sx={sx}>
      {children}
    </Paper>
  );
}

export default PaperListItem;
