import React, {useState} from "react";

function useFilteredResults(items, search) {
  const [filtered, setFiltered] = useState([]);

  React.useEffect(() => {
    filterResults(search);
  }, [items, search]);

  const filterResults = (text) => {
    if (text && text !== '') {
      setFiltered(items.filter((el) => hasValue(el, text)));
    } else {
      setFiltered(items);
    }
  };

  return filtered;
}

function hasValue(object, val) {
  return Object.keys(object).some(function(k) {
    const value = object[k];
    if (value && typeof value === 'object') {
      return hasValue(value, val);
    } else if (typeof value === 'string' && value.toUpperCase().includes(val.toUpperCase())) {
      return true;
    }

    return false;
  });
}

export default useFilteredResults;