import {combineReducers, configureStore} from "@reduxjs/toolkit";
import HomeSlice from '../slices/HomeSlice'
import NavigatorSlice from "../slices/NavigatorSlice";
import WishlistsSlice from "../slices/WishlistsSlice";
import UserItemsSlice from "../slices/UserItemsSlice";
import UserSlice from "../slices/UserSlice";
import WishlistItemsSlice from "../slices/WishlistItemsSlice";
import SearchSlice from "../slices/SearchSlice";
import {devToolsEnhancer} from "redux-devtools-extension";
import LinkedWishlistSlice from "../slices/LinkedWishlistSlice";
import WishlistsItemDetailSlice from "../slices/WishlistItemDetailSlice";
import CommentSlice from "../slices/CommentSlice";
import ModalSlice from "../slices/ModalSlice";
import SharedItemsSlice from "../slices/SharedItemsSlice";
import FriendsSlice from "../slices/FriendsSlice";
import NotificationSlice from '../slices/NotificationSlice';
import BrowseSlice from "../slices/BrowseSlice";

const store = configureStore({
  reducer: combineReducers({
    home: HomeSlice,
    userItems: UserItemsSlice,
    navigator: NavigatorSlice,
    notification: NotificationSlice,
    wishlists: WishlistsSlice,
    linkedWishlists: LinkedWishlistSlice,
    wishlistItems: WishlistItemsSlice,
    wishlistItemDetail: WishlistsItemDetailSlice,
    user: UserSlice,
    friends: FriendsSlice,
    search: SearchSlice,
    comment: CommentSlice,
    modals: ModalSlice,
    sharedItems: SharedItemsSlice,
    browse: BrowseSlice,
  }),
  devTools: devToolsEnhancer()
});

export default store;