import React from "react";
import WishlistItemView from "./WishlistItemView";
import WishlistItemEdit from "./WishlistItemEdit";
import {Stack} from '@mui/material';
import {CollapseStyled} from './styles';

const WishlistItem = ({item, claims, contributions, ownerId}) => {
  const [edit, setEdit] = React.useState(false);

  const toggleEdit = () => {
    setEdit(!edit);
  }

  return (
      <Stack spacing={1}>
        <CollapseStyled in={edit} sx={{ zIndex: 2 }} >
          <WishlistItemEdit item={item} toggleEdit={toggleEdit}/>
        </CollapseStyled>
        <CollapseStyled timeout={150} in={!edit} >
          <WishlistItemView {...{
            item,
            claims,
            contributions,
            ownerId,
            toggleEdit
          }} />
        </CollapseStyled>
      </Stack>
  );
}

export default WishlistItem;
