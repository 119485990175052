import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {toggleOpen} from "../../slices/NavigatorSlice";
import Typography from "@mui/material/Typography";
import ProfileButton from "../ProfileButton";
import AppBar from "@mui/material/AppBar/AppBar";
import MenuIcon from '@mui/icons-material/Menu';
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useHistory, useLocation} from "react-router-dom";
import Notifications from './Notifications';
import LinesEllipsis from 'react-lines-ellipsis';
import {useTheme} from "@mui/material";

function HeaderAppBarMobile() {
  const userId = useSelector(state => state.user.id);
  const { title } = useSelector(state => state.navigator);
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  return (
    <AppBar color="primary" position="sticky" elevation={2}>
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(toggleOpen())}
              size="large"
              sx={{ marginLeft: "-8px" }}>
              <MenuIcon />
            </IconButton>
          </Grid>
          {location.key &&
              <Grid item>
                <IconButton onClick={() => history.goBack()} color="inherit" size="large">
                  <ArrowBackIcon color="inherit"/>
                </IconButton>
              </Grid>}
          <Grid item xs>
            <Typography color="inherit" variant="h6" component="h1" textTransform="capitalize">
              <LinesEllipsis text={title} basedOn={'letters'} />
            </Typography>
          </Grid>
        </Grid>
        {userId && (
            <Grid item>
              <Notifications />
            </Grid>
        )}
        <Grid item>
          <ProfileButton />
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default HeaderAppBarMobile;