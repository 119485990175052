import ListAltIcon from '@mui/icons-material/ListAlt';
import {setLinkedWishlists} from '../../slices/LinkedWishlistSlice';
import {setWishlistItems} from '../../slices/WishlistItemsSlice';
import SearchIcon from '@mui/icons-material/Search';
import ListIcon from '@mui/icons-material/List';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PeopleIcon from '@mui/icons-material/People';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import React from 'react';
import {useDispatch} from 'react-redux';

const useCategories = () => {

    return [
        {
            id: 'Wishlists',
            children: [
                {
                    id: 'My Wishlists',
                    loggedIn: true,
                    icon: <ListAltIcon/>,
                    to: '/lists',
                },
                {
                    id: 'Shared with Me',
                    icon: <ListAltIcon/>,
                    to: '/shared',
                },
                {
                    id: 'Browse',
                    loggedIn: true,
                    icon: <SearchIcon/>,
                    children: [
                        {
                            id: 'Friend Lists',
                            title: "Browse Friend's List",
                            icon: <SearchIcon/>,
                            to: '/browse/lists/friends',
                        },
                        {
                            id: 'Public Lists',
                            title: "Browse Public Lists",
                            icon: <SearchIcon/>,
                            to: '/browse/lists/public',
                        }
                    ]
                },
                {id: 'My Library', icon: <ListIcon/>, loggedIn: true, to: '/items'},
            ],
        },
        {
            id: 'User',
            loggedIn: true,
            children: [
                {id: 'Profile', icon: <AccountBoxIcon/>, to: "/profile"},
                {id: 'Friends', icon: <PeopleIcon/>, to: '/friends'},
            ],
        },
        {
            id: 'Settings',
            loggedIn: true,
            children: [
                {id: 'Logout', icon: <ExitToAppIcon/>, to: '/'}
            ],
        },
    ];
};

export default useCategories;