import React from 'react';
import {Grid, Typography} from '@mui/material';
import EmptySection from './EmptySection';
import {Link} from 'react-router-dom';

const SharedWishlistsEmpty = () => {

  return (
      <EmptySection>
        <Typography color="textSecondary">
          You are currently not following any wishlists. A friend may share a wishlist with you or
          you can search for wishlists via the Browse <Link to="/browse/lists/friends">Friends</Link>
          &nbsp;or <Link to="/browse/lists/friends">Public</Link> wishlists pages.
        </Typography>
      </EmptySection>
  )
}

export default SharedWishlistsEmpty;